import { useMemo, useCallback } from 'react'
import { Select as InternalSelect } from 'formik-antd'
import styled from 'styled-components'
import { useFormContext } from 'context/form-context'
import { useReadonly } from 'hooks/readonly-hook'
import { useTranslationContext } from 'context/translation-context'
import { useRequiredContext } from 'context/required-context'
import { ItemBoxProps } from '../item'
import { BaseItemProps } from '../form'
import { BaseStyle, BaseInput } from '../../input'
import { Placeholder } from '../placeholder'

const Dropdown = styled(InternalSelect)<any>`
    ${BaseInput};

    :where(&).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
        background: none;
        border: 0;
    }

    :where(.ant-select-dropdown).ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background: 0;
    }

    :where(&):is(.ant-select-disabled).ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        ${BaseStyle};
        color: #70758CBC;
    }

    :where(&):not(.ant-select-disabled).ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        ${BaseStyle};
    }

    &:is(.ant-select-disabled) {
        background: 0;
    }

    &:is(.ant-select-disabled):hover {
        border: 1px solid white;
        outline: 0;
    }

    & .ant-select-suffix {
        color: #00B6FF;
    }

    & .select_option {
        background: red;
    }
`

type EnumType = { [s: number]: string }

interface SelectProps extends BaseItemProps, ItemBoxProps {
    type: EnumType
    root: string
    onChange?: (type: any) => void
    disabled?: any[]
    disableTranslate?: boolean
}

export const Select: React.FC<SelectProps> = ({
    name,
    title,
    type,
    root,
    onChange,
    disabled,
    flex,
    disableTranslate,
    required,
}) => {
    const { isValidField } = useRequiredContext()
    const { translate } = useTranslationContext()

    const [isReadonly] = useReadonly()
    const { submit } = useFormContext()

    const createOptions = useMemo(() => {
        return Object.keys(type).map((value: any) => {
            const id = `${name}__${value}--dropdown`
            const isDisabled = (disabled ?? []).includes(value)
            return {
                className: 'select_option',
                id,
                disabled: isDisabled,
                value,
                label: disableTranslate ? value : translate(`${root}.${value}`),
            }
        })
    }, [isReadonly, type, disabled])

    const onChangeInternal = useCallback((type: any) => {
        submit().then(() => {
            if (onChange) {
                onChange(type)
            }
        })
    }, [onChange])

    return (
        <Placeholder title={title} state={false} flex={flex} required={required} name={name}>
            <Dropdown
              name={name ?? ''}
              options={createOptions}
              onChange={onChangeInternal}
              disabled={isReadonly}
              listHeight={1000}
              $required={!isValidField(name) && !isReadonly}
            />
        </Placeholder>
    )
}
