import { Form } from 'components/form/form'
import { PageType } from 'app/router'
import { useAxiosContext } from 'context/axios-context'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { Hotel } from './components/hotel'
import { Consists } from './components/consists'
import { Accommodation } from './components/accommodation'
import { Location } from './components/location'
import { Beach } from './components/beach'
import { Facilities } from './components/facilities'
import { Internet } from './components/internet'
import { Pools } from './components/pools'
import { WaterSlides } from './components/water-slides'
import { AccommodationTypes } from './components/accommodation-types'
import { Sport } from './components/sport'
import { Meals } from './components/meals'
import { Honeymoon } from './components/honeymoon'
import { Birthday } from './components/birthday'

export const Edit: PageType = () => {
    const { save } = useAxiosContext()
    const { data } = useFactSheetContext()

    return (
        <Form defaultValue={data} onSubmit={save}>
            <Hotel />
            <Consists />
            <Accommodation />
            <Location />
            <Beach />
            <Facilities />
            <Pools />
            <WaterSlides />
            <Internet />
            <AccommodationTypes />
            <Sport />
            <Meals />
            <Honeymoon />
            <Birthday />
        </Form>
    )
}
