import { Card } from 'components/main/card'
import { Component } from 'models/common'
import { MainFacilities } from './facilities/main-facilities'
import { Adults } from './facilities/adults'
import { Disabled } from './facilities/disabled'

export const Facilities: React.FC = () => {
    return (
        <Card type={Component.ACCOMMODATION_FACILITIES} required checkAvailable>
            <MainFacilities />
            <Adults />
            <Disabled />
        </Card>
    )
}
