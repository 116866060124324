import { useMemo, useCallback } from 'react'
import dayjs from 'dayjs'
import { BaseDateProps, RangePicker, RangeStyleProps, Formatter, DefaultFormatter } from '../form/items/date-picker'

export const DatePicker: React.FC<BaseDateProps> = ({
    title,
    name,
    required,
}) => {
    const addon = useMemo<RangeStyleProps>(() => ({
        format: 'D. MMM',
    }), [])

    const defaultFormatter = useCallback<DefaultFormatter>((item) => {
        const year = item.value?.[0]
        const month = item.value?.[1]
        const day = item.value?.[2]

        if (!year || !month || !day) {
            return null
        }

        return dayjs().set('year', year).set('month', month).set('day', day)
    }, [])

    const formatter = useCallback<Formatter>((date) => {
        return [
            {
                name: 'value',
                val: date || null,
            },
            {
                name: 'value',
                val: date || null,
            },
        ]
    }, [])

    return (
        <RangePicker
          title={title}
          titles={['from', 'to']}
          name={name}
          addon={addon}
          required={required}
          defaultFormatter={defaultFormatter}
          formatter={formatter}
        />
    )
}
