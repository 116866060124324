import { Box } from 'components/container'
import { CheckBox } from 'components/form/items/check-box'
import { OtherInputSwitcher } from 'components/presets/other-input-switcher'

export interface ExtraBedProps {
    rootName: string
    type: string
    title: string
}

export const ExtraBed: React.FC<ExtraBedProps> = ({
    rootName,
    type,
    title,
}) => {
    const parsedType = `${rootName}-${type}`
    const root = `${rootName}.extraBed.${type}`

    const required = {
        required: parsedType,
    }

    return (
        <Box>
            <CheckBox title={title} name={`${root}.checked`} required={{ required: `${rootName}-bed`, section: parsedType }}>
                <CheckBox title="bedAdult" name={`${root}.adults`} required={required} />
                <OtherInputSwitcher title="bedChildren" name={`${root}.children`} input="childrenAge" inputName={`${root}.age`} required={required} />
            </CheckBox>
        </Box>
    )
}
