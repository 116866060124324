import React, { createContext, useMemo, useContext, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslationContext } from 'context/translation-context'

const Icon = styled(CloseOutlined)`
    margin-left: 5px;
`

type DeletableMessageCallback = (
    message: string,
    defaultMessage?: string,
    type?: any,
) => void
type MessageCallback = (message: string, defaultMessage?: string) => void
type Message = { key: string, messageKey: string, defaultMessage?: string }

export interface MessageContextProps {
    showMessage: MessageCallback
    showError: MessageCallback
    showDeletable: DeletableMessageCallback
}

export const MessageContext = createContext<MessageContextProps>(
    {} as MessageContextProps,
)

type MessageContextProviderProps = React.PropsWithChildren

export const MessageContextProvider: React.FC<MessageContextProviderProps> = ({
    children,
}) => {
    const maxErrorCount = 3
    const [messageApi, contextHolder] = message.useMessage()
    const usedMessageKeys = useRef<Message[]>([])
    const errorMessageCount = useRef(0)
    const { translate } = useTranslationContext()

    const showMessage = useCallback<MessageCallback>((message) => {
        messageApi.open({
            type: 'success',
            content: translate(`notifications.success.${message}`),
        })
    }, [messageApi, translate])

    const showDeletable = useCallback<DeletableMessageCallback>((
        deletableMessage,
        defaultMessage,
        type,
    ) => {
        const internalType = type ?? 'success'
        const copiedErrorList = usedMessageKeys.current

        const parseMessage = (message?: string) => (
            message ? `notifications.${type}.${message}` : message
        )

        const newMessage = {
            key: `error_${errorMessageCount.current}`,
            messageKey: parseMessage(deletableMessage) ?? '',
            defaultMessage: parseMessage(defaultMessage),
        }

        const mapToKeys = () => usedMessageKeys.current.map((message) => message.messageKey)

        const destroyMessage = ({ key, messageKey }: Message) => {
            messageApi.destroy(key)
            const index = mapToKeys().indexOf(messageKey)
            usedMessageKeys.current.splice(index, 1)
        }

        if (mapToKeys().includes(newMessage.messageKey)) {
            return
        }

        if (usedMessageKeys.current.length >= maxErrorCount) {
            destroyMessage(copiedErrorList[0])
            copiedErrorList.shift()
        }

        messageApi.open({
            type: internalType,
            duration: 30,
            key: newMessage.key,
            onClose: () => destroyMessage(newMessage),
            content: (
                <>
                    {translate(newMessage.messageKey, { defaultValue: newMessage.defaultMessage })}
                    <Icon onClick={() => destroyMessage(newMessage)} />
                </>
            ),
        })

        usedMessageKeys.current.push(newMessage)
        errorMessageCount.current += 1
        usedMessageKeys.current = copiedErrorList
    }, [translate, message, usedMessageKeys])

    const showError = useCallback<MessageCallback>((errorMessage, defaultMessage) => {
        showDeletable(errorMessage, defaultMessage, 'error')
    }, [showDeletable])

    const providerContext = useMemo(() => ({
        showMessage,
        showError,
        showDeletable,
    }), [
        showMessage,
        showError,
        showDeletable,
    ])

    return (
        <MessageContext.Provider value={providerContext}>
            {contextHolder}
            {children}
        </MessageContext.Provider>
    )
}

export const useMessageContext = () => {
    return useContext(MessageContext)
}
