import { FactSheetData, FactSheetHeader } from './fact-sheet-data'
import { FactSheetDto, State, Role, Guid, TokenData, CheckData, OtpRequirement, FactSheetEdit } from './fact-sheet-dto'

export type FactSheetRequest = number

export type TokenRequestBody = TokenData

export enum StaffAction {
    FORWARD = 'FORWARD',
    RETURN = 'RETURN',
    OVERTAKE = 'OVERTAKE',
}

export interface StaffRequestBody {
    action: StaffAction
    email?: string
    comment?: string
}

export interface UpdateRequestBody {
    id: Guid
    data: FactSheetData
    tokenRequest: TokenRequestBody
}

export interface StateRequestBody {
    tokenRequest: TokenRequestBody
    newState: State
    otp?: number
}

export interface HistoryInfo {
    author: string
    timestamp: string
}

export interface HistoryState extends HistoryInfo {
    newState: State
    directedTo?: string
    hotelAction?: StaffAction
    note?: string
}

export interface ErrorResponse {
    isError?: boolean
    response?: any
}

export interface HistoryResponse extends ErrorResponse {
    states?: HistoryState[]
    dataUpdate?: HistoryInfo
}

export interface StaffResponse extends ErrorResponse {
    edit: FactSheetEdit
}

export interface HeaderResponse extends FactSheetDto, ErrorResponse {
    header: FactSheetHeader
    role: Role
}

export type CheckResponse = Array<CheckData> & ErrorResponse

export interface FactSheetResponse extends FactSheetDto, ErrorResponse {
    tokenResponse: TokenRequestBody
    otpRequirement?: OtpRequirement
}

export type UpdateResponse = FactSheetResponse
export type StateResponse = FactSheetResponse
export type TokenResponse = FactSheetResponse
