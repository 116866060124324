import { useMemo } from 'react'
import { Card } from 'components/main/card'
import { Component } from 'models/common'
import { EntityWrapper, EntryMapper } from './wrapper/entity-wrapper'

export const WaterSlides: React.FC = () => {
    const waterMapper = useMemo((): EntryMapper => ([
        ['waterSlides', true],
        ['tobogans', true],
        ['lazyRiver', true],
        ['wildRiver', true],
        ['childrenSlides', true],
        ['piratesBoat', true],
    ]), [])

    return (
        <Card type={Component.WATER_SLIDES} required>
            <EntityWrapper rootName="waterSlides" data={waterMapper} extra="extra" showOtherExtra check offset required component={Component.WATER_SLIDES} />
        </Card>
    )
}
