import { useMemo } from 'react'
import styled from 'styled-components'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Tooltip as InternalTooltip } from 'antd'

const StyledTooltip = styled(InternalTooltip)<any>`
    width: 100%;
    ${({ $auto }) =>
        $auto &&
        `
        width: auto;
    `};
`

const Icon = styled(InfoCircleOutlined)`
    margin-top: 2px;
    color: #70758C;
    cursor: pointer;
`

export enum TooltipMode {
    LIGHT = 'LIGHT',
    DARK = 'DARK',
}

type Placement = (
    'top' | 'left' | 'right' | 'bottom' |
    'topLeft' | 'topRight' |
    'bottomLeft' | 'bottomRight' |
    'leftTop' | 'leftBottom' |
    'rightTop' | 'rightBottom'
)

interface TooltipBaseProps {
    text?: string
    mode?: TooltipMode
    place?: Placement
    isAuto?: boolean
}

export interface TooltipProps extends React.PropsWithChildren, TooltipBaseProps {}

export const Tooltip: React.FC<TooltipProps> = ({
    text,
    mode,
    place,
    isAuto,
    children,
}) => {
    const backgroundColor = useMemo(() => (
        !mode || mode === TooltipMode.LIGHT ? '#DCE2ED' : '#0D2D54'
    ), [mode])

    const createStyle = useMemo(() => ({
        color: !mode || mode === TooltipMode.LIGHT ? '#7F859D' : '#FFFFFF',
        fontSize: '12px',
    }), [mode])

    return (
        <StyledTooltip
          $auto={isAuto}
          overlayInnerStyle={createStyle}
          color={backgroundColor}
          placement={place ?? 'bottomRight'}
          title={text}
          arrow
        >
            {children}
        </StyledTooltip>
    )
}

export const TooltipIcon: React.FC<TooltipBaseProps> = ({
    ...props
}) => (
    <Tooltip {...props}>
        <Icon style={{ fontSize: '16px' }} />
    </Tooltip>
)
