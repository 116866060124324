import { useMemo } from 'react'
import { styled } from 'styled-components'
import { MessageOutlined } from '@ant-design/icons'
import { HistoryState } from 'models/fact-sheet-api'
import { Tooltip } from '../../tooltip'
import { Box } from '../../container'

const TextWrapper = styled.p<any>`
    text-overflow: ellipsis;
    margin-bottom: 12px;
    word-break: break-all;
    overflow:hidden;
    white-space: nowrap;
    width: 25%;

    &:hover {
        overflow: visible;
        white-space: normal;
        height:auto;
    }

    ${({ $small }) =>
        $small &&
        `
        width: 100%;
    `};
`

const CommentIcon = styled(MessageOutlined)`
    font-size: 18px;
    color: #00B6FFD9;
    width: auto;
    margin: auto;
`

const HistoryItemWrapper = styled(Box)<any>`
    gap: 0;

   ${({ $renderBorder }) =>
        $renderBorder &&
        `
        border-bottom-style: dotted;
        border-color: #F1F1F1;
        border-width: 1px;
    `};

    padding: 10px 0px;
    height: 20px;
    width: 100%;

    ${({ $margin }) =>
        $margin &&
        `
        margin-right: 17px;
    `};
`

export interface HistoryItemProps {
    data: HistoryState
    hideBorder?: boolean
    margin?: boolean
    children?: (value: any) => React.ReactNode
}

export const HistoryItem: React.FC<HistoryItemProps> = ({
    data: {
        newState,
        timestamp,
        author,
        directedTo,
        note,
        hotelAction,
    },
    hideBorder,
    margin,
    children,
}) => {
    const renderValue = useMemo(() => {
        if (!children) {
            return <p>{newState}</p>
        }
        return children({ newState, hotelAction })
    }, [newState, children])

    return (
        <HistoryItemWrapper $margin={margin} $renderBorder={!hideBorder}>
            <Box $width="25%">
                {renderValue}
            </Box>
            <TextWrapper>{timestamp}</TextWrapper>
            <TextWrapper>{author}</TextWrapper>
            <Box $width={note ? '20%' : '25%'}>
                <TextWrapper $small>{directedTo}</TextWrapper>
            </Box>
            { note && (
                <Box $width="5%">
                    <Tooltip text={note}>
                        <CommentIcon />
                    </Tooltip>
                </Box>
            )}
        </HistoryItemWrapper>
    )
}
