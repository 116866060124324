import { useMemo, useCallback, useEffect } from 'react'
import { Radio as FormikRadio } from 'formik-antd'
import { useField } from 'formik'
import styled from 'styled-components'
import { useFormContext } from 'context/form-context'
import { useAutoCheckContext } from 'context/auto-check-context'
import { useTranslationContext } from 'context/translation-context'
import { useRequiredContext } from 'context/required-context'
import { useReadonly } from 'hooks/readonly-hook'
import { BaseStyle } from '../../input'
import { Item, ItemBoxProps } from '../item'
import { Box, gap, columnGap } from '../../container'

type EnumType = { [s: number]: string }
type Mapper = any

interface RadioProps extends ItemBoxProps {
    name: string
    type: EnumType
    title: string
    mapper?: Mapper
    row?: boolean
}

const RadioWrapper = styled(Item)`
    width: auto;
`

const RadioGroup = styled(FormikRadio.Group)<any>`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: ${columnGap}px;
    row-gap: ${gap}px;

    ${({ $row }) =>
        $row &&
        `
        flex-direction: column;
    `};
`

const RadioItem = styled(Box)`
    align-items: center;
`

const RadioInput = styled(FormikRadio)<any>`
    --color: ${({ $required }: any) => ($required ? '#FF0040' : '#70758C40')};

    font-size: 12px;
    ${BaseStyle};
    height: 32px;

    ${({ $disabled }) =>
        $disabled &&
        `
        color: #70758CBC;
    `};

    :where(.ant-radio):not(.ant-radio-checked) .ant-radio-inner {
        background: #EFEFEF;
        border: ${({ $required }: any) => ($required ? '1px solid #FF0040' : '0')};
    }

    :where(.ant-radio):not(.ant-radio-checked):hover .ant-radio-inner {
        background: #D7D7D7;
    }

    :where(.ant-radio):is(.ant-radio-checked) .ant-radio-inner {
        background: #00B6FF;
        border: 0;
    }

    :where(.ant-radio):is(.ant-radio-checked):hover .ant-radio-inner {
        background: #00A4E6;
    }

    :where(.ant-radio):is(.ant-radio-checked):is(.ant-radio-disabled) .ant-radio-inner {
        background: #70758C80;
    }

    :where(.ant-radio):is(.ant-radio-checked):is(.ant-radio-disabled) .ant-radio-inner:after {
        background: white;
    }

    :where(&).ant-radio-wrapper span.ant-radio+* {
        margin-top: 2px;
    }

    :where(&).ant-radio-wrapper .ant-radio {
        margin-bottom: 10px;
    }
`

export const Radio: React.FC<RadioProps> = ({
    name,
    type,
    row,
    mapper,
    title,
    required,
}) => {
    const { isValidField } = useRequiredContext()
    const { translate } = useTranslationContext()
    const [isReadonly] = useReadonly()
    const { submit } = useFormContext()
    const { autoCheck, disable } = useAutoCheckContext()
    const [, meta, helpers] = useField(name)

    const renderItems = useMemo(() => {
        return Object.keys(type).map((key: any) => {
            return (
                <RadioItem key={key} $length={1}>
                    <RadioInput $disabled={isReadonly} $required={!isValidField(name) && !isReadonly} id={`${name}__${key}--radio`} name={name} value={key}>
                        {translate(`${title}.${key}`)}
                    </RadioInput>
                </RadioItem>
            )
        })
    }, [mapper, isReadonly, isValidField])

    useEffect(() => {
        if (disable === undefined || !meta?.value || !disable) {
            return
        }
        helpers.setValue(undefined)
    }, [disable, meta?.value, helpers])

    const onChange = useCallback(() => {
        if (autoCheck) {
            autoCheck()
        }
        submit()
    }, [autoCheck, submit])

    return (
        <RadioWrapper fixedWidth={false} required={required} name={name}>
            <RadioGroup $row={row} id={`${name}__group--radio`} name={name} disabled={isReadonly} onChange={onChange}>
                {renderItems}
            </RadioGroup>
        </RadioWrapper>
    )
}
