import { useMemo } from 'react'
import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { Other } from 'components/form/other'
import { Input } from 'components/form/items/input'
import { Select } from 'components/form/items/select'
import { MultipleDatePicker } from 'components/presets/multiple-date-picker'
import { Integration, Cost, LocAndInt } from 'models/fact-sheet-data'
import { Component } from 'models/common'
import { PoolsComponent } from './pools/pool-component'
import { PoolItems } from './pools/pool-items'
import { EntityWrapper, EntryMapper } from './wrapper/entity-wrapper'

export const Pools: React.FC = () => {
    const poolMapper = useMemo((): EntryMapper => ([
        ['sunbeds'],
        ['umbrellas'],
        ['mattress'],
    ]), [])

    return (
        <Card type={Component.SWIMMING_POOLS} checkAvailable required>
            <PoolsComponent title="main" name="mainPool" row />
            <PoolsComponent title="adult" name="adults" row />
            <PoolsComponent title="infinity" name="infinity" row />
            <PoolsComponent title="activity" name="activity" row />
            <PoolsComponent title="olympic" name="olympic" row />
            <PoolsComponent title="children" name="children" row>
                {(rootName) => (
                    <Select title="integration" name={`${rootName}.integration`} type={Integration} root="integration" required />
                )}
            </PoolsComponent>
            <PoolsComponent title="indoor" name="indoor" row>
                {(rootName) => (
                    <Box $row $length={1}>
                        <Select title="chargeType" name={`${rootName}.price.chargeType`} type={Cost} root="cost" />
                        <Other name={`${rootName}.price.chargeType`} value="EXTRA" isAuto>
                            <Input title="charge" name={`${rootName}.price.info`} required />
                        </Other>
                    </Box>
                )}
            </PoolsComponent>
            <PoolsComponent title="jacuzzi" name="jacuzzi" disablePicker row>
                {(rootName) => (
                    <Box $width="auto">
                        <Select title="location" name={`${rootName}.locationAndIntegration`} type={LocAndInt} root="locAndInt" required />
                        <Other name={`${rootName}.locationAndIntegration`} value={[LocAndInt.OUTDOOR_INTEGRATED, LocAndInt.OUTDOOR_SEPARATED]} isAuto>
                            <MultipleDatePicker title="when" first={`${rootName}.heatingPeriod1`} second={`${rootName}.heatingPeriod2`} />
                        </Other>
                    </Box>
                )}
            </PoolsComponent>
            <PoolItems />
            <EntityWrapper rootName="swimmingPools" data={poolMapper} disableOther disableRequired component={Component.SWIMMING_POOLS} />
        </Card>
    )
}
