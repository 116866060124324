import 'styles/style.scss'

import React, { useMemo } from 'react'
import { FactSheetContextProvider } from 'context/fact-sheet-context'
import { LoadContextProvider } from 'context/load-context'
import { ConfigurationContextProvider } from 'context/configuration-context'
import { TranslationContextProvider } from 'context/translation-context'
import { MessageContextProvider } from 'context/message-context'
import { useTranslationHook } from 'hooks/i18next-hook'
import { Namespace } from 'models/common'
import { Router } from './router'
import { ErrorHandler } from './error'

export const App: React.FC = () => {
    const { loaded } = useTranslationHook()

    const appInitialized = useMemo(() => {
        return loaded
    }, [loaded])

    return appInitialized && (
        <ErrorHandler>
            <ConfigurationContextProvider>
                <TranslationContextProvider ns={Namespace.COMMON}>
                    <MessageContextProvider>
                        <FactSheetContextProvider>
                            <LoadContextProvider>
                                <Router />
                            </LoadContextProvider>
                        </FactSheetContextProvider>
                    </MessageContextProvider>
                </TranslationContextProvider>
            </ConfigurationContextProvider>
        </ErrorHandler>
    )
}
