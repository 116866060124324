import { useMemo } from 'react'
import styled from 'styled-components'
import { RequiredContextProvider } from 'context/required-context'
import { useTranslationContext } from 'context/translation-context'
import { Component } from 'models/common'
import { RequiredTitle } from '../main/required-title'
import { Box } from '../container'

const WrapperBox = styled(Box)<any>`
    ${({ $valid }) =>
        $valid &&
        `
        margin-top: 10px;
        row-gap: 15px;
    `};

    ${({ $offset }) =>
        $offset &&
        `
        margin-top: -14px;
        row-gap: 15px;
    `};

    ${({ $header }) => `
        scroll-margin-top: ${$header}px;
    `};
`

interface WrapperProps extends React.PropsWithChildren {
    id: string
    title?: string
    addon?: any
    required?: boolean
    checkAvailable?: any
    offset?: boolean
    component: Component
    disableRequiredContext?: boolean
    hideRequiredNote?: boolean
    disableParent?: boolean
}

export const WrapperInternal: React.FC<WrapperProps> = ({
    id,
    title,
    addon,
    required,
    checkAvailable,
    offset,
    children,
    disableRequiredContext,
    hideRequiredNote,
}) => {
    const { translate } = useTranslationContext()
    const isValidTitle = title && title.length > 0

    const headerOffset = useMemo(() => (
        document.getElementById('header-wrapper')?.offsetHeight ?? 0
    ), [])

    return (
        <WrapperBox $row $valid={isValidTitle} $header={headerOffset} $offset={offset} id={id}>
            <RequiredTitle
              required={required && !disableRequiredContext}
              title={translate(title, { interpolations: addon ?? {} })}
              checkAvailable={checkAvailable}
              hideRequiredNote={hideRequiredNote}
            />
            <Box $row>
                {children}
            </Box>
        </WrapperBox>
    )
}

export const Wrapper: React.FC<WrapperProps> = ({
    children,
    ...other
}) => {
    const wrapperId = `${other.component}--${other.id}`

    const renderWrapperContent = useMemo(() => (
        <WrapperInternal {...other} id={wrapperId}>
            {children}
        </WrapperInternal>
    ), [other, children])

    return (
        <>
            {(!other.title || other.disableRequiredContext) && renderWrapperContent}
            {(other.title && !other.disableRequiredContext) && (
                <RequiredContextProvider
                  id={wrapperId}
                  disableParent={other.disableParent}
                  required={other.required}
                  component={other.component}
                >
                    {renderWrapperContent}
                </RequiredContextProvider>
            )}
        </>
    )
}
