import { useMemo } from 'react'
import { Card } from 'components/main/card'
import { Component } from 'models/common'
import { EntityWrapper, EntryMapper } from './wrapper/entity-wrapper'

export const Sport: React.FC = () => {
    const sportMapper = useMemo((): EntryMapper => ([
        ['basketball'],
        ['voleyball'],
        ['football'],
        ['tennis'],
        ['tennisWithLight'],
        ['tableTennis'],
        ['darts'],
        ['biliards'],
        ['minigolf'],
        ['golf', true],
        ['electronicGames'],
        ['playstation'],
    ]), [])

    const spaMapper = useMemo((): EntryMapper => ([
        ['fitness'],
        ['sauna'],
        ['jacuzzi'],
        ['steamBath'],
        ['solarium'],
        ['massage'],
        ['aromatherapy'],
        ['cosmeticProcedures'],
        ['hairdressing'],
    ]), [])

    const animationMapper = useMemo((): EntryMapper => ([
        ['openAirCinema'],
        ['miniDisco'],
        ['tennisClub'],
        ['thematicDays'],
        ['painting'],
        ['stretching'],
        ['waterPolo'],
        ['waterAerobic'],
        ['yoga'],
        ['pilates'],
        ['zumba'],
        ['dancing'],
        ['cookingLessons'],
        ['archery'],
        ['petanque'],
        ['boccia'],
        ['coctailGames'],
        ['beachParty'],
        ['foamParty'],
        ['bingo'],
        ['quizzes'],
        ['eveningPrograms'],
    ]), [])

    const waterMapper = useMemo((): EntryMapper => ([
        ['diving'],
        ['sailing'],
        ['fishing'],
        ['waterSki'],
        ['surfing'],
        ['kitesurfing'],
        ['windsurfing'],
        ['kayak'],
        ['paddleBoard'],
        ['snorkeling'],
    ]), [])

    const props = {
        disableParent: true,
        check: true,
        required: true,
        component: Component.SPORTS,
    }

    return (
        <Card type={Component.SPORTS} required>
            <EntityWrapper rootName="sports.sportsAndEntertainment" data={sportMapper} extra="extra" {...props} disableParent={false} offset />
            <EntityWrapper rootName="sports.spa" data={spaMapper} title="spaTitle" {...props} />
            <EntityWrapper rootName="sports.animations" data={animationMapper} title="animTitle" {...props} sort />
            <EntityWrapper rootName="sports.waterSports" data={waterMapper} title="waterTitle" {...props} />
        </Card>
    )
}
