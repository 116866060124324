import React, { useMemo } from 'react'
import { Other } from 'components/form/other'
import { Loop, LoopHandler } from 'components/form/loop'
import { Wrapper } from 'components/form/wrapper'
import { Box } from 'components/container'
import { Note } from 'components/presets/note'
import { CheckBox } from 'components/form/items/check-box'
import { Component } from 'models/common'
import { EntityComponent } from './entity-component'

export type Entry = [string, boolean?]
export type EntryMapper = Entry[]

export interface EntityWrapperProps {
    title?: string
    rootName: string
    component: Component
    data: EntryMapper
    sort?: boolean
    disableOther?: boolean
    otherName?: string
    extra?: string
    showOtherExtra?: boolean
    check?: boolean
    offset?: boolean
    required?: boolean
    disableRequiredContext?: boolean
    disableRequired?: boolean
    hideRequiredNote?: boolean
    disableParent?: boolean
}

export const EntityWrapper: React.FC<EntityWrapperProps> = React.memo(({
    title,
    rootName,
    data,
    sort,
    otherName,
    disableOther,
    extra,
    showOtherExtra,
    offset,
    check,
    required,
    component,
    disableRequiredContext,
    disableRequired,
    hideRequiredNote,
    disableParent,
}) => {
    const internalRequired = disableRequired ? false : { required: rootName }

    const renderComponents = useMemo(() => {
        if (sort) {
            data.sort((a, b) => {
                const self = a[0]
                const other = b[0]
                return (self > other) ? 1 : -1
            })
        }
        return data.map(([key, showExtra]: Entry) => {
            return <EntityComponent
              key={key}
              title={key}
              rootName={`${rootName}.${key}`}
              showExtra={showExtra}
              extraTitle={extra}
              required={internalRequired}
            />
        })
    }, [])

    const renderOtherComponent = useMemo(() => {
        if (disableOther) {
            return undefined
        }

        return (
            <Box $row>
                <CheckBox title={otherName ?? 'other'} name={`${rootName}.otherItems`} required={internalRequired} />
                <Other name={`${rootName}.otherItems`}>
                    {(otherName) => (
                        <Loop name={otherName} defaultItem={{}}>
                            {(rootName, index) => (
                                <LoopHandler index={index}>
                                    <Box $width="auto">
                                        <EntityComponent
                                          width={70}
                                          rootName={rootName}
                                          custom="other"
                                          addon={index + 1}
                                          extraTitle={extra}
                                          showExtra={showOtherExtra}
                                        />
                                    </Box>
                                </LoopHandler>
                            )}
                        </Loop>
                    )}
                </Other>
            </Box>
        )
    }, [disableOther])

    return (
        <Wrapper
          id={rootName}
          title={title}
          checkAvailable={check ? rootName : false}
          offset={offset}
          required={required}
          disableRequiredContext={disableRequiredContext}
          component={component}
          hideRequiredNote={hideRequiredNote}
          disableParent={disableParent}
        >
            <Box $row>
                {renderComponents}
            </Box>
            {renderOtherComponent}
            <Note name={`${rootName}.note`} />
        </Wrapper>
    )
})
