import { Formik } from 'formik'
import styled from 'styled-components'
import { FormContextProvider } from 'context/form-context'
import { InclusiveContextProvider } from 'context/inclusive-context'
import { FactSheetData } from 'models/fact-sheet-data'

const InternalForm = styled.form`
    width: 100%;
`

interface FormProps<T> {
    defaultValue?: T
    onSubmit: (data: T) => void
}

export interface BaseItemProps {
    name?: string
    title?: string
}

type FormType<T> = React.PropsWithChildren & FormProps<T>

export const Form: React.FC<FormType<FactSheetData>> = ({
    defaultValue,
    onSubmit,
    children,
}) => {
    const deleteOtherKeys = (data: any) => {
        if (Array.isArray(data)) {
            for (const value of data) {
              deleteOtherKeys(value)
            }
        } else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach((key) => {
                if (key.endsWith('--OTHER_CHECK')) {
                    delete data[key]
                } else {
                    deleteOtherKeys(data[key])
                }
            })
        }
    }

    const prepareSubmitData = (data: FactSheetData) => {
        const preparedData = { ...data }
        deleteOtherKeys(preparedData)

        return onSubmit(preparedData)
    }

    return !!defaultValue && (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={defaultValue}
          validate={() => ({})}
          onSubmit={prepareSubmitData}
          enableReinitialize
        >
            {({
                handleSubmit,
            }) => (
                <FormContextProvider>
                    <InclusiveContextProvider>
                        <InternalForm onSubmit={handleSubmit} className="form">
                            { children }
                        </InternalForm>
                    </InclusiveContextProvider>
                </FormContextProvider>
            )}
        </Formik>
    )
}
