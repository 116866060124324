import { useMemo } from 'react'
import { Box } from 'components/container'
import { OtherInput } from 'components/presets/other-input'
import { Note } from 'components/presets/note'
import { CheckBox } from 'components/form/items/check-box'
import { Wrapper } from 'components/form/wrapper'
import { Component } from 'models/common'

export interface FacilitiesComponentProps {
    title: string
    root: string
    note: string
    values: string[]
    check?: any
    required?: boolean
    otherOnly?: boolean
    hideRequiredNote?: boolean
}

export const FacilitiesComponent: React.FC<FacilitiesComponentProps> = ({
    title,
    root,
    note,
    values,
    check,
    required,
    otherOnly,
    hideRequiredNote,
}) => {
    const req = required && !otherOnly ? {
        required: root,
    } : undefined

    const rootName = `accommodationFacilities.${root}`

    const createCheckValues = useMemo(() => values.map((name) => (
        <CheckBox key={`${rootName}.${name}`} title={name} name={`${rootName}.${name}`} required={req} />
    )), [values, rootName])

    return (
        <Wrapper
          id={root}
          title={title}
          checkAvailable={check}
          required={required}
          component={Component.ACCOMMODATION_FACILITIES}
          hideRequiredNote={hideRequiredNote}
        >
            <Box $row>
                <Box $wrap>
                    {createCheckValues}
                </Box>
                <CheckBox title="other" name={`${rootName}.otherItems`} fixedWidth={false} required={req} />
                <OtherInput name={`${rootName}.otherItems`} required={required} />
            </Box>
            <Note name={`accommodationFacilities.${note}`} />
        </Wrapper>
    )
}
