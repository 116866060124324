import { useMemo } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { useReadonly } from 'hooks/readonly-hook'
import { Required } from 'context/required-context'
import { useTranslationContext } from 'context/translation-context'
import { Item } from './item'

export enum InputType {
    TEXT,
    NUMBER,
    TEXT_AREA,
}

interface PlaceholderBoxProps {
    $emptyState?: boolean
    $disabled?: boolean
}

const InputItem = styled(Item)`
    position: relative;
`

const PlaceholderBox = styled.div<PlaceholderBoxProps>`
    font-weight: 600;
    position: absolute;
    z-index: 1;
    color: #70758C;
    margin-top: 7px;
    margin-left: 12px;
    pointer-events: none;

    ${({ $emptyState }) =>
        $emptyState &&
        `
        font-size: 9px;
        top: -12px;
        background: rgb(255,255,255);
        background: linear-gradient(
            to top,
            #F8F8F8 0%,
            #F8F8F8 50%,
            white 50%,
            white 100%
        );
        padding: 0px 2px;
    `};

    ${({ $disabled }) =>
        $disabled &&
        `
        color: #70758CBC;
    `};
`

interface PlaceholderProps {
    title?: string
    children: any
    state: boolean
    flex?: boolean
    name?: string
    required?: Required
}

export const Placeholder: React.FC<PlaceholderProps> = ({
    title,
    children,
    state,
    flex,
    name,
    required,
}) => {
    const { translate } = useTranslationContext()
    const [isReadonly] = useReadonly()
    const [meta] = useField(children.props.name)

    const renderPlaceholder = useMemo(() => {
        return (
            <PlaceholderBox $emptyState={!(state === false && !meta.value)} $disabled={isReadonly}>
                { translate(title) }
            </PlaceholderBox>
        )
    }, [state, isReadonly, meta.value])

    return (
        <InputItem flex={flex} name={name} required={required}>
            {renderPlaceholder}
            {children}
        </InputItem>
    )
}
