import React, { createContext, useState, useMemo, useContext, useCallback, useEffect } from 'react'
import { useField, useFormikContext } from 'formik'

type Mapper = any[] | undefined
type InclusiveEnum = any
type UpdateCallback = (mapper: Mapper, index?: number) => void
type UpdateInclusiveCallback = (mapper: Mapper) => void

export interface InclusiveContextProps {
    update: UpdateCallback
    inclusiveRestaurants: InclusiveEnum
    length: number
    mapper: Mapper
}

export const InclusiveContext = createContext<InclusiveContextProps>(
    {} as InclusiveContextProps,
)

interface InclusiveContextProviderProps {
    children: React.ReactNode
}

export const InclusiveContextProvider: React.FC<InclusiveContextProviderProps> = ({
    children,
}) => {
    const root = 'meals.allInclList'

    const { setFieldValue } = useFormikContext()
    const [mapper, setMapper] = useState<Mapper>(undefined)
    const [inclusiveRestaurants, setRestaurants] = useState<InclusiveEnum>({})
    const [, meta] = useField(root)

    const setRestaurantsInternal = (mapper: Mapper) => {
        setRestaurants((mapper ?? [])
            .reduce((current, item, index) => ({ ...current, [item.name]: index }), {}))
    }

    const update = useCallback<UpdateCallback>((newMapper) => {
        if (newMapper?.length !== mapper?.length) {
            setMapper(newMapper)
        }

        const parsedMapper = (newMapper ?? []).map((item, internalIndex) => {
            const newName = mapper?.[internalIndex]?.name
            return {
                ...item,
                old: newName,
            }
        })
        setMapper(parsedMapper)
    }, [mapper])

    const updateInclusiveItems = useCallback<UpdateInclusiveCallback>((mapper) => {
        for (let index = 0; meta.value && index < meta.value.length; index += 1) {
            const rootArray = meta.value[index].allInclSpecs?.alaCarte?.otherItems ?? []
            const rootName = `meals.allInclList.[${index}].allInclSpecs.alaCarte.otherItems`

            const updatedArray = rootArray.map((item: any) => {
                const foundRestaurant = (mapper ?? [])
                    .find((restaurant) => (restaurant.old ?? restaurant.name) === item.name)

                if (!foundRestaurant) {
                    return undefined
                }

                return {
                    ...item,
                    name: foundRestaurant.name,
                }
            }).filter((item: any) => item)
            setFieldValue(rootName, updatedArray)
        }
    }, [meta.value])

    const providerContext = useMemo(() => ({
        update,
        mapper,
        inclusiveRestaurants,
        length: Object.keys(inclusiveRestaurants).length,
    }), [
        mapper,
        inclusiveRestaurants,
    ])

    useEffect(() => {
        if (mapper === undefined) {
            return
        }
        setRestaurantsInternal(mapper)
        updateInclusiveItems(mapper)
    }, [mapper])

    return (
        <InclusiveContext.Provider value={providerContext}>
            {children}
        </InclusiveContext.Provider>
    )
}

export const useInclusiveContext = () => {
    return useContext(InclusiveContext)
}
