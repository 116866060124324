import { FactSheetData } from './fact-sheet-data'
import { Component } from './common'

export type Guid = string

export enum Operation {
    PROVIDED = 'PROVIDED',
    REJECTED = 'REJECTED',
    UPDATED = 'UPDATED',
    RELEASED = 'RELEASED',
}

export enum State {
    CREATED = 'CREATED',
    RECALLED = 'RECALLED',
    PRINTED_TO_SIGN = 'PRINTED_TO_SIGN',
    SENT_TO_HOTEL = 'SENT_TO_HOTEL',
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED_BY_HOTEL = 'SUBMITTED_BY_HOTEL',
    PUBLISHED = 'PUBLISHED',
    CANCELLED = 'CANCELLED',
}

export enum Role {
    AGENCY = 'AGENCY',
    HOTEL_RESPONSIBLE = 'HOTEL_RESPONSIBLE',
    HOTEL_STAFF = 'HOTEL_STAFF',
}

export enum TokenRejectionReason {
    OTHER_USER_NO_OVERTAKE = 'OTHER_USER_NO_OVERTAKE',
    OTHER_USER_OVERTAKE_POSSIBLE = 'OTHER_USER_OVERTAKE_POSSIBLE',
    FORWARDED = 'FORWARDED',
    FORWARD_NOT_ACTIVE = 'FORWARD_NOT_ACTIVE',
    INVALID_UPDATE = 'INVALID_UPDATE',
    STATE_OVERALL = 'STATE_OVERALL',
    STATE_ROLE = 'STATE_ROLE',
}

export enum ErrorType {
    INCOMPLETE = 'INCOMPLETE',
    MISSING = 'MISSING',
}

export interface TokenData {
    release?: boolean
    overtake?: boolean
    rejectionReason?: TokenRejectionReason
    operation?: Operation
    tokenUuid?: Guid
    expiration?: Date
}

export interface Info {
    state?: State
    role?: Role
    isCurrentEditor: boolean
    token?: TokenData
}

export interface OtpRequirement {
    channel?: string
    maskedContact?: string
    jobTitle?: string
    fullName?: string
}

export interface FactSheetEdit {
    isCurrentEditor: boolean
}

export interface FactSheetDto {
    id: Guid
    state: State
    data: FactSheetData
    edit: FactSheetEdit
}

export interface CheckData {
    errorType: ErrorType
    category: Component
    subCategory: string
    summary: string
}
