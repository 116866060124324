import { Wrapper } from 'components/form/wrapper'
import { Box } from 'components/container'
import { Note } from 'components/presets/note'
import { Input } from 'components/form/items/input'
import { Label } from 'components/form/items/label'
import { Component } from 'models/common'

export interface CleaningProps {
    root: string
}

export const Cleaning: React.FC<CleaningProps> = ({
    root,
}) => {
    const rootName = `${root}.cleaningServices`

    return (
        <Wrapper title="cleaningTitle" id={rootName} required checkAvailable={rootName} component={Component.ACCOMMODATION_TYPES}>
            <Box>
                <Label title="changeTowels" required />
                <Input title="cleaningTimer" name={`${rootName}.changeTowels`} required />
            </Box>
            <Box>
                <Label title="changeLinen" required />
                <Input title="cleaningTimer" name={`${rootName}.changeLinen`} required />
            </Box>
            <Box>
                <Label title="cleaning" required />
                <Input title="cleaningTimer" name={`${rootName}.cleaning`} required />
            </Box>
            <Note name={`${rootName}.note`} />
        </Wrapper>
    )
}
