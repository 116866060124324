import { useMemo } from 'react'
import { Card } from 'components/main/card'
import { Component } from 'models/common'
import { EntityWrapper, EntryMapper } from './wrapper/entity-wrapper'

export const Honeymoon: React.FC = () => {
    const mapper = useMemo((): EntryMapper => ([
        ['flowers'],
        ['fruits'],
        ['wine'],
        ['refreshments'],
        ['water'],
    ]), [])

    return (
        <Card type={Component.HONEYMOON} required hideRequiredNote>
            <EntityWrapper rootName="meals.honeyMoon" data={mapper} component={Component.HONEYMOON} disableRequired />
        </Card>
    )
}
