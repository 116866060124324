import React, { createContext, useMemo, useContext, useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { Configuration } from 'models/config'

export interface ConfigurationContextProps {
    config: Configuration
}

export const ConfigurationContext = createContext<ConfigurationContextProps>(
    {} as ConfigurationContextProps,
)

type ConfigurationContextProviderProps = React.PropsWithChildren

export const ConfigurationContextProvider: React.FC<ConfigurationContextProviderProps> = ({
    children,
}) => {
    const [configuration, setConfiguration] = useState<Configuration>()

    const loadConfiguration = useCallback(async () => {
        const { data } = await axios.get('/config.json')
        setConfiguration({
            ...data,
            contextPath: 'factsheet',
        })
    }, [setConfiguration])

    useEffect(() => {
        loadConfiguration()
    }, [])

    const providerContext = useMemo(() => ({
        config: configuration ?? {
            baseUrl: '',
            contextPath: '',
        },
    }), [
        configuration,
    ])

    return configuration && (
        <ConfigurationContext.Provider value={providerContext}>
            {children}
        </ConfigurationContext.Provider>
    )
}

export const useConfigurationContext = () => {
    return useContext(ConfigurationContext)
}
