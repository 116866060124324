import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { Note } from 'components/presets/note'
import { OtherInputSwitcher } from 'components/presets/other-input-switcher'
import { Loop, LoopHandler } from 'components/form/loop'
import { Input, InputType } from 'components/form/items/input'
import { Other } from 'components/form/other'
import { CheckBox } from 'components/form/items/check-box'
import { Label } from 'components/form/items/label'
import { Component } from 'models/common'

export const Hotel: React.FC = () => {
    return (
        <Card type={Component.HOTEL_INFO} required>
            <Box>
                <Input title="build" name="hotelInfo.yearBuilt" type={InputType.NUMBER} required />
                <Input title="total" name="hotelInfo.totalRooms" type={InputType.NUMBER} required />
                <Input title="year" name="hotelInfo.yearRenovated" type={InputType.NUMBER} required={{ section: 'parts', highlight: false }} />
            </Box>
            <Other name="hotelInfo.yearRenovated" value={[null, undefined]} invert>
                <Box $row>
                    <Label title="renovated" offset />
                    <Box $row>
                        <OtherInputSwitcher
                          title="rooms"
                          required={{ required: 'parts' }}
                          name="hotelInfo.renovated.rooms"
                          input="year"
                          inputName="hotelInfo.renovated.roomsYear"
                          type={InputType.NUMBER}
                        />
                        <OtherInputSwitcher
                          title="areas"
                          required={{ required: 'parts' }}
                          name="hotelInfo.renovated.publicAreas"
                          input="year"
                          inputName="hotelInfo.renovated.publicAreasYear"
                          type={InputType.NUMBER}
                        />
                    </Box>
                    <Box $row>
                        <CheckBox title="other" name="hotelInfo.renovated.otherItems" autoWidth required={{ required: 'parts' }} />
                        <Other name="hotelInfo.renovated.otherItems">
                            {(otherName) => (
                                <Loop name={otherName} defaultItem={{}}>
                                    {(rootName, index) => (
                                        <LoopHandler index={index}>
                                            <Box $width="auto">
                                                <Input title="other" addon={index + 1} name={`${rootName}.name`} required />
                                                <Input title="year" name={`${rootName}.year`} type={InputType.NUMBER} required />
                                            </Box>
                                        </LoopHandler>
                                    )}
                                </Loop>
                            )}
                        </Other>
                    </Box>
                </Box>
            </Other>
            <Note name="hotelInfo.noteRenovated" />
        </Card>
    )
}
