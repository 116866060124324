import { useState, useCallback, useMemo, useEffect } from 'react'
import { styled } from 'styled-components'
import { Modal, BaseModalProps } from 'components/main/modal'
import { useTranslationContext } from 'context/translation-context'
import { OtpRequirement } from 'models/fact-sheet-dto'
import { Digits } from './code/digits'
import { Box } from '../container'

const Wrapper = styled(Box)`
    margin: 15px 0px;
`

const MainWrapper = styled.div`
    width: 100%;
`

const SubTitle = styled.p`
    color: #3D4466;
`

const Contact = styled.p`
    color: #3D4466;
    font-weight: 700;
    margin: auto;
`

export interface CodeModalProps extends BaseModalProps {
    otp: OtpRequirement
    callback: (code: number, fullName?: string, jobTitle?: string) => Promise<void>
}

export const CodeModal: React.FC<CodeModalProps> = ({
    otp,
    callback,
    ...baseModalProps
}) => {
    const { translate } = useTranslationContext()
    const [code, setCode] = useState<string>('      ')
    const [fullName, setFullName] = useState<string>('')
    const [jobTitle, setJobTitle] = useState<string>('')

    const handleClick = useCallback(async () => {
        await callback(+(code ?? 0), fullName, jobTitle)
        setCode('      ')
    }, [callback, code, setCode, fullName, jobTitle])

    const submitEnabled = useMemo((): boolean => {
        return !!code && code.length === 6
    }, [code])

    const modalProps = useMemo(() => ({
        ...baseModalProps,
        onHandleClick: handleClick,
        onHandleClose: true,
        handleTitle: 'buttons.submit',
        handleDisable: !submitEnabled,
        onCancelClick: () => setCode('      '),
    }), [baseModalProps, handleClick, submitEnabled, setCode])

    useEffect(() => {
        setFullName(otp.fullName ?? '')
        setJobTitle(otp.jobTitle ?? '')
    }, [otp])

    return (
        <Modal title="code.title" {...modalProps}>
            <MainWrapper>
                <SubTitle>{translate(`code.subTitle.${otp?.channel ?? 'unknown'}`)}</SubTitle>
                <Contact>{otp.maskedContact}</Contact>
            </MainWrapper>
            <Wrapper $row>
                <Digits value={code} onChange={setCode} />
            </Wrapper>
        </Modal>
    )
}
