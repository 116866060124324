import { useMemo } from 'react'
import { ButtonType } from 'components/button'
import { Role, State } from 'models/fact-sheet-dto'

export enum Buttons {
    /* Button styles */
    DEFAULT,
    HIGHLIGHT,
    HIGHLIGHT_BLUE,
    /* Button actions */
    SENT_TO_HOTEL,
    RECALL_PROCESS,
    SUBMIT,
    PRINT_TO_SIGN,
    PREVIEW,
    CHECK,
    FORWARD_INTERNALLY,
    SAVE_AND_RETURN,
    OVERTAKE,
}

export enum ButtonSize {
    DEFAULT,
    BIG,
}

export type EnabledProps = { [key in Role]?: State[] }

interface EditorType {
    show?: boolean
    enabled?: boolean
}

export interface MenuButtonProps {
    title?: string
    show?: Role[]
    enabled?: EnabledProps
    style?: ButtonType
    editor?: EditorType
    always?: boolean
    size?: ButtonSize,
}

export type MenuButtonResult = { [key in Buttons]: MenuButtonProps }

export const useMenuButtons = (): [MenuButtonResult] => {
    const checkStates = [State.CREATED, State.SENT_TO_HOTEL, State.IN_PROGRESS, State.RECALLED]

    const showMapper = useMemo((): MenuButtonResult => ({
        [Buttons.SENT_TO_HOTEL]: {
            title: 'buttons.send',
            show: [
                Role.AGENCY,
            ],
            enabled: {
                [Role.AGENCY]: [State.CREATED, State.RECALLED],
            },
            style: ButtonType.HIGHLIGHT_BLUE,
        },
        [Buttons.RECALL_PROCESS]: {
            title: 'buttons.recall',
            show: [
                Role.AGENCY,
            ],
            enabled: {
                [Role.AGENCY]: [State.SENT_TO_HOTEL, State.IN_PROGRESS],
            },
        },
        [Buttons.SUBMIT]: {
            title: 'buttons.submit',
            editor: {
                enabled: true,
            },
            show: [
                Role.HOTEL_RESPONSIBLE,
            ],
            enabled: {
                [Role.HOTEL_RESPONSIBLE]: [State.IN_PROGRESS],
            },
            style: ButtonType.HIGHLIGHT_BLUE,
        },
        [Buttons.PRINT_TO_SIGN]: {
            title: 'buttons.signing',
            show: [
                Role.AGENCY,
            ],
            enabled: {
                [Role.AGENCY]: [State.CREATED, State.RECALLED],
            },
            style: ButtonType.HIGHLIGHT_BLUE,
        },
        [Buttons.PREVIEW]: {
            title: 'buttons.preview',
            always: true,
        },
        [Buttons.CHECK]: {
            title: 'buttons.check',
            show: [
                Role.AGENCY, Role.HOTEL_RESPONSIBLE, Role.HOTEL_STAFF,
            ],
            enabled: {
                [Role.AGENCY]: checkStates,
                [Role.HOTEL_RESPONSIBLE]: checkStates,
                [Role.HOTEL_STAFF]: checkStates,
            },
        },
        [Buttons.FORWARD_INTERNALLY]: {
            title: 'buttons.forward',
            editor: {
                show: true,
            },
            show: [
                Role.HOTEL_RESPONSIBLE,
            ],
            enabled: {
                [Role.HOTEL_RESPONSIBLE]: [State.IN_PROGRESS],
            },
        },
        [Buttons.SAVE_AND_RETURN]: {
            title: 'buttons.save',
            editor: {
                show: true,
            },
            show: [
                Role.HOTEL_STAFF,
            ],
            enabled: {
                [Role.HOTEL_STAFF]: [State.IN_PROGRESS],
            },
            style: ButtonType.HIGHLIGHT_BLUE,
        },
        [Buttons.OVERTAKE]: {
            title: 'buttons.overtake',
            editor: {
                show: false,
            },
            show: [
                Role.HOTEL_RESPONSIBLE,
            ],
            enabled: {
                [Role.HOTEL_RESPONSIBLE]: [State.IN_PROGRESS],
            },
        },
        [Buttons.DEFAULT]: {
            always: true,
            style: ButtonType.DEFAULT,
        },
        [Buttons.HIGHLIGHT]: {
            always: true,
            style: ButtonType.HIGHLIGHT,
        },
        [Buttons.HIGHLIGHT_BLUE]: {
            always: true,
            style: ButtonType.HIGHLIGHT_BLUE,
        },
    }), [])

    return [
        showMapper,
    ]
}
