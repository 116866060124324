import { Box } from 'components/container'
import { Other } from 'components/form/other'
import { Loop, LoopHandler } from 'components/form/loop'
import { CheckBox } from 'components/form/items/check-box'
import { Input, InputType } from 'components/form/items/input'
import { Select } from 'components/form/items/select'
import { MultipleDatePicker } from 'components/presets/multiple-date-picker'
import { Water } from 'models/fact-sheet-data'

export const PoolItems: React.FC = () => {
    return (
        <Box $row>
            <CheckBox title="other" name="swimmingPools.otherPools" required={{ required: 'swimmingPools' }} />
            <Other name="swimmingPools.otherPools">
                {(otherName) => (
                    <Loop name={otherName} defaultItem={{}}>
                        {(rootName, index) => (
                            <LoopHandler index={index}>
                                <Box $width="auto">
                                    <Input title="other" addon={index + 1} name={`${rootName}.name`} required />
                                    <Input title="many" name={`${rootName}.count`} type={InputType.NUMBER_RANGE} defaultValue={1} required />
                                    <Select title="type" name={`${rootName}.waterType`} type={Water} root="water" required />
                                    <MultipleDatePicker title="when" first={`${rootName}.heatingPeriod1`} second={`${rootName}.heatingPeriod2`} />
                                </Box>
                            </LoopHandler>
                        )}
                    </Loop>
                )}
            </Other>
        </Box>
    )
}
