import { Button as Btn } from 'antd'
import styled from 'styled-components'

export enum ButtonType {
    DEFAULT,
    HIGHLIGHT,
    HIGHLIGHT_BLUE,
}

export interface InternalButtonProps {
    $type?: ButtonType
}

export const InternalButton = styled(Btn)<InternalButtonProps>`
    border-radius: 5px;
    border: 1px solid #00B6FF;
    color: #00B6FF;
    font-size: 12px;
    background: 0;

    :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
    :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        border: 1px solid #00A4E6;
        color: #00A4E6;
    }

    ${({ disabled, $type }) =>
        disabled && $type === ButtonType.DEFAULT &&
        `
        :where(&).ant-btn-default:disabled {
            background: 0;
            color: #70758C;
            border: 1px solid #70758CBD;
        }
    `};

    ${({ disabled, $type }) =>
        disabled && $type === ButtonType.HIGHLIGHT_BLUE &&
        `
        :where(&).ant-btn-default:disabled {
            background: #EFEFEF;
            color: #70758C;
            border: 1px solid #EFEFEF;
        }
    `};

    ${({ $type }) =>
        $type === ButtonType.HIGHLIGHT &&
        `
        background: #FFFFFF;
    `};

    ${({ $type }) =>
        $type === ButtonType.HIGHLIGHT_BLUE &&
        `
        background: #00B6FF;
        color: white;

        :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
        :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
            background: #00A4E6;
            border: 1px solid #00A4E6;
            color: white;
        }
    `};
`
