import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { Note } from 'components/presets/note'
import { Loop, LoopHandler } from 'components/form/loop'
import { OtherInput } from 'components/presets/other-input'
import { Input } from 'components/form/items/input'
import { CheckBox } from 'components/form/items/check-box'
import { Label } from 'components/form/items/label'
import { Component } from 'models/common'

export const Location: React.FC = () => {
    const required = {
        required: 'location',
    }

    return (
        <Card type={Component.LOCATION} required>
            <Box $row>
                <Box>
                    <CheckBox title="hill" name="location.isOnTheHill" required={required} />
                    <CheckBox title="slope" name="location.isOnTheSlope" required={required} />
                    <CheckBox title="plain" name="location.isOnThePlain" required={required} />
                </Box>
                <CheckBox title="other" name="location.otherItems" required={required} />
                <OtherInput name="location.otherItems" required />
            </Box>
            <Box>
                <Label title="nearest" required />
                <Input title="name" name="location.nearestCenterName" required />
                <Input title="distance" name="location.nearestCenterDistance" required />
            </Box>
            <Box>
                <Label title="shop" required />
                <Input title="distance" name="location.nearestShops" required />
            </Box>
            <Box>
                <Label title="restaurant" required />
                <Input title="distance" name="location.nearestRestaurantsAndBars" required />
            </Box>
            <Box>
                <Label title="bus" />
                <Input title="distance" name="location.busStationDescription" />
                <Input title="going" name="location.busDestination" />
            </Box>
            <Loop name="location.airports" defaultItem={{}} maxCount={4}>
                {(rootName, index) => (
                    <LoopHandler index={index} otherOnly>
                        <Box $width="auto">
                            <Label title={index === 0 ? 'airport' : ''} required />
                            <Input title="name" name={`${rootName}.name`} required />
                            <Input title="distance" name={`${rootName}.distance`} required />
                        </Box>
                    </LoopHandler>
                )}
            </Loop>
            <Note name="location.note" />
        </Card>
    )
}
