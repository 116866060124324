import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { useFormikContext } from 'formik'
import { useAxiosContext } from 'context/axios-context'

type SubmitType = () => Promise<void>

export interface FormContextProps {
    submit: SubmitType
}

export const FormContext = createContext<FormContextProps>(
    {} as FormContextProps,
)

type FormContextProviderProps = React.PropsWithChildren

export const FormContextProvider: React.FC<FormContextProviderProps> = ({
    children,
}) => {
    const { setWaitForUpdate } = useAxiosContext()
    const { submitForm } = useFormikContext()

    const delay = (ms: number): Promise<any> => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms)
        })
    }

    const onSubmit = useCallback(async () => {
        await delay(500)
        submitForm()
        setWaitForUpdate(false)
    }, [submitForm, delay])

    const providerContext = useMemo(() => ({
        submit: onSubmit,
    }), [
        submitForm,
    ])

    return (
        <FormContext.Provider value={providerContext}>
            {children}
        </FormContext.Provider>
    )
}

export const useFormContext = () => {
    return useContext(FormContext)
}
