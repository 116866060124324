import React, { useState, createContext, useRef, useMemo, useContext, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { useSubmitContext } from 'context/submit-context'
import { RequiredType, Component } from 'models/common'

type RequiredBase = boolean | string

interface RequiredModel {
    required?: RequiredBase
    section?: string
    highlight?: boolean
    isModel?: boolean
}

export type Required = RequiredBase | RequiredModel

type RegisterIdentifier = string

type RegisterCallback = (
    name?: RegisterIdentifier,
    required?: Required,
) => void
type UnregisterCallback = (name?: string, includes?: boolean) => void

interface SectionItem {
    data: string[]
    sectionChecker: any
}

type Section = { [key: string]: SectionItem }
type FieldData = { [key: string]: Required }

export interface RequiredContextProps {
    register: RegisterCallback
    unregister: UnregisterCallback
    isValidField: (name?: string) => boolean
    isValid: boolean
    requiredType: RequiredType
    isAvailable: boolean
    setAvailable: (value: boolean) => void
}

export const RequiredContext = createContext<RequiredContextProps>(
    {} as RequiredContextProps,
)

export const useRequiredContext = () => {
    return useContext(RequiredContext)
}

interface RequiredContextProviderProps {
    required?: boolean
    component: Component
    children: React.ReactNode
    additional?: boolean
    disableParent?: boolean
    id: string
}

export const parseRequiredModel = (name?: RegisterIdentifier, required?: Required): any => {
    if (!required || required === true) {
        return { required, highlight: required }
    }

    if (typeof required === 'string' || required instanceof String) {
        return {
            required: true,
            highlight: true,
            sectionName: required,
        }
    }

    const isSection = !!required.section

    const result = parseRequiredModel(undefined, required.required ?? !isSection)
    return {
        highlight: required.highlight ?? result.highlight,
        required: result.required,
        sectionName: result.sectionName,
        sectionCheckerName: required.section,
        sectionChecker: isSection ? name : false,
        isModel: true,
    }
}

export const RequiredContextProvider: React.FC<RequiredContextProviderProps> = ({
    children,
    component,
    required,
    additional,
    disableParent,
    id,
}) => {
    const { check } = useSubmitContext()
    const parent = useRequiredContext()

    const { getFieldMeta } = useFormikContext()
    const [isAvailable, setAvailable] = useState(true)
    const requiredFields = useRef<string[]>([])
    const requiredSections = useRef<Section>({})
    const requiredData = useRef<FieldData>({})

    const register = useCallback<RegisterCallback>((name, fieldRequired) => {
        if (!name || !required) {
            return
        }

        const parsed = parseRequiredModel(name, fieldRequired)
        if (!parsed.required && !parsed.sectionChecker) {
            return
        }

        if (parsed.sectionName) {
            const section = requiredSections.current[parsed.sectionName] ?? {
                data: [],
            }

            if (!section.data.includes(name) && parsed.required) {
                section.data.push(name)
            }
            requiredSections.current[parsed.sectionName] = section
        } else if (!requiredFields.current.includes(name) && !parsed.sectionChecker) {
            requiredFields.current.push(name)
        }

        if (parsed.sectionChecker) {
            const section = { ...requiredSections.current[parsed.sectionCheckerName] }
            requiredSections.current[parsed.sectionCheckerName] = {
                data: section.data ?? [],
                sectionChecker: parsed.sectionChecker,
            }
        }

        requiredData.current[name] = parsed
    }, [required, requiredFields.current, requiredSections, requiredData])

    const unregister = useCallback<UnregisterCallback>((name, includes) => {
        if (name && (requiredFields.current.includes(name) || includes)) {
            const filteredArray = requiredFields.current.filter((item) => {
                if (includes) {
                    return !item.includes(name)
                }
                return item !== name
            })
            requiredFields.current = filteredArray
        }
    }, [requiredFields.current])

    const invalidField = useCallback((name: string) => {
        const { value } = getFieldMeta(name)
        return !value || `${value}`.trim().length === 0
    }, [getFieldMeta])

    const isInvalidPart = useCallback((key: string) => {
        const { data, sectionChecker } = requiredSections.current[key]
        if (sectionChecker && invalidField(sectionChecker)) {
            return false
        }
        return data.every(invalidField)
    }, [requiredSections, invalidField])

    const isValidField = useCallback((name?: string) => {
        const data: any = requiredData.current[name ?? '']
        if (!data || !name) {
            return true
        }

        if (data.sectionName && !isInvalidPart(data.sectionName)) {
            return true
        }

        const { value } = getFieldMeta(name)
        return (!data.highlight || !!value) && `${value}`.trim().length > 0
    }, [getFieldMeta, requiredData, isInvalidPart])

    const validFields = useCallback(() => {
        const invalidParts = Object.keys(requiredSections.current).filter(isInvalidPart)
        const invalidFields = requiredFields.current.filter(invalidField)
        const validAdditional = additional === undefined || additional

        return invalidFields.length === 0 && invalidParts.length === 0 && validAdditional
    }, [requiredFields, requiredSections, isInvalidPart, invalidField, additional])

    const requiredType = useMemo(() => {
        const mapper: { [key in Component]?: RequiredType } = {
            [Component.ACCOMMODATION_TYPES]: RequiredType.ROOMS,
            [Component.BEACH]: RequiredType.PLUS,
        }
        return mapper[component] ?? RequiredType.ONCE_PLUS
    }, [component])

    const checkParentAvailable = useMemo(() => {
        if (parent.isAvailable === undefined || disableParent) {
            return true
        }
        return parent.isAvailable
    }, [parent.isAvailable, disableParent])

    const providerContext = useMemo(() => ({
        register,
        unregister,
        isValidField,
        isValid: !required || (validFields() ?? false),
        requiredType,
        isAvailable: isAvailable && checkParentAvailable,
        setAvailable,
    }), [
        register,
        unregister,
        isValidField,
        validFields,
        requiredType,
        isAvailable,
        setAvailable,
        checkParentAvailable,
    ])

    useEffect(() => {
        const internalCheck = !required || providerContext.isValid || !providerContext.isAvailable
        check(id, internalCheck)
    }, [check, required, providerContext.isValid, providerContext.isAvailable])

    return (
        <RequiredContext.Provider value={providerContext}>
            {children}
        </RequiredContext.Provider>
    )
}
