import styled from 'styled-components'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { useTranslationContext } from 'context/translation-context'
import { Box, gap, width } from '../container'

const MainContent = styled(Box)`
    background: #CBE2EE;
    justify-content: center;
    padding: ${gap}px 0px;
`

const ContentBox = styled(Box)`
    gap: calc(${gap}px * 4);
`

const Text = styled.p`
    height: 28px;
    line-height: 28px;
    width: 100%;
    text-align: center;
    font-size: 12px;
`

export const DisabledMenu: React.FC = () => {
    const { info } = useFactSheetContext()
    const { translate } = useTranslationContext()

    const reason = info.token?.rejectionReason

    return (
        <MainContent>
            <ContentBox $width={`${width}px`}>
                <Text>{translate('disabled.reason', { params: [reason ?? 'unknown'] })}</Text>
            </ContentBox>
        </MainContent>
    )
}
