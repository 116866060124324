import { Other } from '../form/other'
import { Box } from '../container'
import { Input } from '../form/items/input'
import { Loop, LoopHandler } from '../form/loop'

interface OtherInputProps {
    name: string
    loopRoot?: string
    required?: boolean
    onRemove?: (list: any[], index: number) => void
    onChange?: (value: any, old: any) => void
}

export const OtherInput: React.FC<OtherInputProps> = ({
    name,
    loopRoot,
    required,
    onRemove,
    onChange,
}) => {
    return (
        <Other name={name}>
            {(otherName) => (
                <Loop name={loopRoot ?? otherName} defaultItem="">
                    {(rootName, index) => (
                        <LoopHandler index={index} onRemove={onRemove}>
                            <Box $flex>
                                <Input
                                  onChange={onChange}
                                  title="other"
                                  addon={index + 1}
                                  name={rootName}
                                  fixedWidth={false}
                                  required={required}
                                />
                            </Box>
                        </LoopHandler>
                    )}
                </Loop>
            )}
        </Other>
    )
}
