import { useEffect, useState, useCallback } from 'react'
import { useField } from 'formik'
import { styled } from 'styled-components'
import { useFormContext } from 'context/form-context'
import { Box, columnGap, widthCalculator } from '../container'
import { DatePicker } from './date-picker'

const width = widthCalculator(2) + columnGap

const DateWrapper = styled(Box)`
    gap: 0;
    width: ${width}px;
    margin-right: -${columnGap}px;
`

const Symbol = styled.button<any>`
    width: ${columnGap}px;
    height: 32px;
    background: 0;
    color: #00B6FF;
    border: 0;
    font-size: 20px;
    cursor: pointer;

    ${({ $disabled }) =>
        $disabled &&
        `
        color: #70758C;
        cursor: unset;
    `};
`

interface MultipleDatePickerProps {
    title: string
    first: string
    second: string
}

export const MultipleDatePicker: React.FC<MultipleDatePickerProps> = ({
    title,
    first,
    second,
}) => {
    const { submit } = useFormContext()
    const [renderOther, setRenderOther] = useState(false)
    const [enablePlus, setEnablePlus] = useState(false)
    const [, meta] = useField(first)
    const [, otherMeta, helpers] = useField(second)

    const notValidDate = (date: any) => {
        return !date || !date.from?.value || !date.to?.value
    }

    const updatePlusValue = useCallback((value: any) => {
        const enable = !notValidDate(value)
        setEnablePlus(enable)
        return enable
    }, [])

    const onPlus = useCallback(() => {
        if (!enablePlus) {
            return
        }
        setRenderOther(true)
        setEnablePlus(false)
    }, [enablePlus])

    const onRemove = useCallback(() => {
        helpers.setValue(undefined)
        setRenderOther(false)
        updatePlusValue(meta.value)
        submit()
    }, [submit, meta.value, helpers.setValue])

    const checkPlusButton = useCallback((value: any) => {
        const enable = updatePlusValue(value)
        if (!enable) {
            onRemove()
        }
    }, [onRemove])

    useEffect(() => {
        checkPlusButton(meta.value)
    }, [meta.value])

    useEffect(() => {
        if (!notValidDate(otherMeta.value)) {
            setRenderOther(true)
            setEnablePlus(false)
        }
    }, [])

    return (
        <DateWrapper>
            <DatePicker title={title} name={first} />
            <Symbol type="button" $disabled={!enablePlus} onClick={onPlus}>+</Symbol>
            {renderOther && (
                <>
                    <DatePicker title={title} name={second} />
                    <Symbol type="button" onClick={onRemove}>-</Symbol>
                </>
            )}
        </DateWrapper>
    )
}
