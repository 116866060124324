import styled from 'styled-components'
import { Box } from '../container'
import { BaseItemProps } from '../form/form'
import { Input, InputType } from '../form/items/input'

type NoteProps = BaseItemProps

const NoteBox = styled(Box)`
    margin-top: 5px;
`

export const Note: React.FC<NoteProps> = ({
    name,
}) => {
    return (
        <NoteBox>
            <Input title="note" name={name} type={InputType.TEXT_AREA} fixedWidth={false} max={700} />
        </NoteBox>
    )
}
