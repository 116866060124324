import axios from 'axios'
import { useMemo, useCallback } from 'react'
import {
    TokenRequestBody,
    UpdateRequestBody,
    StateRequestBody,
    StaffRequestBody,

    HeaderResponse,
    TokenResponse,
    UpdateResponse,
    CheckResponse,
    HistoryResponse,
    StaffResponse,
} from 'models/fact-sheet-api'
import { Guid } from 'models/fact-sheet-dto'
import { useConfigurationContext } from 'context/configuration-context'

interface AxiosMethods {
    callHeader(): Promise<HeaderResponse>
    callStaff(data?: StaffRequestBody): Promise<StaffResponse>
    callEmails(): Promise<string[]>
    callToken(data?: TokenRequestBody): Promise<TokenResponse>
    callUpdate(data?: UpdateRequestBody): Promise<UpdateResponse>
    callPreview(): Promise<Blob>
    callExport(): Promise<Blob>
    callSetState(data?: StateRequestBody): Promise<UpdateResponse>
    callCheck(): Promise<CheckResponse>
    callHistory(): Promise<HistoryResponse>
    callOtpRequirement(): Promise<void>
}

type AxiosHook = (id: Guid) => AxiosMethods

export const useAxios: AxiosHook = (id) => {
    const { config: {
        baseUrl,
        contextPath,
    } } = useConfigurationContext()

    const useInstance = useCallback(() => {
        const instance = axios.create({
            baseURL: `${baseUrl}/${contextPath}/${id}`,
        })

        instance.interceptors.request.use(
            (config) => {
                return config
            },
            (error) => {
                return error
            },
        )

        instance.interceptors.response.use(
            (response) => {
                return response.data
            },
            (error) => {
                return { ...error, isError: true }
            },
        )

        return instance
    }, [id, baseUrl, contextPath])

    const instance = useInstance()

    return useMemo((): AxiosMethods => ({
        callHeader: () => instance({ method: 'GET' }),
        callStaff: (data: StaffRequestBody) => instance({ method: 'PUT', url: '/staff', data }),
        callEmails: () => instance({ method: 'GET', url: '/staff' }),
        callToken: (data?: TokenRequestBody) => instance({ method: 'PUT', url: '/token', data }),
        callUpdate: (data?: UpdateRequestBody) => instance({ method: 'PUT', data }),
        callPreview: () => instance({ method: 'GET', url: '/preview', responseType: 'blob' }),
        callSetState: (data?: StateRequestBody) => instance({ method: 'PUT', url: '/state', data }),
        callHistory: () => instance({ method: 'GET', url: '/state' }),
        callExport: () => instance({ method: 'GET', url: '/state/export', responseType: 'blob' }),
        callCheck: () => instance({ method: 'GET', url: 'check' }),
        callOtpRequirement: () => instance({ method: 'POST', url: 'otp' }),
    }), [instance])
}
