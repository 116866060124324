import { useMemo } from 'react'
import { Modal, BaseModalProps } from 'components/main/modal'

export type ConfirmAction = () => Promise<any>

export interface ConfirmProps {
    title: string
    callback?: ConfirmAction
}

export interface ConfirmModalProps extends BaseModalProps, ConfirmProps {
}

export const ConfirmModal: React.FC<ConfirmModalProps> = ({
    title,
    callback,
    ...baseModalProps
}) => {
    const modalProps = useMemo(() => ({
        ...baseModalProps,
        onHandleClick: callback,
        onHandleClose: true,
        handleTitle: 'buttons.confirm',
    }), [baseModalProps, callback])

    return (
        <Modal title={title} {...modalProps} />
    )
}
