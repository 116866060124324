import { useMemo } from 'react'
import { Outlet as PageContent } from 'react-router-dom'
import { Spin } from 'antd'
import styled from 'styled-components'
import { Page, Box, width } from 'components/container'
import { Header } from 'components/main/header'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { AxiosContextProvider } from 'context/axios-context'
import { SubmitContextProvider } from 'context/submit-context'
import { useLoadContext } from 'context/load-context'
import { ErrorHandler } from './error'

const MainSpinner = styled(Spin)`
    z-index: 1100;
`

export const Layout: React.FC = () => {
    const { isValidFactSheet } = useFactSheetContext()
    const { isLoading } = useLoadContext()

    const contentWidth = useMemo(() => (
        isValidFactSheet ? `${width}px` : '100%'
    ), [isValidFactSheet, width])

    return (
        <AxiosContextProvider>
            <Page>
                <SubmitContextProvider>
                    {isValidFactSheet && (
                        <Header />
                    )}
                    <Box $width={contentWidth} $row>
                        <ErrorHandler>
                            <PageContent />
                        </ErrorHandler>
                    </Box>
                    <MainSpinner spinning={isLoading} fullscreen />
                </SubmitContextProvider>
            </Page>
        </AxiosContextProvider>
    )
}
