import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { useField } from 'formik'

export interface AutoCheckContextProps {
    autoCheck: () => void
    disable: boolean
}

export const AutoCheckContext = createContext<AutoCheckContextProps>(
    {} as AutoCheckContextProps,
)

interface AutoCheckContextProviderProps extends React.PropsWithChildren {
    root: string
}

export const AutoCheckContextProvider: React.FC<AutoCheckContextProviderProps> = ({
    root,
    children,
}) => {
    const [, meta, helpers] = useField(root)

    const autoCheck = useCallback(() => {
        if (meta.value) {
            return
        }
        helpers.setValue(true)
    }, [meta?.value])

    const providerContext = useMemo(() => ({
        autoCheck,
        disable: !meta?.value,
    }), [
        autoCheck,
        meta?.value,
    ])

    return (
        <AutoCheckContext.Provider value={providerContext}>
            {children}
        </AutoCheckContext.Provider>
    )
}

export const useAutoCheckContext = () => {
    return useContext(AutoCheckContext)
}
