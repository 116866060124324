import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslationContext } from 'context/translation-context'
import { useRequiredContext } from 'context/required-context'
import { useReadonly } from 'hooks/readonly-hook'
import { Component } from 'models/common'
import { Box, columnGap } from '../container'
import { TooltipIcon } from '../tooltip'
import { RequiredCheck } from './required-check'

const IconBox = styled(Box)`
    gap: 8px;
`

const TitleWrapper = styled(Box)`
    gap: calc(${columnGap}px / 2);
`

const Title = styled.h4<any>`
    --size: ${({ $big }: any) => ($big ? '19px' : '16px')};
    --weight: ${({ $big }: any) => ($big ? '600' : '500')};
    --color: ${({ $big }: any) => ($big ? '#153C89' : '#141F33')};

    ${({ $invalid }) => `
        color: ${$invalid ? '#FF0040' : 'var(--color)'};
    `};

    font-size: var(--size);
    font-family: Duepuntozero;
    font-weight: var(--weight);
`

export interface RequiredTitleProps {
    big?: boolean
    required?: boolean
    title: string
    checkAvailable?: any
    hideRequiredNote?: boolean
}

export const RequiredTitle: React.FC<RequiredTitleProps> = ({
    required,
    big,
    title,
    checkAvailable,
    hideRequiredNote,
}) => {
    const { isValid, requiredType } = useRequiredContext()
    const [isReadonly] = useReadonly()
    const { translate, type } = useTranslationContext()

    const parsedRoot = useMemo(() => {
        const parseType = () => {
            switch (type) {
                case Component.ACCOMMODATION_FACILITIES:
                    return 'accommodationFacilities'
                case Component.HOTEL_INFO:
                    return 'hotelInfo'
                case Component.BEACH:
                    return 'beach'
                case Component.SWIMMING_POOLS:
                    return 'swimmingPools'
                case Component.WATER_SLIDES:
                    return 'waterSlides'
                case Component.INTERNET:
                    return 'internet'
                default:
                    return null
            }
        }

        if (!checkAvailable) {
            return null
        }
        return checkAvailable === true ? parseType() : checkAvailable
    }, [type, checkAvailable])

    return (
        <Box $row>
            {title && title.length > 0 && (
                <TitleWrapper>
                    <Title $big={big} $invalid={!isValid && !isReadonly && required}>{title}</Title>
                    {required && !hideRequiredNote && (
                        <IconBox $width="auto">
                            <Title $big={big} $invalid>*</Title>
                            <TooltipIcon text={translate('required', { params: [requiredType] })} place="topRight" />
                        </IconBox>
                    )}
                </TitleWrapper>
            )}
            {checkAvailable && parsedRoot && (
                <RequiredCheck name={`${parsedRoot}.notAvailable`} title="notAvailable" />
            )}
        </Box>
    )
}
