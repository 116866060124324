import { useEffect, useCallback } from 'react'
import { Checkbox as Check } from 'formik-antd'
import { useField } from 'formik'
import styled from 'styled-components'
import { Box } from 'components/container'
import { TooltipIcon } from 'components/tooltip'
import { useFormContext } from 'context/form-context'
import { useRequiredContext } from 'context/required-context'
import { useTranslationContext } from 'context/translation-context'
import { AutoCheckContextProvider, useAutoCheckContext } from 'context/auto-check-context'
import { useReadonly } from 'hooks/readonly-hook'
import { BaseStyle } from '../../input'
import { Item, ItemBoxProps } from '../item'
import { BaseItemProps } from '../form'
import { useOtherField } from '../other'

interface CheckBoxProps extends BaseItemProps, ItemBoxProps, React.PropsWithChildren {
    bold?: boolean
    isRow?: boolean
    onChange?: (value: boolean) => void
    isDisabled?: boolean
    info?: string
}

const BoxItem = styled(Item)`
    gap: 8px;
`

const CheckItem = styled(Check)<any>`
    --color: ${({ $required }: any) => ($required ? '#FF0040' : 'white')};
    --border: ${({ $required }: any) => ($required ? '1px solid var(--color)' : '0')};

    :where(.ant-checkbox):not(.ant-checkbox-checked) .ant-checkbox-inner {
        background: #EFEFEF;
        border: 1px solid var(--color);
    }

    :where(&).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
        border-color: var(--color);
    }

    :where(.ant-checkbox):not(.ant-checkbox-checked):hover .ant-checkbox-inner {
        background: #D7D7D7;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked) .ant-checkbox-inner {
        background: #00B6FF;
        border: var(--border);
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):hover .ant-checkbox-inner {
        background: #00A4E6;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):is(.ant-checkbox-disabled) .ant-checkbox-inner {
        background: #70758C80;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):is(.ant-checkbox-disabled) .ant-checkbox-inner:after {
        border-color: var(--color);
    }
`

interface TitleProps {
    $disabled: boolean
    $bold: boolean
}

const Title = styled.div<TitleProps>`
    ${BaseStyle};
    margin-top: 2px;

    ${({ $disabled }) =>
        $disabled &&
        `
        color: #70758CBC;
    `};

    ${({ $bold }) =>
        $bold &&
        `
        font-weight: 600;
    `};
`

export const CheckBox: React.FC<CheckBoxProps> = ({
    name,
    title,
    children,
    bold,
    fixedHeight,
    fixedWidth,
    autoWidth,
    required,
    isRow,
    onChange,
    info,
    isDisabled,
}) => {
    const { isValidField } = useRequiredContext()
    const { translate } = useTranslationContext()
    const [isReadonly] = useReadonly()
    const { submit } = useFormContext()
    const { autoCheck, disable } = useAutoCheckContext()

    const [nameField, checkValue, check, isOther] = useOtherField(name ?? '')
    const [, meta, helpers] = useField(nameField)

    useEffect(() => {
        if (disable === undefined || !meta?.value || !disable) {
            return
        }
        helpers.setValue(false)
    }, [disable, meta?.value, helpers])

    useEffect(() => {
        if (isOther && !check) {
            helpers.setValue(false)
        } else if (isOther) {
            helpers.setValue(true)
        }
    }, [checkValue, check])

    const onChangeInternal = useCallback((value: any) => {
        if (onChange) {
            onChange(value.target.checked)
        }

        if (autoCheck) {
            autoCheck()
        }
        submit()
    }, [autoCheck, submit, onChange])

    const disabled = (check || isReadonly || isDisabled) ?? false

    return (
        <Box $width="auto" $row={isRow}>
            <BoxItem
              fixedHeight={fixedHeight}
              fixedWidth={fixedWidth}
              autoWidth={autoWidth}
              name={nameField}
              required={required}
            >
                <CheckItem
                  id={nameField}
                  name={nameField}
                  defaultChecked={check}
                  disabled={disabled}
                  onChange={onChangeInternal}
                  $required={!isValidField(nameField) && !isReadonly} />
                <Title $disabled={disabled} $bold={bold ?? false}>{translate(title)}</Title>
                {info && (
                    <TooltipIcon text={translate(info)} place="topRight" isAuto />
                )}
            </BoxItem>
            <AutoCheckContextProvider root={nameField}>
                {children}
            </AutoCheckContextProvider>
        </Box>
    )
}
