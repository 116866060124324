import { useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Other } from 'components/form/other'
import { Box } from 'components/container'
import { Input } from 'components/form/items/input'
import { Radio } from 'components/form/items/radio'
import { CheckBox } from 'components/form/items/check-box'
import { Cost } from 'models/fact-sheet-data'

const EntityBox = styled(Box)`
    flex: 1;
`

export interface EntityComponentProps {
    rootName: string
    title?: string,
    hideCheck?: boolean
    showExtra?: boolean
    extraTool?: string
    custom?: string
    addon?: any
    extraTitle?: string
    width?: number
    required?: any
    children?: React.ReactNode
}

export const EntityComponent: React.FC<EntityComponentProps> = ({
    rootName,
    title,
    showExtra,
    extraTitle,
    extraTool,
    custom,
    addon,
    hideCheck,
    required,
    children,
}) => {
    const props = useMemo(() => {
        const check = !custom || custom.length === 0
        return {
            useCheck: check,
            name: check ? `${rootName}.checked` : `${rootName}.name`,
        }
    }, [custom, hideCheck])

    const renderInputComponent = useMemo(() => {
        if (hideCheck) {
            return undefined
        }

        if (!props.useCheck) {
            return <Input title={custom} addon={addon} name={props.name} required />
        }
        return <CheckBox title={title} name={props.name} required={required} />
    }, [props, hideCheck])

    const renderItem = useMemo(() => {
        return showExtra && (
            <Input title={extraTitle} tooltip={extraTool} name={`${rootName}.infoPlus`} required />
        )
    }, [])

    const renderForm = useCallback(() => (
        <Box $width="auto">
            <Radio name={`${rootName}.freeExtra.chargeType`} type={Cost} title="cost" required />
            <Box $length={1}>
                <Other name={`${rootName}.freeExtra.chargeType`} value="EXTRA" isAuto enableReset>
                    <Input title="charge" name={`${rootName}.freeExtra.info`} required />
                </Other>
            </Box>
            {renderItem}
            {children}
        </Box>
    ), [renderItem, showExtra, children])

    return (
        <Box>
            {renderInputComponent}
            <EntityBox>
                {(hideCheck || !props.useCheck) && renderForm()}
                {!hideCheck && (
                    <Other name={props.name}>
                        {renderForm()}
                    </Other>
                )}
            </EntityBox>
        </Box>
    )
}
