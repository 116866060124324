import styled from 'styled-components'
import { PageType } from 'app/router'
import { Box } from 'components/container'

const InvalidWrapper = styled(Box)`
    margin: 10px;
`

export const Invalid: PageType = () => {
    return (
        <InvalidWrapper $row>
            <h1>FACTSHEET APPLICATION</h1>
            <h3>Please use the link provided to you by the system.</h3>
        </InvalidWrapper>
    )
}
