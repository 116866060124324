import React, { createContext, useMemo, useContext, useCallback, useRef } from 'react'
import { Component } from 'models/common'

type CheckCallback = (key: string, result: boolean) => void
type AdditionalRemoveCheck = (name: string) => boolean
type RemoveCallback = (
    component: Component,
    suffix?: string,
    additional?: AdditionalRemoveCheck,
) => void
type AdditionalCallback = (key: string, result: boolean, message: string) => void

type SubmitCallback = () => { result: boolean, additional: string[], last?: string }

type WrapperMapper = { [key: string]: boolean | undefined }
type AdditionalWrapper = { [key: string]: { result: boolean, message: string } }
type ComponentWrapper = Component[]

export interface SubmitContextProps {
    check: CheckCallback
    remove: RemoveCallback
    registerAdditional: AdditionalCallback
    isSubmitValid: SubmitCallback
}

export const SubmitContext = createContext<SubmitContextProps>(
    {} as SubmitContextProps,
)

type SubmitContextProviderProps = React.PropsWithChildren

export const SubmitContextProvider: React.FC<SubmitContextProviderProps> = ({
    children,
}) => {
    const componentWrapper = useRef<ComponentWrapper>([
        Component.HOTEL_INFO,
        Component.CONSISTS,
        Component.RECOMMENDED_FOR,
        Component.LOCATION,
        Component.BEACH,
        Component.ACCOMMODATION_FACILITIES,
        Component.SWIMMING_POOLS,
        Component.WATER_SLIDES,
        Component.INTERNET,
        Component.ACCOMMODATION_TYPES,
        Component.SPORTS,
        Component.MEALS,
        Component.HONEYMOON,
        Component.BIRTHDAY,
    ])

    const wrapper = useRef<WrapperMapper>({})
    const additionalChecks = useRef<AdditionalWrapper>({})

    const check = useCallback<CheckCallback>((key, result) => {
        wrapper.current[key] = result
    }, [wrapper])

    const remove = useCallback<RemoveCallback>((component, suffix, check) => {
        const filtered = Object.keys(wrapper.current)
            .filter((key) => (
                key.includes(component) &&
                (!suffix || key.includes(suffix)) &&
                (!check || check(key))
            ))

        filtered.forEach((key) => {
            wrapper.current[key] = undefined
        })
    }, [wrapper])

    const registerAdditional = useCallback<AdditionalCallback>((key, result, message) => {
        additionalChecks.current[key] = {
            result,
            message,
        }
    }, [additionalChecks])

    const sortInvalidKeys = useCallback((keys: string[]) => {
        const internalKeys = [...keys]

        const parseKey = (key: any) => {
            const part = key.split('--')
            return {
                main: componentWrapper.current.indexOf(part[0]),
                wrapper: part.length === 1 ? 0 : 1,
            }
        }

        internalKeys.sort((first, second) => {
            const firstParsed = parseKey(first)
            const secondParsed = parseKey(second)

            if (firstParsed.main < secondParsed.main) return -1
            if (firstParsed.main > secondParsed.main) return 1

            if (firstParsed.wrapper === secondParsed.wrapper) {
                return 0
            }

            return firstParsed.wrapper < secondParsed.wrapper ? -1 : 1
        })
        return internalKeys
    }, [componentWrapper])

    const isSubmitValid = useCallback<SubmitCallback>(() => {
        const invalidFilter = (key: any) => (
            !wrapper.current[key] &&
            wrapper.current[key] !== undefined
        )

        const additional = Object.keys(additionalChecks.current)
            .filter((key) => !additionalChecks.current[key].result)
            .map((key) => additionalChecks.current[key].message)

        const keys = Object.keys(wrapper.current)
        const invalidKeys = keys.filter(invalidFilter)
        const validResult = invalidKeys.length === 0 && additional.length === 0

        const sorted = sortInvalidKeys(invalidKeys)

        return {
            result: validResult,
            last: sorted.length === 0 ? undefined : sorted[0],
            additional,
        }
    }, [wrapper, additionalChecks, sortInvalidKeys])

    const providerContext = useMemo(() => ({
        check,
        remove,
        registerAdditional,
        isSubmitValid,
    }), [
        check,
        remove,
        registerAdditional,
        isSubmitValid,
    ])

    return (
        <SubmitContext.Provider value={providerContext}>
            {children}
        </SubmitContext.Provider>
    )
}

export const useSubmitContext = () => {
    return useContext(SubmitContext)
}
