import { useMemo } from 'react'
import { FacilitiesComponent } from './facilities-component'

export const Disabled: React.FC = () => {
    const values = useMemo(() => [
        'complete',
        'beachAccess',
        'publicAreas',
        'restaurant',
        'bar',
        'swimmingPool',
        'elevators',
        'parking',
    ], [])

    return (
        <FacilitiesComponent title="disabledTitle" root="disabledFacilities" note="noteDisabledFacilities" values={values} otherOnly required hideRequiredNote />
    )
}
