import styled, { css } from 'styled-components'
import { State } from 'models/fact-sheet-dto'
import { StaffAction } from 'models/fact-sheet-api'
import { useTranslationContext } from 'context/translation-context'
import { Image, Images } from '../image'
import { Box } from '../container'

const BaseStyle = css<any>`
    color: #7F859D;
    font-weight: 600;
    font-size: 10px;
    padding: 2px 5px;

    ${({ $big }: any) =>
        $big &&
        `
        font-size: 12px;
    `};
`

const ActionWrapper = styled(Box)`
    gap: 2px;
`

const Action = styled.h4<any>`
    ${BaseStyle};
`

const Value = styled.h4<any>`
    ${BaseStyle};

    ${({ $state }: any) =>
        ($state === State.CREATED) &&
        `
        color: #797775;
        background: #EDEBE9;
    `};

    ${({ $state }: any) =>
        ($state === State.SENT_TO_HOTEL) &&
        `
        color: #018395;
        background: #D9FAFF;
    `};

    ${({ $state }: any) =>
        ($state === State.IN_PROGRESS) &&
        `
        color: #8222BA;
        background: #F6E7FF;
    `};

    ${({ $state }: any) =>
        ($state === State.SUBMITTED_BY_HOTEL) &&
        `
        color: #DDBC11;
        background: #FEF8DB;
    `};

    ${({ $state }: any) =>
        ($state === State.PUBLISHED) &&
        `
        color: #27BE6D;
        background: #E5F7ED;
    `};

    ${({ $state }: any) =>
        ($state === State.CANCELLED) &&
        `
        color: #FF0040;
        background: #FFEBF0;
    `};

    ${({ $state }: any) =>
        ($state === State.PRINTED_TO_SIGN) &&
        `
        color: #0D00A4;
        background: #EBF1FF;
    `};

    ${({ $state }: any) =>
        ($state === State.RECALLED) &&
        `
        color: #DF6E07;
        background: #FFF3EB;
    `};
`

export interface StateProps {
    state: State
    action?: StaffAction
    big?: boolean
}

export const StateViewer: React.FC<StateProps> = ({
    state,
    action,
    big,
}) => {
    const { translate } = useTranslationContext()

    if (action) {
        const mapper = {
            [StaffAction.FORWARD]: Images.ARROW_RIGHT,
            [StaffAction.RETURN]: Images.ARROW_LEFT,
            [StaffAction.OVERTAKE]: Images.ARROW_LEFT_RED,
        }

        return (
            <ActionWrapper $width="auto">
                <Image width={25} image={mapper[action]} />
                <Action $big={big}>{translate('action', { params: [action ?? 'unknown'] })}</Action>
            </ActionWrapper>
        )
    }

    return <Value $state={state} $big={big}>{translate('state', { params: [state ?? 'unknown'] })}</Value>
}
