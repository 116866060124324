import { useMemo } from 'react'
import styled from 'styled-components'
import { Image as AntdImage } from 'antd'

import logo from 'assets/images/logo.svg'
import time from 'assets/images/time.svg'
import copy from 'assets/images/copy.svg'
import copyDisabled from 'assets/images/copy-disabled.svg'
import arrowLeft from 'assets/images/arrow-left.svg'
import arrowLeftRed from 'assets/images/arrow-left-red.svg'
import arrowRight from 'assets/images/arrow-right.svg'

const InternalImage = styled(AntdImage)`
    ${({ onClick }: any) =>
        onClick &&
        `
        cursor: pointer;
    `};
`

export enum Images {
    LOGO,
    TIME,
    COPY,
    COPY_DISABLED,
    ARROW_RIGHT,
    ARROW_LEFT,
    ARROW_LEFT_RED,
}

interface ImageProps {
    width?: number
    image: Images
    onClick?: () => void
}

export const Image: React.FC<ImageProps> = ({
    width,
    image,
    onClick,
}) => {
    const createImageSource = useMemo(() => {
        switch (image) {
            case Images.LOGO: return logo
            case Images.TIME: return time
            case Images.COPY: return copy
            case Images.COPY_DISABLED: return copyDisabled
            case Images.ARROW_RIGHT: return arrowRight
            case Images.ARROW_LEFT: return arrowLeft
            case Images.ARROW_LEFT_RED: return arrowLeftRed
            default: return undefined
        }
    }, [image, logo, time])

    return <InternalImage preview={false} width={width} height="auto" src={createImageSource} onClick={onClick} />
}
