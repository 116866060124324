import { useCallback } from 'react'
import { Modal as InternalModal } from 'antd'
import { styled } from 'styled-components'
import { Buttons, ButtonSize } from 'hooks/menu-button-hook'
import { useTranslationContext } from 'context/translation-context'
import { MenuButton } from './menu-button'
import { Box } from '../container'

export type ModalSetter = (newValue: boolean) => void

export interface BaseModalProps {
    show: boolean
    setter: ModalSetter
}

export interface ModalProps extends BaseModalProps {
    width?: number
    children?: React.ReactNode
    title: string
    onHandleClose?: boolean
    onHandleClick?: () => void
    handleTitle?: string
    handleDisable?: boolean
    cancelTitle?: string
    onCancelClick?: () => void
}

const ContentWrapper = styled(Box)`
    width: 75%;
    margin: auto;
    text-align: center;
    padding: 25px 0px;
    gap: 20px;
    font-size: 14px;
    color: #3D4466;
`

const ButtonWrapper = styled(Box)`
    justify-content: center;
    column-gap: 16px;
`

const Title = styled.p`
    font-size: 20px;
    font-weight: 600;
    color: #141F33;
    width: 80%;
    margin: auto;
`

export const Modal: React.FC<ModalProps> = ({
    show,
    setter,
    children,
    title,
    width,
    onHandleClick,
    onHandleClose,
    handleTitle,
    handleDisable,
    cancelTitle,
    onCancelClick,
}) => {
    const { translate } = useTranslationContext()

    const onCancel = useCallback(async () => {
        if (onCancelClick) {
            await onCancelClick()
        }
        setter(false)
    }, [setter])

    const onHandle = useCallback(async () => {
        if (onHandleClick) {
            await onHandleClick()
        }

        if (onHandleClose) {
            await onCancel()
        }
    }, [onHandleClick, onHandleClose, onCancel])

    return (
        <InternalModal open={show} onCancel={onCancel} footer={null} width={width ?? 600}>
            <ContentWrapper $row>
                <Title>{translate(title)}</Title>
                <Box $row>
                    {children}
                </Box>
                <ButtonWrapper>
                    <MenuButton
                      title={cancelTitle ?? 'buttons.cancel'}
                      onClick={onCancel}
                      type={Buttons.HIGHLIGHT}
                      size={ButtonSize.BIG}
                    />
                    { !!handleTitle && (
                        <MenuButton
                          onError={onCancel}
                          disabled={handleDisable}
                          title={handleTitle}
                          onClick={onHandle}
                          type={Buttons.HIGHLIGHT_BLUE}
                          size={ButtonSize.BIG}
                        />
                    )}
                </ButtonWrapper>
            </ContentWrapper>
        </InternalModal>
    )
}
