import { useMemo } from 'react'
import { Card } from 'components/main/card'
import { Component } from 'models/common'
import { EntityWrapper, EntryMapper } from './wrapper/entity-wrapper'

export const Internet: React.FC = () => {
    const internetMapper = useMemo((): EntryMapper => ([
        ['lobby'],
        ['reception'],
        ['all'],
        ['poolArea'],
        ['beach'],
        ['rooms'],
    ]), [])

    return (
        <Card type={Component.INTERNET} required>
            <EntityWrapper rootName="internet" data={internetMapper} check offset required component={Component.INTERNET} />
        </Card>
    )
}
