export enum Cost {
    FREE = 'FREE',
    EXTRA = 'EXTRA',
}

export enum CostDeposit {
    FREE = 'FREE',
    EXTRA = 'EXTRA',
    DEPOSIT = 'DEPOSIT',
}

export enum Restaurants {
    A_LA_CARTE = 'A_LA_CARTE',
    BUFFET = 'BUFFET',
}

export enum Ownership {
    PUBLIC = 'PUBLIC',
    PRIVATE = 'PRIVATE',
}

export enum Jetty {
    JETTY_ONLY = 'JETTY_ONLY',
    ALSO_BEACH = 'ALSO_BEACH',
}

export enum Access {
    SLOW = 'SLOW',
    STEEP = 'STEEP',
    PIER = 'PIER',
}

export enum Water {
    SWEET = 'SWEET',
    SALT = 'SALT',
}

export enum Integration {
    INTEGRATED = 'INTEGRATED',
    SEPARATED = 'SEPARATED',
}

export enum Loc {
    INDOOR = 'INDOOR',
    OUTDOOR = 'OUTDOOR',
}

export enum LocAndInt {
    INDOOR_INTEGRATED = 'INDOOR_INTEGRATED',
    INDOOR_SEPARATED = 'INDOOR_SEPARATED',
    OUTDOOR_INTEGRATED = 'OUTDOOR_INTEGRATED',
    OUTDOOR_SEPARATED = 'OUTDOOR_SEPARATED',
}

export enum AccommodationType {
    HOTEL_ROOM = 'HOTEL_ROOM',
    APARTMENT = 'APARTMENT',
    STUDIO = 'STUDIO',
    BUNGALOW = 'BUNGALOW',
    VILLA = 'VILLA',
    MAISONETTE = 'MAISONETTE',
}

export enum Surface {
    FINE = 'FINE',
    SANDY = 'SANDY',
    COARSE = 'COARSE',
    MIXED = 'MIXED',
    PEBBLE = 'PEBBLE',
    ROCKY = 'ROCKY',
}

export enum Terrain {
    ON_THE_HILL = 'ON_THE_HILL',
    IN_THE_SLOPE = 'IN_THE_SLOPE',
    OTHER_SPECIFY = 'OTHER_SPECIFY',
}

export enum Conditioning {
    INDIVIDUAL = 'INDIVIDUAL',
    CENTRAL_INDIV_CONTROL = 'CENTRAL_INDIV_CONTROL',
    CENTRAL = 'CENTRAL',
}

export enum Bathroom {
    SHOWER_AND_WC = 'SHOWER_AND_WC',
    BATHTUB_AND_WC = 'BATHTUB_AND_WC',
    SHOWER_BATHTUB_AND_WC = 'SHOWER_BATHTUB_AND_WC',
}

export enum Kitchen {
    REGULAR = 'REGULAR',
    CORNER = 'CORNER',
}

export interface BaseModel {
    note?: string
}

export interface OtherModel<T> {
    otherItems?: T[]
}

export interface HotelRenovated extends OtherModel<string> {
    rooms?: boolean
    publicAreas?: boolean
}

export interface HotelConsistsOf extends OtherModel<string> {
    oneBuilding?: boolean
    moreBuildings?: boolean
    villas?: boolean
    bungalows?: boolean
}

export interface HotelInfo extends BaseModel {
    yearBuilt?: number
    totalRooms?: number
    yearRenovated?: number
    renovated: HotelRenovated
    consistsOf: HotelConsistsOf
}

export interface RecommendedFor extends BaseModel {
    adultsOnly?: boolean
    adultsOnlyAge?: number
    multiGeneration?: boolean
    seniors?: boolean
    families?: boolean
    youngPeople?: boolean
    partyHotel?: boolean
    quietHotel?: boolean
    liveAtmosphere?: boolean
    honeyMooners?: boolean
    allClients?: boolean
}

export interface Airport {
    name?: string
    distance?: string
}

export interface Location extends BaseModel {
    terrain?: Terrain
    terrainOther?: string
    nearestCenterName?: string
    nearestCenterDistance?: string
    nearestShops?: string
    nearestRestaurantsAndBars?: string
    busStationDescription?: string
    busDestination?: string
    beachIsDirect?: true
    beachDistance?: string
    airports?: Airport[]
}

export interface FactSheetHeader {
    contractNo?: string
    propertyName?: string
    country?: string
    destination?: string
    location?: string
    season?: string
    factSheetNo?: string
    officialCategory?: string
    creationDate?: string
    market?: string
    roomTypes?: string[]
}

export interface FactSheetData {
    hotelInfo: HotelInfo
    recommendedFor: RecommendedFor
    accommodationTypes: any[]
    swimmingPools: any
}
