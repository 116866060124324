import React, { createContext, useState, useMemo, useContext } from 'react'
import { Info, Operation } from 'models/fact-sheet-dto'
import { FactSheetData, FactSheetHeader } from 'models/fact-sheet-data'

export interface FactSheetContextProps {
    data?: FactSheetData
    setData: (data?: FactSheetData) => void
    header: FactSheetHeader
    setHeader: (header: FactSheetHeader) => void
    info: Info
    setInfo: (info: Info) => void
    isValidFactSheet: boolean
}

export const FactSheetContext = createContext<FactSheetContextProps>(
    {} as FactSheetContextProps,
)

type FactSheetContextProviderProps = React.PropsWithChildren

export const FactSheetContextProvider: React.FC<FactSheetContextProviderProps> = ({
    children,
}) => {
    const [data, setData] = useState<FactSheetData>()
    const [header, setHeader] = useState<FactSheetHeader>({})
    const [info, setInfo] = useState<Info>({
        isCurrentEditor: false,
        token: {
            operation: Operation.PROVIDED,
        },
    })

    const isValidFactSheet = useMemo(() => (
        header && Object.keys(header).length > 0
    ), [header])

    const providerContext = useMemo(() => ({
        data,
        setData,
        header,
        setHeader,
        info,
        setInfo,
        isValidFactSheet,
    }), [
        data,
        setData,
        header,
        setHeader,
        info,
        setInfo,
        isValidFactSheet,
    ])

    return (
        <FactSheetContext.Provider value={providerContext}>
            {children}
        </FactSheetContext.Provider>
    )
}

export const useFactSheetContext = () => {
    return useContext(FactSheetContext)
}
