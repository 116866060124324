import { useMemo, useRef, createContext, useEffect, useContext } from 'react'
import { useField, useFormikContext } from 'formik'
import styled from 'styled-components'
import { useRequiredContext } from 'context/required-context'
import { Box } from '../container'
import { BaseItemProps } from './form'

const OtherBox = styled(Box)`
    position: relative;
`

const getFormattedFieldName = (name: string): [boolean, string] => {
    const split = name.split('.')
    const checkName = split.length > 0 ? split[split.length - 1] : name

    const isOther = checkName.includes('other') && !checkName.endsWith('other') && checkName.length > 5
    const nameOther = isOther ? name.concat('--OTHER_CHECK') : name

    return [isOther, nameOther]
}

export const useOtherField = (name: string): [string, any, boolean, boolean] => {
    const [isOther, nameOther] = getFormattedFieldName(name)

    const [, meta] = useField(nameOther)
    const [, baseMeta] = useField(name)

    const check = isOther && baseMeta.value && baseMeta.value.length > 0

    return [nameOther, meta.value, check, isOther]
}

type ChildrenCallbackType = (className: string) => React.ReactNode
export type ChildrenContent = ChildrenCallbackType | React.ReactNode

export interface OtherProps {
    children: ChildrenContent
    value?: any | any[]
    isAuto?: boolean
    invert?: boolean
    enableReset?: boolean
}

export type OtherType = OtherProps & BaseItemProps

interface OtherContextProps {
    assign: (name?: string) => void
    shouldRender: boolean
}

export const OtherContext = createContext<OtherContextProps>(
    {} as OtherContextProps,
)

export const useOtherContext = () => {
    return useContext(OtherContext)
}

export const Other: React.FC<OtherType> = ({
    name,
    children,
    value,
    invert,
    isAuto,
    enableReset,
}) => {
    const parent = useOtherContext()

    const { unregister } = useRequiredContext()
    const { setFieldValue } = useFormikContext()
    const fields = useRef<string[]>([])
    const [, otherValue, otherCheck] = useOtherField(name ?? '')
    const parsed = value ?? true

    const renderChildren = useMemo(() => {
        if (typeof children === 'function') {
            return children(name ?? '')
        }
        return children
    }, [children])

    const otherProps = useMemo(() => ({
        $width: isAuto ? 'auto' : '100%',
    }), [isAuto])

    const shouldRender = useMemo(() => {
        const checkValue = () => {
            if (Array.isArray(parsed)) {
                return parsed.includes(otherValue) !== (invert ?? false)
            }
            return (otherValue === parsed) !== (invert ?? false)
        }
        return checkValue() || otherCheck
    }, [parsed, otherValue, otherCheck])

    const providerContext = useMemo(() => ({
        assign: (field?: string) => {
            if (!field) {
                return
            }

            if (!fields.current.includes(field)) {
                fields.current.push(field)
            }

            if (parent.assign !== undefined) {
                parent.assign(field)
            }
        },
        shouldRender,
    }), [fields, shouldRender, parent.assign])

    useEffect(() => {
        const shouldResetField = !shouldRender

        if (shouldResetField) {
            fields.current.forEach((fieldName) => {
                unregister(fieldName)
                const isArrayItem = fieldName.includes('[') && fieldName.includes(']')
                if (!isArrayItem || enableReset) {
                    setFieldValue(fieldName, null)
                }
            })
        }
    }, [shouldRender, fields.current, setFieldValue, enableReset])

    return shouldRender && (
        <OtherContext.Provider value={providerContext}>
            <OtherBox {...otherProps}>
                {renderChildren}
            </OtherBox>
        </OtherContext.Provider>
    )
}
