import styled from 'styled-components'
import { useTranslationContext } from 'context/translation-context'
import { useReadonly } from 'hooks/readonly-hook'
import { Item, ItemBoxProps } from '../item'
import { Box, gap } from '../../container'

interface LabelProps extends ItemBoxProps {
    title: string
    offset?: boolean
    start?: boolean
}

const TitleWrapper = styled(Box)`
    gap: 5px;
`

const RequiredSymbol = styled.p`
    color: #FF0040;
`

const LabelItem = styled(Item)<any>`
    color: #3D4466;
    font-weight: 600;

    ${({ $offset }) =>
        $offset &&
        `
        margin-top: ${gap}px;
    `};

    ${({ $start }) =>
        !$start &&
        `
        align-items: center;
    `};

    ${({ $readonly }) =>
        $readonly &&
        `
        color: #70758CBC;
    `};
`

export const Label: React.FC<LabelProps> = ({
    title,
    required,
    offset,
    start,
}) => {
    const { translate } = useTranslationContext()
    const [isReadonly] = useReadonly()

    return (
        <LabelItem $start={start} $readonly={isReadonly} $offset={offset}>
            <TitleWrapper>
                {translate(title)}
                {required && title && title.length > 0 && <RequiredSymbol>*</RequiredSymbol>}
            </TitleWrapper>
        </LabelItem>
    )
}
