import { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { useLoadContext } from 'context/load-context'
import { useTranslationContext } from 'context/translation-context'
import { useAxiosContext } from 'context/axios-context'
import { useMessageContext } from 'context/message-context'
import { useMenuButtons, Buttons, ButtonSize, MenuButtonProps, EnabledProps } from 'hooks/menu-button-hook'
import { Role } from 'models/fact-sheet-dto'
import { InternalButton, ButtonType } from '../button'

const InternalMenuButton = styled(InternalButton)`
    :where(&).ant-btn.ant-btn-sm,
    :where(&).ant-btn.ant-btn-lg {
        font-size: 12px;
    }

    :where(&).ant-btn.ant-btn-sm {
        height: 28px;
    }

    :where(&).ant-btn.ant-btn-lg {
        height: 35px;
        border-radius: 4px;
    }
`

export interface ButtonProps extends MenuButtonProps {
    onClick?: () => void
    type: Buttons
    disabled?: boolean
    hide?: boolean
    onError?: () => void
}

export const MenuButton: React.FC<ButtonProps> = ({
    onClick,
    type,
    disabled,
    hide,
    onError,
    ...props
}) => {
    const { initialConfiguration } = useAxiosContext()
    const { showError } = useMessageContext()
    const { translate } = useTranslationContext()
    const [mapper] = useMenuButtons()
    const {
        info: {
            role,
            state,
            isCurrentEditor,
        },
    } = useFactSheetContext()
    const { begin, end } = useLoadContext()

    const {
        title = '',
        show = [],
        enabled = {} as EnabledProps,
        always = false,
        editor = {},
        style = ButtonType.DEFAULT,
        size = ButtonSize.DEFAULT,
    } = {
        ...mapper[type],
        ...props,
    }

    const internalOnClick = useCallback(async () => {
        try {
            begin()
            if (onClick) {
                await onClick()
            }
        } catch (error: any) {
            if (onError) {
                onError()
            }
            showError('WEIRD', 'failed')
            await initialConfiguration()
        } finally {
            end()
        }
    }, [onClick, begin, end, title, onError, initialConfiguration])

    const createButtonProps = useCallback((role: Role) => {
        const enabledStateArray = enabled[role] ?? []

        const check = state && enabledStateArray.includes(state)
        const validEditor = editor.enabled === undefined || editor.enabled === isCurrentEditor

        return {
            disabled: (!check && !always) || disabled || !validEditor,
            onClick: internalOnClick,
            $type: style,
            size: (size === ButtonSize.DEFAULT ? 'small' : 'large') as any,
        }
    }, [state, onClick, style, disabled, isCurrentEditor])

    const renderButton = useMemo(() => {
        const validEditor = editor.show === undefined || editor.show === isCurrentEditor
        return role && (show.includes(role) || always) && !hide && validEditor
    }, [role, show, always, editor, isCurrentEditor])

    return renderButton && (
        <InternalMenuButton {...createButtonProps(role ?? Role.AGENCY)}>
            {translate(title)}
        </InternalMenuButton>
    )
}
