import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { Note } from 'components/presets/note'
import { CheckBox } from 'components/form/items/check-box'
import { OtherInput } from 'components/presets/other-input'
import { Component } from 'models/common'

export const Consists: React.FC = () => {
    const required = {
        required: 'consists',
    }

    return (
        <Card type={Component.CONSISTS} required>
            <Box>
                <CheckBox title="building" name="hotelInfo.consistsOf.oneBuilding" required={required} />
                <CheckBox title="more" name="hotelInfo.consistsOf.moreBuildings" required={required} />
                <CheckBox title="villas" name="hotelInfo.consistsOf.villas" required={required} />
                <CheckBox title="bungalows" name="hotelInfo.consistsOf.bungalows" required={required} />
            </Box>
            <Box $row>
                <CheckBox title="other" name="hotelInfo.consistsOf.otherItems" required={required} />
                <OtherInput name="hotelInfo.consistsOf.otherItems" required />
            </Box>
            <Note name="hotelInfo.noteConsistsOf" />
        </Card>
    )
}
