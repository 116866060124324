import { styled } from 'styled-components'
import { useTranslationContext } from 'context/translation-context'
import { Box } from '../../container'

const Wrapper = styled(Box)`
    margin: 2px;
`

const TitleWrapper = styled(Box)`
    gap: 5px;
`

const Title = styled.p`
    color: #3D4466;
    font-weight: 600;
    font-size: 13px;
`

const Required = styled.p`
    color: #FF0040;
    font-size: 13px;
`

export interface InputWrapperProps {
    title: string
    children: React.ReactNode
}

export const InputWrapper: React.FC<InputWrapperProps> = ({
    title,
    children,
}) => {
    const { translate } = useTranslationContext()

    return (
        <Wrapper $row>
            <TitleWrapper>
                <Title>{translate(title)}</Title>
                <Required>*</Required>
            </TitleWrapper>
            {children}
        </Wrapper>
    )
}
