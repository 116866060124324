import { useEffect } from 'react'
import styled from 'styled-components'
import { useRequiredContext, Required } from 'context/required-context'
import { useOtherContext } from './other'
import { Box, width, columnGap, count } from '../container'

const itemWidth = (width - (columnGap * (count - 1))) / count

export interface ItemBoxProps {
    fixedHeight?: boolean
    fixedWidth?: boolean
    autoWidth?: boolean
    flex?: boolean
    required?: Required
}

export const InternalItem = styled(Box)<any>`
    ${({ $fixedHeight }) =>
        ($fixedHeight === undefined || $fixedHeight) &&
        `
        min-height: 32px;
    `};

    ${({ $fixedWidth }) =>
        ($fixedWidth === undefined || $fixedWidth) &&
        `
        width: ${itemWidth}px;
    `};

    ${({ $autoWidth }) =>
        $autoWidth &&
        `
        width: auto;
    `};

    ${({ $flex }) =>
        $flex &&
        `
        flex: 1;
    `};
`

interface ItemProps extends ItemBoxProps, React.PropsWithChildren {
    length?: number
    className?: string
    name?: string
}

export const Item: React.FC<ItemProps> = ({
    length,
    children,
    className,
    name,
    required,
    ...itemBoxProps
}) => {
    const { register } = useRequiredContext()
    const { assign } = useOtherContext()

    useEffect(() => {
        if (assign) {
            assign(name)
        }

        if (!register) {
            return
        }
        register(name, required)
    }, [])

    return (
        <InternalItem
          className={className}
          $fixedHeight={itemBoxProps.fixedHeight}
          $fixedWidth={itemBoxProps.fixedWidth}
          $autoWidth={itemBoxProps.autoWidth}
          $flex={itemBoxProps.flex}
        >
            {children}
        </InternalItem>
    )
}
