import { useCallback, useMemo, useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { AutoComplete } from 'antd'
import { useAxiosContext } from 'context/axios-context'
import { Modal, BaseModalProps } from 'components/main/modal'
import { useTranslationContext } from 'context/translation-context'
import { BaseInputTextArea, BaseInputText } from 'components/input'
import { InputWrapper } from './common/input-wrapper'

const BaseInput = styled(BaseInputText)`
    color: #3D4466;
    background: #F8F8F8;
    font-size: 12px;
    padding: 8px 10px;
    width: 100%;
    text-align: left;
`

const BaseArea = styled(BaseInputTextArea)`
    padding: 8px 10px;
    width: 100%;
    text-align: left;
`

export type StaffLinkCallback = (email: string, comment?: string) => Promise<void>

export interface StaffModalProps extends BaseModalProps {
    staffLink?: StaffLinkCallback
}

export const StaffModal: React.FC<StaffModalProps> = ({
    staffLink,
    ...baseModalProps
}) => {
    const { emails } = useAxiosContext()

    const [comment, setComment] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [options, setOptions] = useState<{ value: string }[]>([])

    const { translate } = useTranslationContext()

    const initialEmailLoad = useCallback(async () => {
        const availableEmails = await emails()
        const optionParser = (value: string) => ({
            value,
        })
        setOptions([...availableEmails.map(optionParser)])
    }, [emails])

    const filter = useCallback((current: string, option: any) => {
        return option.value.toLowerCase().includes(current.toLowerCase())
    }, [])

    const reset = useCallback(() => {
        setComment('')
        setEmail('')
    }, [])

    const submit = useCallback(async () => {
        if (staffLink) {
            await staffLink(email, comment)
        }
        reset()
    }, [staffLink, reset, email, comment])

    const submitEnabled = useMemo((): boolean => {
        return !!email && !!comment
    }, [comment, email])

    const modalProps = useMemo(() => ({
        ...baseModalProps,
        onHandleClick: submit,
        onHandleClose: true,
        onCancelClick: reset,
        handleDisable: !submitEnabled,
        handleTitle: 'staff.send',
        cancelTitle: 'buttons.close',
    }), [baseModalProps, submit])

    useEffect(() => {
        initialEmailLoad()
    }, [baseModalProps.show, initialEmailLoad])

    return (
        <Modal title="staff.title" {...modalProps}>
            <InputWrapper title="staff.member">
                <AutoComplete
                  value={email}
                  options={options}
                  style={{ width: '100%' }}
                  filterOption={filter}
                  onChange={setEmail}>
                    <BaseInput placeholder={translate('staff.email')} />
                </AutoComplete>
            </InputWrapper>
            <InputWrapper title="note">
                <BaseArea
                  placeholder={translate('staff.note')}
                  maxLength={500}
                  rows={5}
                  value={comment}
                  onChange={(val) => setComment(val.currentTarget.value)}
                />
            </InputWrapper>
        </Modal>
    )
}
