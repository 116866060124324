import useDigitInput from 'react-digit-input'
import { styled } from 'styled-components'
import { BaseInputText } from 'components/input'
import { Box } from '../../container'

const InputWrapper = styled(Box)`
    gap: 1px;
    width: auto;
    align-self: center;
`

const Value = styled(BaseInputText)`
    width: 35px;
    font-weight: bold;
    font-size: 20px;
    text-align: center;

    &:is(.ant-input) {
        height: 40px;
    }
`

export interface DigitsProps {
    value: string
    onChange: (value: string) => void
}

export const Digits: React.FC<DigitsProps> = ({
    value,
    onChange,
}) => {
    const digits = useDigitInput({
        acceptedCharacters: /^\d$/,
        length: 6,
        value,
        onChange,
    }) as any

    return (
        <InputWrapper>
            <Value type="decimal" autoFocus {...digits[0]} />
            <Value type="decimal" {...digits[1]} />
            <Value type="decimal" {...digits[2]} />
            <Value type="decimal" {...digits[3]} />
            <Value type="decimal" {...digits[4]} />
            <Value type="decimal" {...digits[5]} />
        </InputWrapper>
    )
}
