import styled from 'styled-components'
import { Box } from '../container'
import { Other, OtherType } from '../form/other'
import { CheckBox } from '../form/items/check-box'

interface OtherInputProps extends OtherType {
    title?: string
    isDisabled?: boolean
    required?: any
    info?: string
}

const OtherContainer = styled(Box)`
    flex: 1;
`

export const OtherContent: React.FC<OtherInputProps> = ({
    title,
    name,
    isDisabled,
    children,
    required,
    value,
    info,
}) => {
    return (
        <Box>
            <CheckBox
              title={title}
              name={name}
              isDisabled={isDisabled}
              required={required}
              info={info}
            />
            <OtherContainer>
                <Other name={name} value={value}>
                    {children}
                </Other>
            </OtherContainer>
        </Box>
    )
}
