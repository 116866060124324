import { useMemo } from 'react'
import { FacilitiesComponent } from './facilities-component'

export const Adults: React.FC = () => {
    const values = useMemo(() => [
        'bar',
        'restaurant',
        'swimmingPool',
        'partOfBeach',
        'partOfHotel',
    ], [])

    return (
        <FacilitiesComponent required title="adultTitle" root="adultsOnly" note="noteAdultsOnly" values={values} check="accommodationFacilities.adultsOnly" />
    )
}
