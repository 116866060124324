import { useCallback } from 'react'

export const useDownloadHook = () => {
    const getFormattedDate = () => {
        const currentDate = new Date()
        return currentDate.toISOString().split('T')[0]
    }

    const download = useCallback((blob: Blob, name: string) => {
        const blobURL = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', `${name}-${getFormattedDate()}.pdf`)
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL)
        }, 100)
    }, [])

    return [download]
}
