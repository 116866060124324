import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { useReadonly } from 'hooks/readonly-hook'
import { State } from 'models/fact-sheet-dto'
import { Namespace } from 'models/common'
import { HistoryModal } from 'components/modals/history-modal'
import { TranslationContextProvider } from 'context/translation-context'
import { Box, width, headerBackground, headerGap } from '../container'
import { Image, Images } from '../image'
import { Menu } from './menu'
import { StateViewer } from './state-viewer'
import { DisabledMenu } from './disabled-menu'
import { InfoWrapper } from './info-wrapper'

const HeaderBox = styled(Box)`
    background: ${headerBackground};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${headerGap}px;
    position: sticky;
    top: 0;
    z-index: 1000;
    gap: ${headerGap}px;
`

const StateWrapper = styled(Box)`
    width: 90%;
    justify-content: space-between;
`

const Main = styled(Box)`
    gap: ${headerGap}px;
`

const HeaderTitleWrapper = styled(Box)`
    margin-top: calc(${headerGap}px * 2);
    margin-bottom: ${headerGap}px;

    > *:first-child  {
        flex: 1;
        gap: 5px;

        > * {
             font-family: Duepuntozero;
             font-weight: 600;
        }
    };

    $ image {
        float: right;
    };
`

const Title = styled.h1`
    color: #0D2D54;
    font-size: 45px;
`

const SubTitle = styled.h3`
    color: #153C89;
`

interface HeaderProps extends React.PropsWithChildren {
}

export const Header: React.FC<HeaderProps> = () => {
    const [showHistoryModal, setShowHistoryModal] = useState(false)

    const {
        header: {
            contractNo,
            propertyName,
            country,
            destination,
            location,
            season,
            factSheetNo,
            officialCategory,
            creationDate,
            market,
        },
        info: {
            state,
        },
    } = useFactSheetContext()
    const [readonly] = useReadonly()

    const showHistory = useCallback(() => {
        setShowHistoryModal(true)
    }, [setShowHistoryModal])

    return (
        <TranslationContextProvider ns={Namespace.HEADER}>
            <HeaderBox id="header-wrapper">
                <Main $width={`${width}px`} $row>
                    <HeaderTitleWrapper>
                        <Box $row>
                            <Title>Fact Sheet</Title>
                            <SubTitle>
                                Annex to the accommodation contract: {contractNo}
                            </SubTitle>
                        </Box>
                        <Image width={225} image={Images.LOGO} />
                    </HeaderTitleWrapper>
                    <Box $noGap>
                        <InfoWrapper min={30} value={propertyName} title="main.property" />
                        <InfoWrapper min={30} value={`${country} / ${destination} / ${location}`} title="main.country" />
                        <InfoWrapper min={20} value={officialCategory} title="main.category" />
                        <InfoWrapper min={20} value={season} title="main.season" />
                    </Box>
                    <Box $noGap>
                        <InfoWrapper min={30} value={factSheetNo} title="main.number" />
                        <InfoWrapper min={30} value={creationDate} title="main.date" />
                        <InfoWrapper min={20} title="main.state">
                            <StateWrapper>
                                <StateViewer state={state ?? State.CREATED} big />
                                <Image width={15} image={Images.TIME} onClick={showHistory} />
                            </StateWrapper>
                        </InfoWrapper>
                        <InfoWrapper min={20} value={market} title="main.market" />
                    </Box>
                </Main>
                <Menu />
                { readonly && (
                    <DisabledMenu />
                )}
                <HistoryModal
                  show={showHistoryModal}
                  setter={setShowHistoryModal}
                />
            </HeaderBox>
        </TranslationContextProvider>
    )
}
