import React, { createContext, useMemo, useState, useEffect, useContext, useCallback } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { Guid, State, Operation, Info, TokenRejectionReason, OtpRequirement, Role } from 'models/fact-sheet-dto'
import { UpdateResponse, UpdateRequestBody, CheckResponse, TokenRequestBody, StaffAction } from 'models/fact-sheet-api'
import { useMessageContext } from 'context/message-context'
import { FactSheetData } from 'models/fact-sheet-data'
import { useDownloadHook } from 'hooks/download-hook'
import { useAxios } from 'hooks/axios-hook'
import { useLoadContext } from 'context/load-context'
import { FactSheetContext } from './fact-sheet-context'

type UpdateCallback = (data: any) => Promise<UpdateResponse>
type HandleUpdateCallback = (
    callback: UpdateCallback,
    data: any,
    updateToken?: boolean,
) => Promise<UpdateResponse>

interface StateResponse {
    enableCodeModal: boolean
    otp: OtpRequirement
    isError?: boolean
}

interface SetStateProps {
    code?: number
    note?: string
    fullName?: string
    jobTitle?: string
}

type InitCallback = () => Promise<void>
type SaveCallback = (data: FactSheetData) => void
type BaseCallback = () => Promise<any>
type StaffCallback = (action: StaffAction, email?: string, comment?: string) => Promise<void>
type CheckCallback = () => Promise<CheckResponse>
type OtpCallback = () => Promise<void>
type SetStateCallback = (newState: State, props?: SetStateProps) => Promise<StateResponse>

export interface AxiosContextProps {
    save: SaveCallback
    preview: BaseCallback
    historyExport: BaseCallback
    overtake: BaseCallback
    staff: StaffCallback
    finish: BaseCallback
    setState: SetStateCallback
    check: CheckCallback
    history: BaseCallback
    emails: BaseCallback
    sendOtpRequirement: OtpCallback
    waitForUpdate: boolean
    setWaitForUpdate: (value: boolean) => void
    initialConfiguration: () => void
}

export const AxiosContext = createContext<AxiosContextProps>(
    {} as AxiosContextProps,
)

type AxiosContextProviderProps = React.PropsWithChildren

export const AxiosContextProvider: React.FC<AxiosContextProviderProps> = ({
    children,
}) => {
    const navigate = useNavigate()
    const { info, setData, setHeader, setInfo } = useContext(FactSheetContext)
    const { showDeletable } = useMessageContext()
    const [queryParameters] = useSearchParams()
    const [download] = useDownloadHook()
    const [id, setId] = useState<Guid>(queryParameters.get('id') ?? '')
    const [saving, setSaving] = useState<boolean>(false)
    const [waitForUpdate, setWaitForUpdate] = useState<boolean>(false)
    const { begin, end } = useLoadContext()

    const {
        callHeader,
        callToken,
        callUpdate,
        callStaff,
        callPreview,
        callSetState,
        callCheck,
        callHistory,
        callEmails,
        callOtpRequirement,
        callExport,
    } = useAxios(id)

    const createInfo = ({ state, role, tokenResponse, edit: { isCurrentEditor } }: any): Info => ({
        state,
        role,
        isCurrentEditor,
        token: tokenResponse,
    })

    const getInitData = useCallback(async () => {
        begin()
        const response = await callHeader()
        if (response.isError) {
            end()
            return {}
        }

        setHeader(response.header)
        const info = createInfo(response)

        let tokenData = await callToken({})
        if (tokenData.tokenResponse.rejectionReason === TokenRejectionReason.INVALID_UPDATE) {
            tokenData = await callToken({})
        }

        const { data, state, id, tokenResponse } = tokenData

        setId(id)
        setData({
            ...data,
            swimmingPools: {
                ...data.swimmingPools,
                totalCount: 1,
            },
        })

        const currentInfo = {
            ...info,
            state,
            token: tokenResponse,
        }

        setInfo(currentInfo)
        end()
        return {
            role: currentInfo.role,
            state: currentInfo.state,
        }
    }, [])

    const initialConfiguration = useCallback<InitCallback>(async () => {
        getInitData().then(({ state, role }) => {
            if (state === State.RECALLED && role !== Role.AGENCY) {
                showDeletable('recalled', 'warning')
            }
        })
    }, [getInitData, showDeletable])

    const handleUpdateResponse = useCallback<HandleUpdateCallback>(async (
        callback,
        data,
        updateToken = true,
    ) => {
        setSaving(true)
        const response = await callback(data)
        if (response.isError) {
            setSaving(false)
            return response
        }

        const { id, state, tokenResponse, edit: { isCurrentEditor } } = response

        const token = updateToken ? tokenResponse : info.token

        setId(id)
        setInfo({ ...info, state, isCurrentEditor, token })
        setSaving(false)

        return {
            ...response,
            tokenResponse,
        }
    }, [info, id, saving])

    const save = useCallback<SaveCallback>(async (data) => {
        if (!info.token || info.token.operation === Operation.REJECTED || saving) {
            return
        }

        const requestBody: UpdateRequestBody = {
            id,
            data,
            tokenRequest: {
                release: false,
                tokenUuid: info.token?.tokenUuid,
            },
        }

        const {
            isError, response, tokenResponse,
        } = await handleUpdateResponse(callUpdate, requestBody)
        const invalidReason = tokenResponse?.rejectionReason === TokenRejectionReason.INVALID_UPDATE
        const invalidState = response?.data === 'Fact sheet cannot be modified by user in current state.'

        if (isError || invalidReason || invalidState) {
            initialConfiguration()
        }
    }, [info, id, saving, handleUpdateResponse, initialConfiguration])

    const preview = useCallback<BaseCallback>(async () => {
        const blob = await callPreview()
        download(blob, 'preview')
    }, [id])

    const historyExport = useCallback<BaseCallback>(async () => {
        const blob = await callExport()
        download(blob, 'history')
    }, [id])

    const updateToken = useCallback(async (body?: TokenRequestBody) => {
        const response = await callToken({
            tokenUuid: info.token?.tokenUuid,
            ...body,
        })

        if (response.isError) {
            return
        }

        const { tokenResponse, state, edit: { isCurrentEditor } } = response

        if (tokenResponse?.rejectionReason === TokenRejectionReason.INVALID_UPDATE) {
            initialConfiguration()
            return
        }

        setInfo({
            ...info,
            ...{
                state,
                isCurrentEditor,
                token: tokenResponse,
            },
        })
    }, [info.token?.tokenUuid, info, setInfo])

    const overtake = useCallback<BaseCallback>(async () => {
        await updateToken({
            overtake: true,
        })
    }, [updateToken])

    const finish = useCallback<BaseCallback>(async () => {
        await updateToken({
            release: true,
        })
    }, [updateToken])

    const staff = useCallback<StaffCallback>(async (action, email, comment) => {
        const response = await callStaff({ action, email, comment })

        if (response.isError) {
            return
        }
        const { edit: { isCurrentEditor } } = response
        setInfo({
            ...info,
            isCurrentEditor,
        })
    }, [info])

    const history = useCallback<BaseCallback>(async () => {
        return callHistory()
    }, [])

    const emails = useCallback<BaseCallback>(async () => {
        return callEmails()
    }, [])

    const setState = useCallback<SetStateCallback>(async (newState, props) => {
        const {
            otpRequirement, state, isError, response,
        } = await handleUpdateResponse(callSetState, {
            tokenRequest: {
                tokenUuid: info.token?.tokenUuid,
            },
            newState,
            otp: props?.code,
            note: props?.note,
            fullName: props?.fullName,
            jobTitle: props?.jobTitle,
        }, false)

        if (isError) {
            const errorObject = { ...response }
            throw errorObject
        }

        return {
            enableCodeModal: otpRequirement !== null && state !== newState,
            otp: otpRequirement ?? {},
            isError,
        }
    }, [info, id, handleUpdateResponse])

    const check = useCallback<CheckCallback>(async () => callCheck(), [callCheck])
    const sendOtpRequirement = useCallback<OtpCallback>(async () => callOtpRequirement(), [])

    useEffect(() => {
        if (!id || id.length === 0) {
            navigate('/invalid')
            return
        }
        initialConfiguration()
    }, [])

    useEffect(() => {
        if (info.token) {
            window.onbeforeunload = (event) => {
                event.preventDefault()
                finish()
            }
        }

        return () => {
            window.onbeforeunload = null
        }
    }, [info.token, finish])

    const providerContext = useMemo(() => ({
        save,
        preview,
        overtake,
        staff,
        finish,
        setState,
        check,
        historyExport,
        history,
        emails,
        sendOtpRequirement,
        waitForUpdate,
        setWaitForUpdate,
        initialConfiguration,
    }), [
        save,
        preview,
        overtake,
        staff,
        finish,
        setState,
        check,
        historyExport,
        history,
        emails,
        sendOtpRequirement,
        waitForUpdate,
        setWaitForUpdate,
        initialConfiguration,
    ])

    return (
        <AxiosContext.Provider value={providerContext}>
            {children}
        </AxiosContext.Provider>
    )
}

export const useAxiosContext = () => {
    return useContext(AxiosContext)
}
