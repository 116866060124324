import React, { createContext, useMemo, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Namespace } from 'models/common'

type Params = any[]

interface TranslationParams {
    params?: Params,
    interpolations?: any,
    defaultValue?: any,
}

export type TranslationParseCallback = (
    key?: string,
    params?: Params,
) => string

export type TranslationCallback = (
    key?: string,
    data?: TranslationParams,
) => string

export type TranslationCreator = (
    ns: Namespace,
    key: string,
    defaultValue: string,
    interpolations?: any,
) => string

export interface TranslationContextProps {
    translate: TranslationCallback
    type?: string
}

export const TranslationContext = createContext<TranslationContextProps>(
    {} as TranslationContextProps,
)

export interface TranslationContextProviderProps extends React.PropsWithChildren {
    ns: Namespace
    addon?: string
}

export const TranslationContextProvider: React.FC<TranslationContextProviderProps> = ({
    ns,
    addon,
    children,
}) => {
    const { t } = useTranslation([ns, Namespace.COMMON])

    const baseTranslation = useCallback<TranslationCreator>((
        ns,
        key,
        defaultValue,
        interpolations,
    ) => {
        return t(key, { defaultValue, ns, ...interpolations }) as string
    }, [t])

    const parseTranslationKey = useCallback<TranslationParseCallback>((key, params) => {
        return (params ? `${key}.${params.join('.')}` : key) ?? 'unknown key'
    }, [])

    const translate = useCallback<TranslationCallback>((key, data) => {
        const parsedData = (data ?? {}) as TranslationParams
        const { params, interpolations, defaultValue } = parsedData

        if (!key || key.length === 0) {
            return ''
        }
        const formattedKey = parseTranslationKey(key, params)
        const parsedKey = addon ? `${addon}.${formattedKey}` : formattedKey

        const defaultTranslation: string = defaultValue ?
            translate(defaultValue, { ...parsedData, defaultValue: undefined }) :
            baseTranslation(Namespace.COMMON, formattedKey, parsedKey, interpolations)

        return baseTranslation(
            ns,
            parsedKey,
            defaultTranslation,
            interpolations,
        )
    }, [parseTranslationKey, baseTranslation, ns, addon])

    const providerContext = useMemo(() => ({
        translate,
        type: addon,
    }), [
        translate,
        addon,
    ])

    return (
        <TranslationContext.Provider value={providerContext}>
            {children}
        </TranslationContext.Provider>
    )
}

export const useTranslationContext = () => {
    return useContext(TranslationContext)
}
