import { Box } from 'components/container'
import { Wrapper } from 'components/form/wrapper'
import { CheckBox } from 'components/form/items/check-box'
import { Note } from 'components/presets/note'
import { OtherInput } from 'components/presets/other-input'
import { Component } from 'models/common'

export interface ExtraFacilitiesProps {
    root: string
}

export const ExtraFacilities: React.FC<ExtraFacilitiesProps> = ({
    root,
}) => {
    const rootName = `${root}.extraFacilities`

    return (
        <Wrapper title="facTitle" id="facTitle" component={Component.ACCOMMODATION_TYPES} required hideRequiredNote>
            <Box $row>
                <Box $wrap>
                    <CheckBox title="sharing" name={`${rootName}.sharingPool`} />
                    <CheckBox title="private" name={`${rootName}.privatePool`} />
                    <CheckBox title="jacuzzi" name={`${rootName}.jacuzzi`} />
                </Box>
                <CheckBox title="other" name={`${rootName}.otherItems`} />
                <OtherInput name={`${rootName}.otherItems`} required />
            </Box>
            <Note name={`${rootName}.note`} />
        </Wrapper>
    )
}
