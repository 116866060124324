import { useCallback, useEffect } from 'react'
import { useField } from 'formik'
import { Checkbox as Check } from 'formik-antd'
import styled from 'styled-components'
import { Box } from 'components/container'
import { useFormContext } from 'context/form-context'
import { useTranslationContext } from 'context/translation-context'
import { useRequiredContext } from 'context/required-context'
import { useReadonly } from 'hooks/readonly-hook'

const Wrapper = styled(Box)`
    background: #F1F7FA;
    padding: 5px 15px;
    gap: 8px;
    align-items: center;
    border-radius: 4px;
`

const Title = styled.p`
    color: #00B6FF;
    font-size: 13px;
`

const CheckItem = styled(Check)<any>`
    --color: ${({ $required }: any) => ($required ? '#FF0040' : 'white')};
    --border: ${({ $required }: any) => ($required ? '1px solid var(--color)' : '0')};

    :where(.ant-checkbox):not(.ant-checkbox-checked) .ant-checkbox-inner {
        background: #F1F7FA;
        border: 1px solid #00B6FF;
    }

    :where(&).ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
        border-color: #00B6FF;
    }

    :where(.ant-checkbox):not(.ant-checkbox-checked):hover .ant-checkbox-inner {
        background: #D7D7D7;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked) .ant-checkbox-inner {
        background: #00B6FF;
        border: 1px solid #00B6FF;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):hover .ant-checkbox-inner {
        background: #00A4E6;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):is(.ant-checkbox-disabled) .ant-checkbox-inner {
        background: #70758C80;
    }

    :where(.ant-checkbox):is(.ant-checkbox-checked):is(.ant-checkbox-disabled) .ant-checkbox-inner:after {
        border-color: #00B6FF;
    }
`

interface RequiredCheckProps {
    name: string
    title: string
}

export const RequiredCheck: React.FC<RequiredCheckProps> = ({
    name,
    title,
}) => {
    const { setAvailable } = useRequiredContext()
    const { translate } = useTranslationContext()
    const { submit } = useFormContext()
    const [isReadonly] = useReadonly(true)
    const [, meta] = useField(name)

    const onChange = useCallback((event: any) => {
        const isAvailable = !event.target.checked
        setAvailable(isAvailable)
        submit()
    }, [submit, setAvailable])

    useEffect(() => {
        setAvailable(!meta.value)
    }, [])

    return (
        <Wrapper $width="auto">
            <CheckItem
              id={name}
              name={name}
              disabled={isReadonly}
              onChange={onChange} />
            <Title>{translate(title)}</Title>
        </Wrapper>
    )
}
