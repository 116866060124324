export enum Language {
    EN = 'en',
}

export enum Namespace {
    COMMON = 'common',
    HEADER = 'header',
    COMPONENT = 'component',
}

export enum Component {
    LOCATION = 'LOCATION',
    ACCOMMODATION_TYPES = 'ACCOMMODATION_TYPES',
    RECOMMENDED_FOR = 'RECOMMENDED_FOR',
    BEACH = 'BEACH',
    BIRTHDAY = 'BIRTHDAY',
    SPORTS = 'SPORTS',
    CONSISTS = 'CONSISTS',
    ACCOMMODATION_FACILITIES = 'ACCOMMODATION_FACILITIES',
    HONEYMOON = 'HONEYMOON',
    HOTEL_INFO = 'HOTEL_INFO',
    INTERNET = 'INTERNET',
    MEALS = 'MEALS',
    SWIMMING_POOLS = 'SWIMMING_POOLS',
    WATER_SLIDES = 'WATER_SLIDES',
}

export enum RequiredType {
    ONCE = 'ONCE',
    PLUS = 'PLUS',
    ONCE_PLUS = 'ONCE_PLUS',
    ROOMS = 'ROOMS',
}
