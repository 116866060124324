import { Box } from 'components/container'
import { Wrapper } from 'components/form/wrapper'
import { CheckBox } from 'components/form/items/check-box'
import { Radio } from 'components/form/items/radio'
import { Note } from 'components/presets/note'
import { OtherInput } from 'components/presets/other-input'
import { Kitchen } from 'models/fact-sheet-data'
import { Component } from 'models/common'

export interface ExtraKitchenProps {
    root: string
}

export const ExtraKitchen: React.FC<ExtraKitchenProps> = ({
    root,
}) => {
    const rootName = `${root}.kitchen`
    const section = `${root}.kitchenTypology`

    const required = {
        required: rootName,
    }

    return (
        <Wrapper title="kitchenTitle" id="kitchenTitle" checkAvailable={rootName} required component={Component.ACCOMMODATION_TYPES}>
            <CheckBox title="kitchenTypologyChecked" name={`${rootName}.kitchenTypologyChecked`} required={{ required: rootName, section }}>
                <Radio name={`${rootName}.kitchenTypology`} type={Kitchen} title="kitchen" required={{ required: section }} />
            </CheckBox>
            <Box $row>
                <Box $wrap>
                    <CheckBox title="microwave" name={`${rootName}.microwave`} required={required} />
                    <CheckBox title="cooker" name={`${rootName}.cooker`} required={required} />
                    <CheckBox title="hood" name={`${rootName}.hood`} required={required} />
                    <CheckBox title="dishWasher" name={`${rootName}.dishWasher`} required={required} />
                    <CheckBox title="washing" name={`${rootName}.washingMachine`} required={required} />
                    <CheckBox title="dishes" name={`${rootName}.dishes`} required={required} />
                    <CheckBox title="kitchenWare" name={`${rootName}.kitchenware`} required={required} />
                    <CheckBox title="table" name={`${rootName}.diningTable`} required={required} />
                </Box>
                <CheckBox title="other" name={`${rootName}.otherItems`} required={required} />
                <OtherInput name={`${rootName}.otherItems`} required />
            </Box>
            <Note name={`${rootName}.note`} />
        </Wrapper>
    )
}
