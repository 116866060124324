import { useEffect, useState, useMemo, useCallback } from 'react'
import { Dayjs } from 'dayjs'
import { useField } from 'formik'
import { DatePicker as InternalDatePicker } from 'antd'
import styled from 'styled-components'
import { Required, useRequiredContext } from 'context/required-context'
import { useFormContext } from 'context/form-context'
import { useReadonly } from 'hooks/readonly-hook'
import { useTranslationContext } from 'context/translation-context'
import { BaseInput, BaseStyle, PlaceholderStyle } from '../../input'
import { BaseItemProps } from '../form'
import { Placeholder } from '../placeholder'

const InternalRangePicker = styled(InternalDatePicker.RangePicker)<any>`
    ${BaseInput};
    height: 32px;

    :where(&).ant-picker .ant-picker-suffix > * {
        color: #00B6FF;
    }

    :where(&).ant-picker .ant-picker-separator {
        color: #70758C;
    }

    :where(&).ant-picker .ant-picker-input > input {
        ${BaseStyle};
    }

    :where(&).ant-picker .ant-picker-input > input:placeholder-shown {
        &::-webkit-input-placeholder {
            ${PlaceholderStyle};
        }
        &:-moz-placeholder {
            ${PlaceholderStyle};
        }
        &::-moz-placeholder {
            ${PlaceholderStyle};
        }
        &:-ms-input-placeholder {
            ${PlaceholderStyle};
        }
        &::-ms-input-placeholder {
            ${PlaceholderStyle};
        }
        &::placeholder {
            ${PlaceholderStyle};
        }
    }
`

type Date = Dayjs | null
type RangePickerValue = any
type OnChangeCallback = (first: Date, second: Date) => void

type PlaceHolderType = [string, string]

interface FormatterItem {
    name: string,
    val: any,
}

type BaseFormatterItem = FormatterItem | any
type FormatterResult = BaseFormatterItem[]
type SetterCallback = (key: keyof RangePickerValue, item: BaseFormatterItem) => void

export type Formatter = (date: Date) => FormatterResult
export type DefaultFormatter = (item: any) => Date

export interface RangeStyleProps {
    format?: string
}

export interface BaseDateProps extends BaseItemProps {
    picker?: any
    addon?: RangeStyleProps
    required?: Required
}

export interface RangePickerProps extends BaseDateProps {
    titles: PlaceHolderType
    formatter: Formatter
    defaultFormatter: DefaultFormatter,
}

export const RangePicker: React.FC<RangePickerProps> = ({
    name,
    title,
    addon,
    formatter,
    defaultFormatter,
    required,
    titles,
}) => {
    const { isValidField } = useRequiredContext()
    const [isReadonly] = useReadonly()
    const { translate } = useTranslationContext()
    const { submit } = useFormContext()

    const [defaultValue, setDefaultValue] = useState<[Date, Date]>()
    const [, meta, helpers] = useField(name ?? '')

    useEffect(() => {
        if (!defaultValue) {
            setDefaultValue([
                meta?.value ? defaultFormatter(meta?.value[titles[0]]) : null,
                meta?.value ? defaultFormatter(meta?.value[titles[1]]) : null,
            ])
            return
        }
        submit()
    }, [meta?.value, submit])

    const createTitles = useMemo<PlaceHolderType>(() => ([
        translate(titles[0]),
        translate(titles[1]),
    ]), [translate])

    const onChange = useCallback<OnChangeCallback>((first, second) => {
        const [f, s] = [formatter(first), formatter(second)]
        const result: RangePickerValue = {}

        const getValue = (item: BaseFormatterItem) => {
            return item.val === undefined ? item : item.val
        }

        const setDateResult: SetterCallback = (key, item) => {
            if (item.length === 1) {
                const formattedItem = item[0]
                result[key] = getValue(formattedItem)
            }

            const dateResult: any = {}
            item.forEach((formattedItem: BaseFormatterItem) => {
                const key = formattedItem.name as keyof typeof dateResult
                dateResult[key] = getValue(formattedItem)
            })
            result[key] = dateResult
        }
        setDateResult(titles[0], f)
        setDateResult(titles[1], s)

        helpers.setValue((!first && !second) ? undefined : result)
    }, [formatter, helpers])

    return defaultValue && (
        <Placeholder title={title} state name={name} required={required}>
            <InternalRangePicker
              defaultValue={defaultValue}
              needConfirm={false}
              $required={!isValidField(name) && !isReadonly}
              onChange={(e: any) => onChange(e ? e[0] : null, e ? e[1] : null)}
              name={name}
              {...addon}
              minuteStep={15}
              placeholder={createTitles}
            />
        </Placeholder>
    )
}
