import { useMemo, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { Modal, BaseModalProps } from 'components/main/modal'
import { HistoryResponse } from 'models/fact-sheet-api'
import { useTranslationContext } from 'context/translation-context'
import { useAxiosContext } from 'context/axios-context'
import { StateViewer } from '../main/state-viewer'
import { Box } from '../container'
import { HistoryItem } from './history/history-item'

const HistoryWrapper = styled(Box)`
    gap: 0;
    font-size: 12px;
    text-align: left;
`

const Header = styled(Box)`
    font-weight: 600;
    color: #7F859D;
`

const Content = styled(Box)`
    overflow: auto;
    max-height: 210px;
`

const Info = styled.p`
    color: #7F859D;
    padding: 10px 0px;
`

export interface HistoryModalProps extends BaseModalProps {
}

export const HistoryModal: React.FC<HistoryModalProps> = ({
    ...baseModalProps
}) => {
    const { translate } = useTranslationContext()
    const { history, historyExport } = useAxiosContext()
    const [data, setData] = useState<HistoryResponse>({})

    const modalProps = useMemo(() => ({
        ...baseModalProps,
        onHandleClick: historyExport,
        cancelTitle: 'buttons.close',
        handleTitle: 'buttons.export',
    }), [baseModalProps, historyExport])

    const renderHistory = useMemo(() => {
        const states = data.states ?? []

        return states?.map((item, index) => {
            const parent = (
                index + 1 < states.length ? states[index + 1].hotelAction !== null : false
            )
            const hideBorder = item.hotelAction !== null || parent
            return (
                <HistoryItem
                  key={`history-${item.author}-${item.timestamp}-${item.newState}-${item.hotelAction}`}
                  hideBorder={hideBorder}
                  data={item}
                >
                    {({ newState, hotelAction }) => (
                        <StateViewer big state={newState} action={hotelAction} />
                    )}
                </HistoryItem>
            )
        })
    }, [data.states])

    const useHeaderMargin = useMemo(() => {
        return data.states ? data.states.length > 5 : false
    }, [data.states])

    const renderInfo = useMemo(() => {
        if (!data.dataUpdate) {
            return undefined
        }

        return (
            <Info>
                Last updated {data.dataUpdate.timestamp} by user {data.dataUpdate.author}.
            </Info>
        )
    }, [data.dataUpdate])

    useEffect(() => {
        if (baseModalProps.show) {
            history().then(setData)
        }
    }, [baseModalProps.show, history, setData])

    return (
        <Modal title="history.title" {...modalProps} width={1000}>
            <HistoryWrapper $row>
                <Header>
                    <HistoryItem
                      data={{
                        newState: translate('history.value') as any,
                        timestamp: translate('history.timestamp'),
                        author: translate('history.author'),
                        directedTo: translate('history.directed'),
                      }}
                      margin={useHeaderMargin}
                    />
                </Header>
                <Content $row $noGap>
                    {renderHistory}
                </Content>
                {renderInfo}
            </HistoryWrapper>
        </Modal>
    )
}
