import { useMemo } from 'react'
import styled from 'styled-components'
import { useTranslationContext } from 'context/translation-context'
import { Box, headerGap, BoxProps } from '../container'

interface InfoBoxProps extends BoxProps {
    $min: number
}

const InfoWrapperBox = styled(Box)<InfoBoxProps>`
    border-left: 2px solid #CBE2EE;
    padding-left: ${headerGap}px;
    height: 38px;
    font-size: 12px;
    gap: 0;
    color: #7F859D;
    flex-grow: 1;
    width: ${({ $min }) => $min}%;
`

const Value = styled.h4`
    color: #153C89;
    font-weight: 600;
    padding: 2px 0px;
    font-size: 14px;
`

interface InfoWrapperProps {
    title: string
    value?: string
    min: number
    children?: React.ReactNode
}

export const InfoWrapper: React.FC<InfoWrapperProps> = ({
    title,
    value,
    min,
    children,
}) => {
    const { translate } = useTranslationContext()

    const renderValue = useMemo(() => {
        if (children !== undefined) {
            return children
        }
        return <Value>{value}</Value>
    }, [children, value])

    return (
        <InfoWrapperBox $row $min={min}>
            <p>{translate(title)}</p>
            {renderValue}
        </InfoWrapperBox>
    )
}
