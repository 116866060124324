import { useCallback, useState } from 'react'
import { styled } from 'styled-components'
import { useAxiosContext } from 'context/axios-context'
import { Buttons } from 'hooks/menu-button-hook'
import { StaffModal, StaffLinkCallback } from 'components/modals/staff-modal'
import { CodeModal } from 'components/modals/code-modal'
import { useMessageContext } from 'context/message-context'
import { useSubmitContext } from 'context/submit-context'
import { useLoadContext } from 'context/load-context'
import { ReturnModal, ReturnCallback } from 'components/modals/return-modal'
import { ConfirmModal, ConfirmProps, ConfirmAction } from 'components/modals/confirm-modal'
import { StaffAction } from 'models/fact-sheet-api'
import { OtpRequirement, State } from 'models/fact-sheet-dto'
import { Box, BoxProps, width, headerGap } from '../container'
import { MenuButton } from './menu-button'

const MenuBox = styled(Box)<BoxProps>`
    border-top: 1px solid #CBE2EE;
    padding: ${headerGap}px 0px;
    margin-top: ${headerGap}px;
    justify-content: center;
`

const MenuContentBox = styled(Box)<BoxProps>`
    justify-content: flex-end;
    gap: ${headerGap}px;
`

export const Menu: React.FC = () => {
    const {
        preview,
        setState,
        staff,
        sendOtpRequirement,
        waitForUpdate,
        setWaitForUpdate,
        initialConfiguration,
    } = useAxiosContext()
    const { isSubmitValid } = useSubmitContext()
    const { showMessage, showError } = useMessageContext()
    const { begin, end } = useLoadContext()

    const [showCodeModal, setShowCodeModal] = useState(false)
    const [showStaffModal, setShowStaffModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showReturnModal, setShowReturnModal] = useState(false)

    const [confirmProps, setConfirmProps] = useState<ConfirmProps>({
        title: 'confirm.title',
    })

    const [staffLink, setStaffLink] = useState<StaffLinkCallback>()
    const [otpRequirement, setOtpRequirement] = useState<OtpRequirement>({})

    const delay = (ms: number): Promise<any> => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms)
        })
    }

    const downloadPreview = useCallback(async () => {
        if (waitForUpdate) {
            await delay(500)
        }
        await preview()
        setWaitForUpdate(false)
    }, [waitForUpdate, preview])

    const openConfirm = useCallback(async (title: string, callback: ConfirmAction) => {
        setShowConfirmModal(true)
        setConfirmProps({
            title,
            callback,
        })
    }, [setShowConfirmModal, setConfirmProps])

    const callStaff = useCallback(async (action: StaffAction, email?: string, comment?: string) => {
        await staff(action, email, comment)
    }, [staff])

    const toHotel = useCallback(async () => {
        openConfirm('hotel.title', () => setState(State.SENT_TO_HOTEL))
    }, [openConfirm, setState])

    const toRecalled = useCallback(async () => {
        openConfirm('recall.title', () => setState(State.RECALLED))
    }, [openConfirm, setState])

    const toSign = useCallback(async () => {
        openConfirm('sign.title', async () => {
             await setState(State.PRINTED_TO_SIGN)
             await downloadPreview()
        })
    }, [openConfirm, setState, downloadPreview])

    const overtake = useCallback(async () => {
        openConfirm('overtake.title', async () => {
            await callStaff(StaffAction.OVERTAKE)
            await initialConfiguration()
        })
    }, [callStaff, openConfirm, initialConfiguration])

    const save = useCallback(async () => {
        openConfirm('save.title', () => callStaff(StaffAction.RETURN))
    }, [callStaff, openConfirm])

    const forward = useCallback(async () => {
        setShowStaffModal(true)
        setStaffLink((): StaffLinkCallback => async (email, comment) => {
            await callStaff(StaffAction.FORWARD, email, comment)
            showMessage('forward')
        })
    }, [setShowStaffModal, setStaffLink, callStaff])

    const returnCallback = useCallback<ReturnCallback>(async (note) => {
        await setState(State.IN_PROGRESS, { note })
    }, [setState])

    const scrollToError = useCallback((last: any) => {
        document.getElementById(last ?? '')?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
        })
    }, [])

    const submit = useCallback(async () => {
        const initializeSubmitModal = async () => {
            const {
                enableCodeModal,
                otp,
            } = await setState(State.SUBMITTED_BY_HOTEL)
            setShowCodeModal(enableCodeModal)
            setOtpRequirement(otp)

            sendOtpRequirement()
        }

        begin()

        if (waitForUpdate) {
            await delay(500)
        }

        const { result, additional, last } = isSubmitValid()

        if (result) {
            await initializeSubmitModal()
        } else {
            showError('submitInvalid')
            additional.forEach((errorMessage) => {
                showError(errorMessage)
            })
            scrollToError(last)
        }

        setWaitForUpdate(false)
        end()
    }, [setState, waitForUpdate, sendOtpRequirement, isSubmitValid, scrollToError])

    const otpCallback = useCallback(async (code: number, fullName?: string, jobTitle?: string) => {
        await setState(State.SUBMITTED_BY_HOTEL, { code, fullName, jobTitle })
    }, [setState])

    return (
        <MenuBox>
            <MenuContentBox $width={`${width}px`}>
                <MenuButton onClick={downloadPreview} type={Buttons.PREVIEW} />
                <MenuButton onClick={toRecalled} type={Buttons.RECALL_PROCESS} />
                <MenuButton onClick={overtake} type={Buttons.OVERTAKE} />
                <MenuButton onClick={forward} type={Buttons.FORWARD_INTERNALLY} />
                <MenuButton onClick={save} type={Buttons.SAVE_AND_RETURN} />
                <MenuButton onClick={toHotel} type={Buttons.SENT_TO_HOTEL} />
                <MenuButton onClick={submit} type={Buttons.SUBMIT} />
                <MenuButton onClick={toSign} type={Buttons.PRINT_TO_SIGN} />
            </MenuContentBox>
            <StaffModal show={showStaffModal} setter={setShowStaffModal} staffLink={staffLink} />
            <ConfirmModal
              show={showConfirmModal}
              setter={setShowConfirmModal}
              {...confirmProps}
            />
            <ReturnModal
              show={showReturnModal}
              setter={setShowReturnModal}
              callback={returnCallback}
            />
            <CodeModal
              show={showCodeModal}
              setter={setShowCodeModal}
              otp={otpRequirement}
              callback={otpCallback}
            />
        </MenuBox>
    )
}
