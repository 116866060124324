import { styled, css } from 'styled-components'
import { Input as InpText, InputNumber as InpNumber } from 'formik-antd'
import { Input as BaseTextInput } from 'antd'

const { TextArea } = InpText

export const BaseStyle = `
    color: #3D4466;
    font-family: Open Sans;
    font-weight: 400;
    font-size: 12px;
`

export const PlaceholderStyle = css`
    font-weight: 600;
    color: #70758C;
    font-family: Open Sans;
    font-size: 12px;
`

export const BaseInputEvents = css`
    --color: ${({ $required }: any) => ($required ? '#FF0040' : '#70758C40')};

    &:hover {
        background: #F8F8F8;
        border: 1px solid var(--color);
        outline: 0;
        border-color: var(--color);
    }

    &:focus-visible {
        outline: none;
        border-color: var(--color);
    }

    &:focus-within {
        box-shadow: none;
        background: #F8F8F8;
        border: 1px solid var(--color);
        border-color: var(--color);
    }
`

export const BaseInput = css`
    width: 100%;
    background: #F8F8F8;
    border: 1px solid white;
    border-radius: 5px;

    ${({ $required }: any) =>
        $required &&
        `
        border-color: #FF0040;
    `};

    ${BaseStyle};
    ${BaseInputEvents};
`

export const BaseTextInputStyle = css`
    height: 32px;
    ${BaseInput};

    &:is(.ant-input-disabled) {
        border: 0;
    }

    &:is(.ant-input) {
        height: 32px;
        ${({ $disabled }: any) =>
            $disabled &&
            `
            color: #70758CBC;
        `};
    }
`

export const InputText = styled(InpText)`
    ${BaseTextInputStyle};
`

export const BaseInputText = styled(BaseTextInput)`
    ${BaseTextInputStyle};
`

export const BaseInputTextArea = styled(BaseTextInput.TextArea)`
    ${BaseInput};
`

export const InputTextArea = styled(TextArea)`
    ${BaseInput};

    &:is(.ant-input-disabled) {
        border: 0;
    }

    &:is(.ant-input) {
        padding-top: 5px;
        min-height: 32px;
        ${({ $disabled }: any) =>
            $disabled &&
            `
            color: #70758CBC;
        `};
    }
`

export const InputNumber = styled(InpNumber)<any>`
    height: 32px;
    ${BaseInput};

    &:is(.ant-input-number-disabled) {
        border: 0;
    }

    & .ant-input-number-input {
        ${BaseStyle};
        height: 32px;

        ${({ $disabled }: any) =>
            $disabled &&
            `
            color: #70758CBC;
        `};
    }

    ${({ $showRange }: any) =>
        $showRange &&
        `
        & .ant-input-number-handler-wrap {
            background: none;
            opacity: 1;
            margin-right: 7px;

            & > * {
                border: 0;
            }
        }

        :where(&).ant-input-number-outlined .ant-input-number-handler-wrap .ant-input-number-handler-down {
            border: 0;
        }

        :where(&).ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler .anticon {
            font-size: 11px;
            color: #00B6FF;
        }
    `};

    ${({ $showRange }: any) =>
        !$showRange &&
        `
        & .ant-input-number-handler-wrap {
            display: none;
        }
    `};
`
