import React from 'react'

type State = { error: Error | null }

export class ErrorHandler extends React.Component<React.PropsWithChildren> {
    state: State

    constructor(props: React.PropsWithChildren) {
        super(props)
        this.state = { error: null }
    }

    static getDerivedStateFromError(error: Error) {
        return { error }
    }

    render() {
        const { error } = this.state
        const { children } = this.props

        if (error) {
            return <div>An error occurred.</div>
        }

        return children
    }
}
