import { useEffect, useContext, useCallback, useMemo } from 'react'
import { useField } from 'formik'
import styled from 'styled-components'
import { LoopContextProvider, LoopContext, OnRemoveCallback, OnAddCallback } from 'context/loop-context'
import { useRequiredContext } from 'context/required-context'
import { useFormContext } from 'context/form-context'
import { useReadonly } from 'hooks/readonly-hook'
import { Box } from '../container'
import { InternalButton } from '../button'
import { Item } from './item'
import { BaseItemProps } from './form'

interface LoopProps {
    children: (className: string, index: number, length: number) => React.ReactNode
    defaultItem?: any
    maxCount?: number
    minCount?: number
}

type LoopType = LoopProps & BaseItemProps

const LoopInternal: React.FC<LoopType> = ({
    name,
    children,
    defaultItem,
    maxCount,
    minCount,
}) => {
    const { submit } = useFormContext()
    const { unregister } = useRequiredContext()

    const {
        counter,
        setCounter,
        setAdd,
        setRemove,
        setMaxCount,
        setMinCount,
    } = useContext(LoopContext)
    const [, meta, helpers] = useField(name ?? '')

    const addInternal = (index: number, item: any, onAdd?: OnAddCallback) => {
        let nextItem = item
        if (item && index >= 0) {
            const current = { ...meta.value[index] }

            const isCopy = typeof item !== 'function'
            nextItem = isCopy ? current : item(current)
        }

        const nextIndex = index + 1
        const oldArray = [...meta.value ?? []]
        const val = nextItem ?? defaultItem ?? {}

        if (onAdd) {
            onAdd(val)
        }

        let newArray
        if (nextIndex >= oldArray.length) {
            newArray = [...oldArray, val]
        } else {
            oldArray.splice(nextIndex, 0, val)
            newArray = oldArray
        }

        helpers.setValue(newArray)
        setCounter(counter + 1)
    }

    useEffect(() => {
        setMaxCount(maxCount ?? 10)
        setMinCount(minCount)
    }, [maxCount, minCount])

    useEffect(() => {
        if (!meta.value || meta.value.length === 0) {
            addInternal(-1, null)
        }
    }, [])

    useEffect(() => {
        if (meta.value && meta.value.length > 0) {
            setCounter(meta.value.length)
        }
    }, [meta.value])

    useEffect(() => {
        setAdd(() => addInternal)

        setRemove(() => (index: number, onRemove: OnRemoveCallback) => {
            const tempArray = [...meta.value]

            if (onRemove) {
                onRemove([...tempArray], index)
            }

            tempArray.splice(index, 1)

            const tag = `${name}.[${tempArray.length}]`
            unregister(tag, true)

            helpers.setValue(tempArray)
            setCounter(counter - 1)

            submit()
        })
    }, [meta.value, defaultItem, counter, unregister])

    const renderItems = useCallback(() => {
        const resultList = meta.value ?? []
        return resultList.map((value: any, index: number) => {
            const tag = `${name}.[${index}]`

            return (
                <Box key={`loop-${tag}-row`}>
                    {children(tag, index, resultList.length)}
                </Box>
            )
        })
    }, [meta.value, name, children])

    return (
        <Box $row>
            {renderItems()}
        </Box>
    )
}

type CallbackAction = () => void

interface LoopActionProps {
    index: number
    copy?: any
    children: (add: CallbackAction, remove: CallbackAction) => React.ReactNode
    onRemove?: OnRemoveCallback
    onAdd?: OnAddCallback
}

export const LoopAction: React.FC<LoopActionProps> = ({
    index,
    copy,
    children,
    onRemove,
    onAdd,
}) => {
    const { remove, add } = useContext(LoopContext)

    const addInternal = useCallback(() => {
        add(index, copy, onAdd)
    }, [add, index, copy])

    const removeInternal = useCallback(() => {
        remove(index, onRemove)
    }, [remove, index, onRemove])

    return (
        children(addInternal, removeInternal)
    )
}

interface LoopHandlerProps {
    index: number
    otherOnly?: boolean
    copy?: boolean | ((data: any) => any)
    children: React.ReactNode
    addAlways?: boolean
    onRemove?: OnRemoveCallback
    onAdd?: OnAddCallback
    addIcon?: (disabled: boolean) => React.ReactNode
    length?: number
}

const LoopHandlerItem = styled(Item)`
    > button {
        font-size: 20px;
        flex: 1 1 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align:center;
        width: 60px;
    };
`

const LoopHandlerBox = styled(Box)`
    justify-content: space-between;
`

const LoopButton = styled(InternalButton)`
    background: #F1F7FA;
    color: #00B6FF;
    border: 1px solid #F1F7FA;

    :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):active,
    :where(&).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
        border: 1px solid #F1F7FA;
        color: #00B6FF;
    }

    ${({ disabled }) =>
        disabled &&
        `
        :where(&).ant-btn-default:disabled {
            background: #F8F8F8;
            color: #70758C;
            border: 1px solid #F8F8F8;
        }
    `};
`

export const LoopHandler: React.FC<LoopHandlerProps> = ({
    index,
    otherOnly,
    copy,
    addAlways,
    children,
    addIcon,
    onRemove,
    onAdd,
    length,
}) => {
    const [isReadonly] = useReadonly()
    const { counter, maxCount, minCount } = useContext(LoopContext)
    const editableHandler = maxCount === undefined || counter < maxCount

    const minVal = otherOnly ? 1 : minCount
    const editableMinus = otherOnly ?
        !(!!minVal && index < minVal) :
        (minVal === undefined || counter > minVal)

    const disableAdd = (index !== counter - 1 && !addAlways) || !editableHandler

    const renderAddContent = useMemo(() => {
        if (!addIcon) {
            return '+'
        }
        return addIcon(disableAdd || isReadonly)
    }, [addIcon, disableAdd, isReadonly])

    return (
        <LoopAction index={index} copy={copy} onRemove={onRemove} onAdd={onAdd}>
            {(addInternal, removeInternal) => (
                <LoopHandlerBox>
                    <Box $width="auto" $length={length ?? 5}>
                        {children}
                    </Box>
                    <LoopHandlerItem>
                        { index !== undefined && (
                            <LoopButton
                              disabled={!editableMinus || isReadonly}
                              onClick={removeInternal}
                            >
                                -
                            </LoopButton>
                        )}
                        <LoopButton
                          disabled={disableAdd || isReadonly}
                          onClick={addInternal}>
                            {renderAddContent}
                        </LoopButton>
                    </LoopHandlerItem>
                </LoopHandlerBox>
            )}
        </LoopAction>
    )
}

export const Loop: React.FC<LoopType> = ({
    children,
    ...loopProps
}) => (
    <LoopContextProvider>
        <LoopInternal {...loopProps}>
            {children}
        </LoopInternal>
    </LoopContextProvider>
)
