import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { OtherContent } from 'components/presets/other-content'
import { OtherInput } from 'components/presets/other-input'
import { Note } from 'components/presets/note'
import { Other } from 'components/form/other'
import { CheckBox } from 'components/form/items/check-box'
import { Radio } from 'components/form/items/radio'
import { Label } from 'components/form/items/label'
import { Input } from 'components/form/items/input'
import { Component } from 'models/common'
import { Ownership, Cost, Jetty, Surface, Access, CostDeposit } from 'models/fact-sheet-data'

export const Beach: React.FC = () => {
    return (
        <Card type={Component.BEACH} required checkAvailable>
            <Box>
                <Label title="beach" start required />
                <CheckBox title="direct" name="beach.beachIsDirect" required={{ required: 'beachDirectDistance' }} />
                <Box $width="auto">
                    <Other name="beach.beachIsDirect" value={[false, null, undefined]}>
                        <Input title="distance" name="beach.beachDistance" required={{ required: 'beachDirectDistance' }} />
                    </Other>
                </Box>
            </Box>
            <Box>
                <Label title="hotel" start />
                <CheckBox title="street" name="beach.separated.street" />
                <CheckBox title="promenade" name="beach.separated.promenade" />
                <CheckBox title="underpass" name="beach.separated.underpass" />
                <CheckBox title="other" name="beach.separated.otherItems" />
            </Box>
            <OtherInput name="beach.separated.otherItems" required />
            <Box>
                <Label title="ownershipChecked" start required />
                <Radio name="beach.ownership" type={Ownership} title="ownership" required />
            </Box>
            <OtherContent title="shuttle" name="beach.shuttleBus.checked">
                <Input title="many" name="beach.shuttleBus.frequency" required />
                <Radio name="beach.shuttleBus.charge.chargeType" type={Cost} title="cost" required />
                <Box $width="auto">
                    <Other name="beach.shuttleBus.charge.chargeType" value="EXTRA">
                        <Input title="charge" name="beach.shuttleBus.charge.info" required />
                    </Other>
                </Box>
            </OtherContent>
            <OtherContent title="jetty" name="beach.jetty.checked">
                <Radio name="beach.jetty.access" type={Jetty} title="jetty" required />
            </OtherContent>
            <Box>
                <Label title="surfaceChecked" start required />
                <Box $wrap $flex>
                    <Radio name="beach.surface" type={Surface} title="surface" required={{ required: 'surface' }} />
                    <Box>
                        <Radio name="beach.surface" type={{ OTHER_SPECIFY: 'OTHER_SPECIFY' } as any} title="surface" required={{ required: 'surface' }} />
                        <Other name="beach.surface" value="OTHER_SPECIFY">
                            <Input title="specify" name="beach.surfaceOther" required />
                        </Other>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Label title="accessChecked" start required />
                <Radio name="beach.access" type={Access} title="access" required />
            </Box>
            <OtherContent title="service" name="beach.barService.checked">
                <Radio name="beach.barService.charge.chargeType" type={Cost} title="otherCost" required />
            </OtherContent>
            <CheckBox title="showers" name="beach.showers" />
            <CheckBox title="wc" name="beach.wc" />
            <OtherContent title="sunbeds" name="beach.sunbeds.checked">
                <Radio name="beach.sunbeds.charge.chargeType" type={Cost} title="cost" required />
                <Box $width="auto">
                    <Other name="beach.sunbeds.charge.chargeType" value="EXTRA">
                        <Input title="charge" name="beach.sunbeds.charge.info" required />
                    </Other>
                </Box>
            </OtherContent>
            <OtherContent title="towels" name="beach.beachTowels.checked">
                <Radio name="beach.beachTowels.charge.chargeType" type={CostDeposit} title="cost" required />
                <Box $width="auto">
                    <Other name="beach.beachTowels.charge.chargeType" value="EXTRA">
                        <Input title="charge" name="beach.beachTowels.charge.info" required />
                    </Other>
                    <Other name="beach.beachTowels.charge.chargeType" value="DEPOSIT">
                        <Input title="deposit" name="beach.beachTowels.charge.info" required />
                    </Other>
                </Box>
            </OtherContent>
            <CheckBox title="blue" name="beach.blueFlag" />
            <Box $row>
                <CheckBox title="other" name="beach.otherItems" fixedWidth={false} />
                <OtherInput name="beach.otherItems" required />
            </Box>
            <Note name="beach.note" />
        </Card>
    )
}
