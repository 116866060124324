import { useField } from 'formik'
import type { FieldInputProps, FieldMetaProps, FieldHelperProps } from 'formik'

type HookResult<Value> = [FieldInputProps<Value>?, FieldMetaProps<Value>?, FieldHelperProps<Value>?]
type UseFieldHookProps = (name?: string) => HookResult<any>

export const useFieldHook: UseFieldHookProps = (name) => {
    if (!name) {
        return [undefined, undefined, undefined]
    }
    const fieldResult = useField(name)
    return fieldResult
}
