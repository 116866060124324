import { useCallback, useEffect } from 'react'
import { useField } from 'formik'
import { Box } from 'components/container'
import { useInclusiveContext } from 'context/inclusive-context'
import { OtherContent } from 'components/presets/other-content'
import { OtherInput } from 'components/presets/other-input'
import { Note } from 'components/presets/note'
import { Loop, LoopHandler } from 'components/form/loop'
import { CheckBox } from 'components/form/items/check-box'
import { Restaurants } from 'models/fact-sheet-data'
import { Select } from 'components/form/items/select'
import { Input, InputType } from 'components/form/items/input'

export const MainFacilities: React.FC = () => {
    const showHowMany = false
    const required = {
        required: 'mainFacilities',
    }

    const { update, mapper } = useInclusiveContext()
    const [, checked] = useField('accommodationFacilities.restaurants.checked')
    const [, internal, helpers] = useField('accommodationFacilities.restaurants.list')

    const fillMapper = useCallback((list: any[], index?: number) => {
        const nameCount: { [key: string]: number } = {}

        const validList = list.filter((item) => item.name)
        const parsedList = validList.map((item) => {
            const parsed: any = {
                name: item.name,
            }

            if (nameCount[item.name] !== undefined) {
                nameCount[item.name] += 1
                parsed.name = `${item.name} (${nameCount[item.name]})`
            } else {
                nameCount[item.name] = 1
            }

            return parsed
        })
        update(parsedList, index)
    }, [mapper])

    const restaurantRemoved = useCallback((list: any[], index: number) => {
        const filteredList = list.filter(({ typology }, internalIndex) => (
            typology === Restaurants.A_LA_CARTE && internalIndex !== index
        ))
        fillMapper(filteredList)
    }, [fillMapper])

    const onTypeChanged = useCallback((index: number) => {
        return (type: any) => {
            const newArray = [...internal.value]
            newArray[index].typology = type

            const filteredList = newArray.filter(({ typology }) => (
                typology === Restaurants.A_LA_CARTE
            ))
            fillMapper(filteredList)
        }
    }, [internal, fillMapper])

    const onNameChanged = useCallback((index: number) => {
        return (value: any) => {
            const newArray = [...internal.value]
            newArray[index].name = value

            const filteredList = newArray.filter(({ typology }) => (
                typology === Restaurants.A_LA_CARTE
            ))
            fillMapper(filteredList, index)
        }
    }, [internal, fillMapper])

    useEffect(() => {
        if (!checked.value) {
            helpers.setValue([])
        }
        fillMapper((checked.value ? internal.value : []) ?? [])
    }, [checked.value])

    useEffect(() => {
        fillMapper(internal.value ?? [])
    }, [])

    return (
        <>
            <Box $row>
                <CheckBox title="reception" name="accommodationFacilities.reception" required={required} />
                <CheckBox title="room" name="accommodationFacilities.conferenceRoom" required={required} />
                <CheckBox title="tv" name="accommodationFacilities.commonRoom" required={required} />
                <CheckBox title="shops" name="accommodationFacilities.shops" required={required} />
                <CheckBox title="fitness" name="accommodationFacilities.fitness" required={required} />
                <CheckBox title="wellness" name="accommodationFacilities.welness" required={required} />
            </Box>
            <Box $row>
                <CheckBox title="other" name="accommodationFacilities.otherItems" required={required} />
                <OtherInput name="accommodationFacilities.otherItems" required />
            </Box>
            <OtherContent title="restaurants" name="accommodationFacilities.restaurants.checked" required={required} info="restaurantInfo">
                <Loop name="accommodationFacilities.restaurants.list" defaultItem={{}} minCount={1}>
                    {(rootName, index) => (
                        <LoopHandler index={index} onRemove={restaurantRemoved} length={4}>
                            <Input title="whichRestaurant" name={`${rootName}.name`} max={20} tooltip="restaurantTooltip" onChange={onNameChanged(index)} required />
                            {showHowMany && (
                                <Input title="many" name={`${rootName}.count`} max={10} type={InputType.NUMBER_RANGE} />
                            )}
                            <Select title="type" name={`${rootName}.typology`} type={Restaurants} root="restaurants" onChange={onTypeChanged(index)} required />
                            <Input title="note" max={40} name={`${rootName}.note`} />
                        </LoopHandler>
                    )}
                </Loop>
            </OtherContent>
            <OtherContent title="bar" name="accommodationFacilities.bars.checked" required={required} info="barInfo">
                <Loop name="accommodationFacilities.bars.list" defaultItem={{ count: 1 }} minCount={1}>
                    {(rootName, index) => (
                        <LoopHandler index={index} length={4}>
                            <Input title="whichBar" name={`${rootName}.name`} max={20} tooltip="barTooltip" required />
                            {showHowMany && (
                                <Input title="many" name={`${rootName}.count`} max={10} type={InputType.NUMBER_RANGE} />
                            )}
                        </LoopHandler>
                    )}
                </Loop>
            </OtherContent>
            <OtherContent title="cafe" name="accommodationFacilities.cafe.checked" required={required}>
                <Input title="many" name="accommodationFacilities.cafe.count" type={InputType.NUMBER_RANGE} required defaultValue={1} />
            </OtherContent>
            <Note name="accommodationFacilities.noteFirst" />
        </>
    )
}
