import { useCallback, useState, useEffect, useMemo } from 'react'
import { useField } from 'formik'
import { useSubmitContext } from 'context/submit-context'
import { Card } from 'components/main/card'
import { CheckBox } from 'components/form/items/check-box'
import { Component } from 'models/common'
import { MealsChecker } from './meals/meals-checker'
import { Inclusive } from './meals/inclusive'

type BaseListType = any[] | undefined

export const Meals: React.FC = () => {
    const required = {
        required: 'meals',
    }

    const defaultInclusiveList = ['allInclusive', 'ultraAI', 'twentyFourAI', 'lightAI']
    const [, list, helpers] = useField('meals.allInclList')
    const [baseList, setBaseList] = useState<BaseListType>(undefined)
    const { remove } = useSubmitContext()

    const findLastIndex = useCallback(() => {
        const internal = list.value ?? []
        let index = internal.length
        while (index) {
            index -= 1
            const name = internal[index].allInclName
            if (defaultInclusiveList.includes(name)) {
                return index
            }
        }
        return -1
    }, [list.value])

    const findByName = useCallback((name: string) => {
        return (list.value ?? []).findIndex((item: any) => item.allInclName === name)
    }, [list.value])

    const createCopy = useCallback((name: string) => {
        const index = findByName(name)
        if (index < 0) {
            return undefined
        }
        return { ...list.value[index] }
    }, [list.value, findByName])

    const internalAdd = useCallback((index: number, name: string) => {
        const oldArray = list.value ?? []
        const nextIndex = index < 0 ? oldArray.length : index
        const val = { allInclName: name }

        let newArray
        if (nextIndex >= oldArray.length) {
            newArray = [...oldArray, val]
        } else {
            oldArray.splice(nextIndex, 0, val)
            newArray = oldArray
        }
        helpers.setValue(newArray)
    }, [list])

    const internalRemove = useCallback((index: number) => {
        const newInclusive = [...list.value]
        const removeIndex = index >= newInclusive.length ? newInclusive.length - 1 : index
        newInclusive.splice(removeIndex, 1)
        helpers.setValue(newInclusive)
    }, [list.value])

    const onRemove = useCallback((inclusive: any[], index: number) => {
        const removeIndex = findByName(inclusive[index])
        internalRemove(removeIndex)
    }, [internalRemove, findByName])

    const onChange = useCallback((value: any, old: any) => {
        if (!old || old.length === 0) {
            internalAdd(-1, value)
            return
        }
        const index = findByName(old)
        if (index >= 0) {
            const newInclusive = [...list.value]
            newInclusive[index].allInclName = value
            helpers.setValue(newInclusive)
        }
    }, [internalAdd, list, findByName])

    const onInclusiveChange = useCallback((name: string) => {
        return (value: boolean) => {
            const index = defaultInclusiveList.findIndex((inclusive) => name === inclusive)
            const newBaseList = [...(baseList ?? [])]

            newBaseList[index] = value ? name : undefined
            setBaseList(newBaseList)
        }
    }, [baseList])

    const spliceList = useCallback((inclusive: any[]) => {
        const index = findLastIndex()
        if (!list.value || list.value.length === 0 || list.value.length <= index + 1) {
            helpers.setValue([...inclusive])
            return
        }
        const otherItems = list.value.slice(index + 1)
        helpers.setValue([...inclusive, ...otherItems])
    }, [list.value, findByName, findLastIndex])

    const renderInclusiveContent = useMemo(() => {
        if (!list.value || list.value.length === 0) {
            remove(Component.MEALS, 'allInclSpecs')
        } else {
            remove(Component.MEALS, 'allInclSpecs', (name) => {
                return list.value.map((item: any) => item.allInclName)
                    .filter((key: string) => key === name).length !== 0
            })
        }

        return (list.value ?? []).map((item: any, index: number) => {
            const key = `${item.allInclName}-${index}`
            const includes = defaultInclusiveList.includes(item.allInclName)
            return <Inclusive
              key={key}
              id={`inclusive-${index}`}
              includes={includes}
              title={item.allInclName}
              index={index}
            />
        })
    }, [list.value])

    useEffect(() => {
        const init = defaultInclusiveList.map((name) => {
            const index = findByName(name)
            return index >= 0 ? name : undefined
        })
        setBaseList(init)
    }, [])

    useEffect(() => {
        if (baseList === undefined) {
            return
        }

        const mappedList = baseList.filter((name) => name).map((name) => {
            const foundCopy = createCopy(name)
            return foundCopy ?? { allInclName: name }
        })
        spliceList(mappedList)
    }, [baseList])

    return (
        <Card type={Component.MEALS} required>
            <MealsChecker name="main" hasInclusive onChange={onChange} onRemove={onRemove} required={required}>
                {(root) => (
                    <>
                        <CheckBox title="bedOnly" name={`${root}.bedOnly`} required={required} />
                        <CheckBox title="bedBreakfast" name={`${root}.bb`} required={required} />
                        <CheckBox title="halfboard" name={`${root}.halfBoard`} required={required} />
                        <CheckBox title="fullboard" name={`${root}.fullBoard`} required={required} />
                        <CheckBox title="halfboardPlus" name={`${root}.halfBoardPlus`} required={required} />
                        <CheckBox title="fullboardPlus" name={`${root}.fullBoardPlus`} required={required} />
                        <CheckBox title="allInclusive" name={`${root}.allInclusive`} onChange={onInclusiveChange('allInclusive')} required={required} />
                        <CheckBox title="ultraAI" name={`${root}.ultraAI`} onChange={onInclusiveChange('ultraAI')} required={required} />
                        <CheckBox title="twentyFourAI" name={`${root}.twentyFourAI`} onChange={onInclusiveChange('twentyFourAI')} required={required} />
                        <CheckBox title="lightAI" name={`${root}.lightAI`} onChange={onInclusiveChange('lightAI')} required={required} />
                    </>
                )}
            </MealsChecker>
            {renderInclusiveContent}
            <MealsChecker title="specialDiets" name="specialDiets" activateWrapper>
                {(root) => (
                    <>
                        <CheckBox title="glutenFree" name={`${root}.glutenFree`} />
                        <CheckBox title="vegetarian" name={`${root}.vegetarian`} />
                        <CheckBox title="vegan" name={`${root}.vegan`} />
                        <CheckBox title="lactofree" name={`${root}.lactoFree`} />
                    </>
                )}
            </MealsChecker>
            <MealsChecker title="children" name="children" activateWrapper>
                {(root) => (
                    <>
                        <CheckBox title="childrenBuffet" name={`${root}.buffet`} />
                        <CheckBox title="childrenMenu" name={`${root}.menu`} />
                        <CheckBox title="childrenRestaurant" name={`${root}.restaurant`} />
                        <CheckBox title="highChair" name={`${root}.highChair`} />
                        <CheckBox title="microwave" name={`${root}.microwave`} />
                        <CheckBox title="mixer" name={`${root}.mixer`} />
                    </>
                )}
            </MealsChecker>
        </Card>
    )
}
