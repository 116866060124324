import React, { useMemo } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Edit } from 'pages/edit/edit'
import { Invalid } from 'pages/invalid/invalid'
import { Layout } from './layout'

export enum Page {
    EDIT,
    INVALID,
}

export type PageType = React.FC
type PageMapper = Array<[Page, string, React.ReactNode]>

export const Router: React.FC = () => {
    const pageMapper = useMemo<PageMapper>(() => ([
        [Page.EDIT, '/edit', <Edit key="edit" />],
        [Page.INVALID, '/invalid', <Invalid key="invalid" />],
    ]), [])

    const getPageComponent = (type: Page): React.ReactNode => {
        const mapper = pageMapper.filter((mapper) => mapper[0] === type).at(0)
        if (!mapper) {
            throw new Error(`Page for type ${type} could not be found!`)
        }
        return mapper[2]
    }

    if (pageMapper.length !== Object.keys(Page).length / 2) {
        throw new Error('Not all pages are mapped to the router component!')
    }

    const defaultRoute = useMemo(() => getPageComponent(Page.EDIT), [])
    const routes = useMemo(() => (
        pageMapper.map((pageMap) => (
            <Route key={pageMap[0]} path={pageMap[1]} element={pageMap[2]} />
        ))
    ), [])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={defaultRoute} />
                    {routes}
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </BrowserRouter>
    )
}
