import { useCallback } from 'react'
import { Box } from 'components/container'
import { Other } from 'components/form/other'
import { CheckBox } from 'components/form/items/check-box'
import { Loop, LoopHandler } from 'components/form/loop'
import { Input, InputType } from 'components/form/items/input'
import { MultipleDatePicker } from 'components/presets/multiple-date-picker'
import { Select } from 'components/form/items/select'
import { Water } from 'models/fact-sheet-data'

type ChildrenCallback = (rootName: string) => React.ReactNode

interface PoolsComponentProps {
    name: string
    title: string
    row?: boolean
    disablePicker?: boolean
    children?: ChildrenCallback
}

export const PoolsComponent: React.FC<PoolsComponentProps> = ({
    name,
    title,
    row,
    disablePicker,
    children,
}) => {
    const rootName = `swimmingPools.${name}`

    const renderChildren = useCallback<ChildrenCallback>((rootName) => {
        if (children) {
            return children(rootName)
        }
        return null
    }, [children])

    return (
        <Box $row={row} $noGap={row}>
            <CheckBox title={title} name={`${rootName}.checked`} required={{ required: 'swimmingPools' }} />
            <Other name={`${rootName}.checked`}>
                <Loop name={`${rootName}.pools`} maxCount={10} minCount={1} defaultItem={{ count: 1 }}>
                    {(root, index) => (
                        <Box $width="auto">
                            <LoopHandler index={index} length={5}>
                                <Input title="many" name={`${root}.count`} type={InputType.NUMBER_RANGE} max={10} min={0} />
                                <Select title="type" name={`${root}.waterType`} type={Water} root="water" required />
                                {!disablePicker && (
                                    <MultipleDatePicker title="when" first={`${root}.heatingPeriod1`} second={`${root}.heatingPeriod2`} />
                                )}
                                { renderChildren(root) }
                            </LoopHandler>
                        </Box>
                    )}
                </Loop>
            </Other>
        </Box>
    )
}
