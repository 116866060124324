import { useMemo } from 'react'
import styled from 'styled-components'
import { TranslationContextProvider, useTranslationContext } from 'context/translation-context'
import { RequiredContextProvider } from 'context/required-context'
import { Component, Namespace } from 'models/common'
import { Box } from '../container'
import { RequiredTitle } from './required-title'

interface CardProps extends React.PropsWithChildren {
    type: Component
    additional?: boolean
    required?: boolean
    checkAvailable?: any
    disableRequiredTitle?: boolean
    hideRequiredNote?: boolean
}

const CardBox = styled(Box)<any>`
    border-bottom: 1px solid #DCE2ED;
    padding: 20px 0px;
    row-gap: 20px;

    ${({ $offset }) => `
        scroll-margin-top: ${$offset}px;
    `};
`

const HeaderBox = styled(Box)`
    gap: 15px;
`

const CardContentBox = styled(Box)`
    font-size: 12px;
    flex-wrap: wrap;
`

export const InternalCard: React.FC<CardProps> = ({
    type,
    required,
    checkAvailable,
    disableRequiredTitle,
    hideRequiredNote,
    children,
}) => {
    const { translate } = useTranslationContext()

    const headerOffset = useMemo(() => (
        document.getElementById('header-wrapper')?.offsetHeight ?? 0
    ), [])

    return (
        <CardBox $row id={type} $offset={headerOffset}>
            <HeaderBox>
                <RequiredTitle
                  required={required && !disableRequiredTitle}
                  hideRequiredNote={hideRequiredNote}
                  big
                  title={translate('titles', { params: [type] })}
                  checkAvailable={checkAvailable}
                />
            </HeaderBox>
            <CardContentBox>
                {children}
            </CardContentBox>
        </CardBox>
    )
}

export const Card: React.FC<CardProps> = ({
    type,
    required,
    additional,
    checkAvailable,
    disableRequiredTitle,
    hideRequiredNote,
    children,
}) => {
    return (
        <TranslationContextProvider addon={type} ns={Namespace.COMPONENT}>
            <RequiredContextProvider
              additional={additional}
              id={type}
              required={required}
              component={type}
            >
                <InternalCard
                  type={type}
                  required={required}
                  checkAvailable={checkAvailable}
                  disableRequiredTitle={disableRequiredTitle}
                  hideRequiredNote={hideRequiredNote}
                >
                    {children}
                </InternalCard>
            </RequiredContextProvider>
        </TranslationContextProvider>
    )
}
