import { useState, useEffect } from 'react'
import { useField } from 'formik'
import { useTranslationContext } from 'context/translation-context'
import { useInclusiveContext } from 'context/inclusive-context'
import { Box } from 'components/container'
import { Wrapper } from 'components/form/wrapper'
import { OtherInput } from 'components/presets/other-input'
import { TimePicker } from 'components/presets/time-picker'
import { Note } from 'components/presets/note'
import { Loop, LoopHandler } from 'components/form/loop'
import { Select } from 'components/form/items/select'
import { Label } from 'components/form/items/label'
import { OtherContent } from 'components/presets/other-content'
import { CheckBox } from 'components/form/items/check-box'
import { Input } from 'components/form/items/input'
import { Component } from 'models/common'

interface InclusiveProps {
    title: string
    includes: boolean
    index: number
    id: string
}

export const Inclusive: React.FC<InclusiveProps> = ({
    id,
    title,
    includes,
    index,
}) => {
    const required = {
        required: 'inclusive',
    }

    const { translate } = useTranslationContext()
    const { inclusiveRestaurants, length } = useInclusiveContext()

    const root = `meals.allInclList.[${index}].allInclSpecs`
    const [, internal] = useField(`${root}.alaCarte.otherItems`)
    const [used, setUsed] = useState<any>([])

    useEffect(() => {
        setUsed((internal.value ?? [])
            .filter((item: any) => item.name)
            .map((item: any) => item.name))
    }, [internal.value])

    return (
        <Wrapper
          id={`${id}.allInclSpecs`}
          title="inclusiveTitle"
          addon={{ title: includes ? translate(title) : title }}
          required
          component={Component.MEALS}
        >
            <Box>
                <Label title="aiAvailable" required />
                <TimePicker title="range" name={`${root}.openingHours.timeRange`} required />
                <Input title="note" name={`${root}.openingHours.note`} fixedWidth={false} />
            </Box>
            <Box $row>
                <CheckBox title="earlyBreakfast" name={`${root}.earlyBreakfast`} required={required} />
                <CheckBox title="breakfast" name={`${root}.breakfast`} required={required} />
                <CheckBox title="lateBreakfast" name={`${root}.lateBreakfast`} required={required} />
                <CheckBox title="lunch" name={`${root}.lunch`} required={required} />
                <CheckBox title="dinner" name={`${root}.dinner`} required={required} />
                <CheckBox title="snackDuringDay" name={`${root}.snackDuringDay`} required={required} />
                <CheckBox title="iceCream" name={`${root}.iceCream`} required={required} />
                <CheckBox title="lateDinner" name={`${root}.lateDinner`} required={required} />
                <CheckBox title="nightSnack" name={`${root}.nightSnack`} required={required} />
                <CheckBox title="unlimitedSoft" name={`${root}.unlimitedSoft`} required={required} />
                <CheckBox title="unlimitedAlcohol" name={`${root}.unlimitedAlcohol`} required={required} />
                <CheckBox title="importedAlcohol" name={`${root}.importedAlcohol`} required={required} />
            </Box>
            <Box $row>
                <CheckBox title="other" name={`${root}.otherItems`} fixedWidth={false} required={required} />
                <OtherInput name={`${root}.otherItems`} required />
            </Box>
            <OtherContent title="alaCarteRestaurants" name={`${root}.alaCarte.otherItems`} required={required} isDisabled={length === 0}>
                <Loop name={`${root}.alaCarte.otherItems`} defaultItem={{ count: 1 }} maxCount={length}>
                    {(rootName, index) => (
                        <LoopHandler index={index} length={4}>
                            <Select title="whichRestaurant" disabled={used} name={`${rootName}.name`} type={inclusiveRestaurants} required disableTranslate root="" />
                            <Input title="timesCanBeVisited" name={`${rootName}.allowance`} />
                            <Input title="note" name={`${rootName}.note`} max={40} />
                        </LoopHandler>
                    )}
                </Loop>
            </OtherContent>
            <Note name={`${root}.note`} />
        </Wrapper>
    )
}
