import React, { createContext, useState, useMemo, useContext, useCallback } from 'react'

export interface LoadContextProps {
    isLoading: boolean
    begin: () => void
    end: () => void
}

export const LoadContext = createContext<LoadContextProps>(
    {} as LoadContextProps,
)

type LoadContextProviderProps = React.PropsWithChildren

export const LoadContextProvider: React.FC<LoadContextProviderProps> = ({
    children,
}) => {
    const [isLoading, setLoading] = useState<boolean>(false)

    const begin = useCallback(() => {
        setLoading(true)
    }, [setLoading])

    const end = useCallback(() => {
        setLoading(false)
    }, [setLoading])

    const providerContext = useMemo(() => ({
        isLoading,
        begin,
        end,
    }), [
        isLoading,
        begin,
        end,
    ])

    return (
        <LoadContext.Provider value={providerContext}>
            {children}
        </LoadContext.Provider>
    )
}

export const useLoadContext = () => {
    return useContext(LoadContext)
}
