import { useMemo } from 'react'
import { useFactSheetContext } from 'context/fact-sheet-context'
import { useRequiredContext } from 'context/required-context'
import { Operation, State } from 'models/fact-sheet-dto'

export const useReadonly = (disableAvailable?: boolean) => {
    const { info } = useFactSheetContext()
    const { isAvailable } = useRequiredContext()

    const readonly = useMemo(() => {
        const invalidOperations = [
            Operation.REJECTED, Operation.RELEASED,
        ]

        const invalidStates = [
            State.PRINTED_TO_SIGN,
            State.PUBLISHED,
            State.CANCELLED,
        ]

        const available = (
            isAvailable === null || isAvailable === undefined || isAvailable || disableAvailable
        )

        const readonlyState = info.state && invalidStates.includes(info.state)
        const internalReadonly = readonlyState || !info.isCurrentEditor
        const token = !info.token || invalidOperations.includes(
            info.token.operation ?? Operation.REJECTED,
        )

        const calculated = internalReadonly || token || !available
        return calculated
    }, [info, isAvailable, disableAvailable])

    return [readonly]
}
