import { useMemo } from 'react'
import { Box } from 'components/container'
import { OtherInput } from 'components/presets/other-input'
import { Note } from 'components/presets/note'
import { CheckBox } from 'components/form/items/check-box'
import { Wrapper } from 'components/form/wrapper'
import { Component } from 'models/common'

interface MealsCheckerProps {
    name: string
    children: (className: string) => React.ReactNode
    title?: string
    hasInclusive?: boolean
    required?: any
    activateWrapper?: boolean
    onRemove?: (list: any[], index: number) => void,
    onChange?: (value: any, old: any) => void,
}

export const MealsChecker: React.FC<MealsCheckerProps> = ({
    name,
    children,
    title,
    hasInclusive,
    required,
    activateWrapper,
    onRemove,
    onChange,
}) => {
    const rootName = `meals.${name}`
    const renderChildren = useMemo(() => children(rootName), [children])

    return (
        <Wrapper
          id={name}
          title={title}
          component={Component.MEALS}
          required={activateWrapper}
          hideRequiredNote
        >
            <Box $row>
                <Box $wrap>
                    {renderChildren}
                </Box>
                {hasInclusive && (
                    <>
                        <CheckBox title="otherInclusive" name={`${rootName}.otherItemsAI`} fixedWidth={false} required={required} />
                        <OtherInput name={`${rootName}.otherItemsAI`} onRemove={onRemove} onChange={onChange} required />
                    </>
                )}
                <CheckBox title="other" name={`${rootName}.otherItems`} fixedWidth={false} required={required} />
                <OtherInput name={`${rootName}.otherItems`} required />
            </Box>
            <Note name={`${rootName}.note`} />
        </Wrapper>
    )
}
