import { useMemo, useCallback } from 'react'
import dayjs from 'dayjs'
import { BaseDateProps, RangePicker, RangeStyleProps, Formatter, DefaultFormatter } from '../form/items/date-picker'

export const TimePicker: React.FC<BaseDateProps> = ({
    title,
    name,
    required,
}) => {
    const addon = useMemo<RangeStyleProps>(() => ({
        picker: 'time',
        format: 'HH:mm',
    }), [])

    const defaultFormatter = useCallback<DefaultFormatter>((item) => {
        if (!item.hr && !item.mins) {
            return null
        }
        return dayjs().set('hour', item.hr).set('minute', item.mins).set('second', 0)
    }, [])

    const formatter = useCallback<Formatter>((date) => {
        return [
            {
                name: 'hr',
                val: date ? date.hour() : null,
            },
            {
                name: 'mins',
                val: date ? date.minute() : null,
            },
        ]
    }, [])

    return (
        <RangePicker
          title={title}
          titles={['from', 'to']}
          name={name}
          addon={addon}
          required={required}
          defaultFormatter={defaultFormatter}
          formatter={formatter}
        />
    )
}
