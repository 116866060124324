import { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { useFormContext } from 'context/form-context'
import { useReadonly } from 'hooks/readonly-hook'
import { useRequiredContext } from 'context/required-context'
import { useTranslationContext } from 'context/translation-context'
import { useAxiosContext } from 'context/axios-context'
import { useFieldHook } from 'hooks/use-field-hook'
import { InputNumber, InputTextArea, InputText } from '../../input'
import { Tooltip } from '../../tooltip'
import { Item, ItemBoxProps } from '../item'
import { BaseItemProps } from '../form'

export enum InputType {
    TEXT,
    NUMBER,
    NUMBER_RANGE,
    TEXT_AREA,
}

interface PlaceholderBoxProps {
    $emptyState?: boolean
    $disabled?: boolean
}

const InputItem = styled(Item)`
    position: relative;
`

const PlaceholderBox = styled.div<PlaceholderBoxProps>`
    font-weight: 600;
    position: absolute;
    z-index: 1;
    color: #70758C;
    margin-top: 7px;
    margin-left: 12px;
    pointer-events: none;

    ${({ $emptyState }) =>
        $emptyState &&
        `
        font-size: 9px;
        top: -13px;
        background: rgb(255,255,255);
        background: linear-gradient(
            to top,
            #F8F8F8 0%,
            #F8F8F8 50%,
            white 50%,
            white 100%
        );
        padding: 0px 2px;
    `};

    ${({ $disabled }) =>
        $disabled &&
        `
        color: #70758CBC;
    `};
`

interface InputProps extends BaseItemProps, ItemBoxProps {
    addon?: any
    type?: InputType
    max?: number
    tooltip?: string
    min?: number
    onChange?: (value: any, old: any) => void
    defaultValue?: any
}

export const Input: React.FC<InputProps> = ({
    name,
    title,
    addon,
    type,
    max,
    min,
    tooltip,
    fixedHeight,
    fixedWidth,
    autoWidth,
    onChange,
    required,
    defaultValue,
}) => {
    const { isValidField } = useRequiredContext()
    const { translate } = useTranslationContext()
    const { setWaitForUpdate } = useAxiosContext()
    const [isReadonly] = useReadonly()
    const { submit } = useFormContext()
    const [, meta, helpers] = useFieldHook(name)
    const [state, setState] = useState<boolean>(!!meta?.value)

    const internalOnChange = useCallback((value: any) => {
        const isObject = typeof value === 'object'
        const newValue = isObject && value ? value.currentTarget.value : value
        if (onChange) {
            onChange(newValue, meta?.value)
        }
    }, [onChange])

    const inputProps = useMemo(() => {
        const isNumber = type && [InputType.NUMBER, InputType.NUMBER_RANGE].includes(type)
        const minLength = isNumber ? Math.max(min ?? 0, 0) : min

        return {
            name: name ?? '',
            id: `${name}__input`,
            rows: 1,
            maxLength: max,
            max,
            min: minLength,
            minLength,
            disabled: isReadonly,
            $disabled: isReadonly,
            $required: !isValidField(name),
            onChange: internalOnChange,
            onFocus: () => {
                setState(true)
                setWaitForUpdate(true)
            },
            onBlur: () => {
                setState(false)
                submit()
            },
        }
    }, [isReadonly, submit, internalOnChange, isValidField, required, meta?.value, type])

    const renderPlaceholder = useMemo(() => {
        const invalidValue = !meta?.value && meta?.value !== 0
        return (
            <PlaceholderBox $emptyState={!(state === false && invalidValue)} $disabled={isReadonly}>
                { `${translate(title)} ${addon ?? ''}` }
            </PlaceholderBox>
        )
    }, [state, isReadonly, meta?.value])

    const renderInputComponent = useMemo(() => {
        const showRange = type === InputType.NUMBER_RANGE
        switch (type ?? InputType.TEXT) {
            case InputType.TEXT:
                return <InputText {...inputProps} />
            case InputType.NUMBER:
            case InputType.NUMBER_RANGE:
                return <InputNumber {...inputProps} $showRange={showRange} />
            case InputType.TEXT_AREA:
                return <InputTextArea {...inputProps} />
            default:
                throw Error('Unknown input type')
        }
    }, [inputProps])

    const renderInput = useMemo(() => {
        return (
            <InputItem
              fixedHeight={fixedHeight}
              fixedWidth={fixedWidth}
              autoWidth={autoWidth}
              name={name}
              required={required}
            >
                <Tooltip text={translate(tooltip)} place="topLeft">
                    {renderPlaceholder}
                    {renderInputComponent}
                </Tooltip>
            </InputItem>
        )
    }, [renderPlaceholder, renderInputComponent])

    useEffect(() => {
        if (defaultValue && !meta?.value) {
            helpers?.setValue(defaultValue)
        }
    }, [])

    return renderInput
}
