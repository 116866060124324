import { useMemo, useState, useCallback } from 'react'
import { styled } from 'styled-components'
import { BaseInputTextArea } from 'components/input'
import { Modal, BaseModalProps } from 'components/main/modal'
import { useTranslationContext } from 'context/translation-context'

const Title = styled.p`
    color: #3D4466;
    font-size: 12px;
`

export type ReturnCallback = (note: string) => Promise<any>

export interface ReturnModalProps extends BaseModalProps {
    callback: ReturnCallback
}

export const ReturnModal: React.FC<ReturnModalProps> = ({
    callback,
    ...baseModalProps
}) => {
    const { translate } = useTranslationContext()
    const [note, setNote] = useState<string>()

    const onClick = useCallback(async () => {
        callback(note ?? '')
    }, [callback, note])

    const submitEnabled = useMemo((): boolean => {
        return !!note
    }, [note])

    const modalProps = useMemo(() => ({
        ...baseModalProps,
        onHandleClick: onClick,
        onHandleClose: true,
        handleDisable: !submitEnabled,
        handleTitle: 'buttons.confirm',
        onCancelClick: () => setNote(undefined),
    }), [baseModalProps, onClick, submitEnabled, setNote])

    return (
        <Modal title="edit.title" {...modalProps}>
            <Title>{translate('edit.reason')}</Title>
            <BaseInputTextArea
              value={note}
              onChange={(val) => { setNote(val.currentTarget.value) }}
              maxLength={500}
              rows={5}
              placeholder={translate('edit.input')}
            />
        </Modal>
    )
}
