import styled from 'styled-components'
import { Box } from '../container'
import { Other } from '../form/other'
import { Input, InputType } from '../form/items/input'
import { CheckBox } from '../form/items/check-box'

const OtherContainer = styled(Box)`
    flex: 1;
`
interface OtherInputProps {
    name: string
    title?: string
    input?: string
    inputName?: string
    type?: InputType
    required?: any
}

export const OtherInputSwitcher: React.FC<OtherInputProps> = ({
    name,
    title,
    input,
    inputName,
    type,
    required,
}) => {
    return (
        <Box $width="auto">
            <CheckBox title={title} name={name} required={required} />
            <OtherContainer>
                <Other name={name}>
                    {(name) => (
                        <Input
                          title={input ?? title}
                          name={inputName ?? name}
                          type={type}
                          required
                        />
                    )}
                </Other>
            </OtherContainer>
        </Box>
    )
}
