import { useMemo } from 'react'
import { Component } from 'models/common'
import { EntityWrapper, EntryMapper } from '../wrapper/entity-wrapper'

export interface ExtraChildrenProps {
    rootName: string
}

export const ExtraChildren: React.FC<ExtraChildrenProps> = ({
    rootName,
}) => {
    const childrenMapper = useMemo((): EntryMapper => ([
        ['babyCot'],
        ['babySitting'],
        ['potty'],
        ['babyBath'],
        ['bottleWarmer'],
        ['monitors'],
    ]), [])

    return (
        <EntityWrapper
          title="childrenTitle"
          rootName={`${rootName}.childrenFacilities`}
          data={childrenMapper}
          hideRequiredNote
          required
          disableRequired
          component={Component.ACCOMMODATION_TYPES}
        />
    )
}
