import { useEffect, useState, useCallback } from 'react'
import i18n, { InitOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Language, Namespace } from 'models/common'

type Json = object
type NamespaceJson = { [key in Namespace]?: Json }
type Translation = { [key in Language]?: NamespaceJson }

type LoadCallback = (lang: Language, namespace: Namespace) => Promise<Json>
type CreateCallback = () => Promise<Translation>
type InitCallback = (translation: Translation) => InitOptions

interface TranslationHookProps {
    loaded: boolean
}

export const useTranslationHook = (): TranslationHookProps => {
    const [loaded, setLoaded] = useState<boolean>(false)

    const loadTranslation = useCallback<LoadCallback>(async (lang, namespace) => {
        const headers = new Headers()
        headers.append('pragma', 'no-cache')
        headers.append('cache-control', 'no-cache')

        const props = {
            headers,
        }

        const response = await fetch(`/locales/${lang}/${namespace}.json`, props)
        const json = await response.json()
        return json
    }, [])

    const createTranslationObject = useCallback<CreateCallback>(async () => {
        const result: Translation = {}

        for (const language of Object.values(Language)) {
            const namespaces: NamespaceJson = {}
            for (const namespace of Object.values(Namespace)) {
                namespaces[namespace] = await loadTranslation(language, namespace)
            }
            result[language] = namespaces
        }
        return result
    }, [loadTranslation])

    const initOptions = useCallback<InitCallback>((translations) => ({
        load: 'languageOnly',
        ns: Object.values(Namespace),
        fallbackLng: Language.EN,
        resources: translations,
        supportedLngs: Object.values(Language),
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
            bindI18n: 'languageChanged',
        },
    }), [])

    const initializeTranslation = useCallback(async () => {
        const translations = await createTranslationObject()
        i18n.use(initReactI18next).init(initOptions(translations))
        setLoaded(true)
    }, [setLoaded, createTranslationObject, initOptions])

    useEffect(() => {
        initializeTranslation()
    }, [initializeTranslation])

    return {
        loaded,
    }
}
