import { Card } from 'components/main/card'
import { Box } from 'components/container'
import { OtherInput } from 'components/presets/other-input'
import { OtherInputSwitcher } from 'components/presets/other-input-switcher'
import { Note } from 'components/presets/note'
import { CheckBox } from 'components/form/items/check-box'
import { InputType } from 'components/form/items/input'
import { Component } from 'models/common'

export const Accommodation: React.FC = () => {
    const required = {
        required: 'recommendedFor',
    }

    return (
        <Card type={Component.RECOMMENDED_FOR} required>
            <OtherInputSwitcher
              title="adults"
              name="recommendedFor.adultsOnly"
              input="age"
              inputName="recommendedFor.adultsOnlyAge"
              type={InputType.NUMBER}
              required={required}
            />
            <Box $row>
                <Box $wrap>
                    <CheckBox title="mult" name="recommendedFor.multiGeneration" required={required} />
                    <CheckBox title="seniors" name="recommendedFor.seniors" required={required} />
                    <CheckBox title="families" name="recommendedFor.families" required={required} />
                    <CheckBox title="young" name="recommendedFor.youngPeople" required={required} />
                    <CheckBox title="party" name="recommendedFor.partyHotel" required={required} />
                    <CheckBox title="quiet" name="recommendedFor.quietHotel" required={required} />
                    <CheckBox title="live" name="recommendedFor.liveAtmosphere" required={required} />
                    <CheckBox title="honey" name="recommendedFor.honeyMooners" required={required} />
                    <CheckBox title="all" name="recommendedFor.allClients" required={required} />
                </Box>
                <CheckBox title="other" name="recommendedFor.otherItems" fixedWidth={false} required={required} />
                <OtherInput name="recommendedFor.otherItems" required />
            </Box>
            <Note name="recommendedFor.note" />
        </Card>
    )
}
